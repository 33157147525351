<div class="filter-container">
  <div
    [ngClass]="{'margin-bottom-5': first}" *ngFor="let mode of colorModes; let first = first; trackBy: trackByFn">
    <button class="mode-button"
            (click)="onChangeFilterType(mode)"
            [disabled]="selectedColorMode === mode"
    >
      {{('color_by_' + mode) | translate}}
    </button>
    <span class="margin-left-10" *ngFor="let event of eventFilters[mode]; trackBy: trackByFn">
        <button
          [ngStyle]="{'background-color': event.color}"
          (mouseenter)="onFilterByColor(event.color)"
          (mouseleave)="onFilterByColor(null)">
          {{event.name}} {{event.total}}
        </button>
      </span>
  </div>
</div>
