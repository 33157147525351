import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { Event as EventModel } from '~/shared/models';

import { eventsBufferAdapter, EventsBufferState, EVENTS_BUFFER, initialBufferState } from './events-buffer.state';
import { EventsBufferActions, EventsBufferActionTypes } from './events-buffer.actions';

export function eventsBufferReducer(
  state: EventsBufferState = initialBufferState,
  action: EventsBufferActions
): EventsBufferState {
  switch (action.type) {
    case EventsBufferActionTypes.CopyEvent:
      return eventsBufferAdapter.addOne(action.payload.event, state);
    case EventsBufferActionTypes.RemoveFromBuffer:
      return eventsBufferAdapter.removeOne(action.payload.eventId, state);
    case EventsBufferActionTypes.RemoveAllFromBuffer:
      return initialBufferState;

    case EventsBufferActionTypes.PastedEvent:
      return {
        ...state,
        selectedId: action.payload.eventId
      };
    default:
      return state;
  }
}

export const selectBufferEventState = createFeatureSelector<EventsBufferState>(EVENTS_BUFFER);

export const getAllBufferEvents = eventsBufferAdapter.getSelectors(selectBufferEventState).selectAll;
export const getBufferEventsEntities = eventsBufferAdapter.getSelectors(selectBufferEventState).selectEntities;
export const getBufferEventsTotal = eventsBufferAdapter.getSelectors(selectBufferEventState).selectTotal;

export const getPastingEventId = createSelector(
  selectBufferEventState,
  (state) => state.selectedId
);

export const getPastingEvent = () => {
  return createSelector(
    getBufferEventsEntities,
    getPastingEventId,
    (entities: Dictionary<EventModel>, selectedId: number) => {
      return entities[selectedId];
    }
  );
};
