import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NoteModel } from '~/shared/models';

export const NOTES = 'notes';

export interface NoteState extends EntityState<NoteModel> {
  selectedId: string | null;
  isLoading?: boolean;
  error?: any;
}

export const noteAdapter: EntityAdapter<NoteModel> = createEntityAdapter<NoteModel>({
  selectId: cur => cur.id
});

export const initialNoteState: NoteState = noteAdapter.getInitialState({
  selectedId: null,
  isLoading: undefined,
  error: undefined
});
