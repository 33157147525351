import { CalendarRuleInitialState, CalendarRuleState } from '~/store/calendar-rule/calendar-rule.state';
import * as CalendarRuleActions from '~/store/calendar-rule/calendar-rule.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { updateArrayElement } from '~/framework';

const calendarRuleReducer = createReducer(
  CalendarRuleInitialState,
  on(CalendarRuleActions.getCalendarRule, state => ({...state, calendarRules: null, isLoading: true})),
  on(CalendarRuleActions.getCalendarRuleFail, (state, {error}) => ({...state, isLoading: false, error})),
  on(CalendarRuleActions.getCalendarRuleSuccess, (state, {calendarRules}) =>
    ({...state, calendarRules, isLoading: false, error: null})),
  on(CalendarRuleActions.getCalendarRuleById, state => ({...state, selectedCalendarRule: null, isLoading: true})),
  on(CalendarRuleActions.getCalendarRuleByIdFail, (state, {error}) => ({...state, isLoading: false, error})),
  on(CalendarRuleActions.getCalendarRuleByIdSuccess, (state, {selectedCalendarRule}) =>
    ({...state, selectedCalendarRule, isLoading: false, error: null})),
  on(CalendarRuleActions.createCalendarRule, state => ({...state, isLoading: true})),
  on(CalendarRuleActions.createCalendarRuleFail, (state, {error}) => ({...state, isLoading: false, error})),
  on(CalendarRuleActions.createCalendarRuleSuccess, (state, {selectedCalendarRule}) =>
    ({...state, selectedCalendarRule, isLoading: false, error: null})),
  on(CalendarRuleActions.createCalendarRules, state => ({...state, isLoading: true})),
  on(CalendarRuleActions.createCalendarRulesFail, (state, {error}) => ({...state, isLoading: false, error})),
  on(CalendarRuleActions.createCalendarRulesSuccess, (state, {calendarRules}) =>
    ({
      ...state,
      calendarRules: state.calendarRules ? state.calendarRules.concat(calendarRules) : calendarRules,
      isLoading: false,
      error: null
    })),
  on(CalendarRuleActions.updateCalendarRule, state => ({...state, isLoading: true})),
  on(CalendarRuleActions.updateCalendarRuleSuccess, (state, {selectedCalendarRule}) =>
    ({...state, calendarRules: updateArrayElement(state.calendarRules, selectedCalendarRule), selectedCalendarRule, isLoading: false})),
  on(CalendarRuleActions.updateCalendarRuleFail, (state, {error}) => ({...state, error, isLoading: false})),
  on(CalendarRuleActions.deleteCalendarRule, state => ({...state})),
  on(CalendarRuleActions.deleteCalendarRuleFail, (state, {error}) => ({...state, isLoading: false, error})),
  on(CalendarRuleActions.deleteCalendarRuleSuccess, (state, {id}) =>
    ({
      ...state,
      calendarRules: state.calendarRules.filter(item => item.id !== id),
      isLoading: false,
      error: null
    }))
);

export function CalendarRuleReducer(state: CalendarRuleState, action: Action) {
  return calendarRuleReducer(state, action);
}


