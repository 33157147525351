import { Component, Input, OnInit } from '@angular/core';
import { AgendaFreeTimeSlot, calculateStartPosition } from '~/shared/components/agenda/agenda.util';
import moment from 'moment';
import { BASE_TIME_FORMAT } from '~/framework';

@Component({
  selector: 'apfr-agenda-free-time',
  templateUrl: './agenda-free-time.component.html',
  styleUrls: ['./agenda-free-time.component.scss']
})
export class AgendaFreeTimeComponent implements OnInit {
  @Input()
  timeSlot: AgendaFreeTimeSlot;

  @Input()
  startAgenda: string;

  @Input()
  hovered = false;

  @Input()
  cellHeight: number;

  @Input()
  intervalAgenda: number;

  styles: Object;

  constructor() { }

  ngOnInit() {
    this.styles = {
      top: `${calculateStartPosition(
        moment(this.timeSlot.startTime, BASE_TIME_FORMAT)
          .unix(),
        this.startAgenda,
        this.intervalAgenda,
        this.cellHeight
      )}px`
    };
  }

}
