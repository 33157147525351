import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppointmentService } from '~/+customer/shared/appointment.service';

import * as fromActions from './appointment-form.actions';
import { AppointmentFormActionTypes } from './appointment-form.actions';

@Injectable()
export class AppointmentFormEffects {
  loadCustomerForm$ = createEffect(() => this.actions$
    .pipe(
      ofType(AppointmentFormActionTypes.LoadForm),
      switchMap(() => {
        return this.appointmentService.getAppointmentForm()
          .pipe(
            map(fields => new fromActions.LoadFormSuccess(fields)),
            catchError(error => observableOf(new fromActions.LoadFormFail(error)))
          );
      })
    ));

  constructor(
    private actions$: Actions,
    private appointmentService: AppointmentService) {
  }
}
