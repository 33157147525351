<ng-container *ngIf="event">
  <div>
    <ng-container *ngIf="numberOfDays < breakpointNumberOfDays; else shortView">
      <small class="start-time">
        {{event.startTime | fromUnixToTime}}
      </small>
      <div class="status">
        <ng-container *ngIf="event.statutEntry === eventStatuses.InProgress">
          <mat-icon>local_parking</mat-icon>
        </ng-container>

        <ng-container *ngIf="event.statutEntry === eventStatuses.CV">
          CV
        </ng-container>

        <ng-container *ngIf="event.statutEntry === eventStatuses.Done">
          <mat-icon color="primary">check</mat-icon>
        </ng-container>

        <ng-container *ngIf="event.statutEntry === eventStatuses.NotDone">
          <mat-icon color="warn">close</mat-icon>
        </ng-container>
      </div>

      <div class="event-first-line">
        <span>{{ event.name }}</span>
        <span *ngIf="event.licensePlate"> {{event.licensePlate}}</span>
      </div>
      <div class="event-second-line">
        <span>{{ event.contactName | replaceContactName }}</span>
        <span *ngIf="event.contactTel"> -
          <a class="link-tel" href="tel:{{ event.contactTel }}">
            {{ event.contactTel }}
          </a>
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #shortView>
  <div class="event-first-line">
    <span>{{ event.name | slice:0: 6}}</span>
  </div>
</ng-template>
