import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, pluck, switchMap, tap } from 'rxjs/operators';
import { DatabaseService } from '~/framework/database/database.service';
import { CustomerService } from '~/+customer/shared/customer.service';
import { CustomerModel } from '~/shared/models';

import * as fromActions from './customer.actions';
import { CustomerActionTypes } from './customer.actions';
import { CustomerState } from './customer.state';

@Injectable()
export class CustomerEffects {

  loadCustomers$ = createEffect(() => this.actions$
    .pipe(
      ofType(CustomerActionTypes.GetAllCustomers),
      map((action: fromActions.GetAllCustomers) => action.payload),
      switchMap((data: { page, itemsPerPage, search }) => {
        return this.customerService.getCustomers(data.page, data.itemsPerPage, data.search)
          .pipe(
            pluck('hydra:member'),
            map((customers: CustomerModel[]) => {
              return new fromActions.GetAllCustomersSuccess(customers);
            }),
            catchError((error) => {
              return observableOf(new fromActions.GetAllCustomersFail(error));
            })
          );
      })
    ));
  loadCustomer$ = createEffect(() => this.actions$
    .pipe(
      ofType(CustomerActionTypes.GetOneCustomer),
      map((action: fromActions.GetOneCustomer) => action.payload),
      switchMap((id: string) => {
        return this.customerService.getCustomer(id)
          .pipe(
            map(customer => new fromActions.GetOneCustomerSuccess(customer)),
            catchError(error => observableOf(new fromActions.GetOneCustomerFail(error)))
          );
      })
    ));
  handleGetCustomersSuccess = createEffect(() => this.actions$
    .pipe(
      ofType(CustomerActionTypes.GetAllCustomersSuccess),
      map((action: fromActions.GetAllCustomersSuccess) => action.payload),
      map((data) => new fromActions.SetCustomersToDB(data)),
      tap((action) => this.databaseService.setCustomers(action.payload))
    ));
  handleGetCustomersFail = createEffect(() => this.actions$
    .pipe(
      ofType(CustomerActionTypes.GetAllCustomersFail),
      map((action: fromActions.GetAllCustomersFail) => action.payload),
      tap(error => {
        if (error.status === 0 || error.status >= 500) {
          this.databaseService.getCustomers()
            .subscribe(customers => {
              this.store.dispatch(new fromActions.SetCustomersFromDB(customers));
            });
        }
      }),
      map(data => new fromActions.GetCustomersFromDB())
    ));

  constructor(private actions$: Actions,
              private customerService: CustomerService,
              private databaseService: DatabaseService,
              private store: Store<CustomerState>) {
  }
}
