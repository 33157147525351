import { Action } from '@ngrx/store';
import { AppointmentModel } from '~/shared/models';

export enum AppointmentActionTypes {
  GetAppointments = '[Appointments] Get Appointments',
  GetAppointmentsFail = '[Appointments] Get Appointments Fail',
  GetAppointmentsSuccess = '[Appointments] Get Appointments Success',

  GetOneAppointment = '[Appointment] Get One Appointment',
  GetOneAppointmentSuccess = '[Appointment] Get One Appointment Success',
  GetOneAppointmentFail = '[Appointment] Get One Appointment Fail'
}

export class GetAppointments implements Action {
  readonly type = AppointmentActionTypes.GetAppointments;

  constructor(public payload: string) {
  }
}

export class GetAppointmentsFail implements Action {
  readonly type = AppointmentActionTypes.GetAppointmentsFail;

  constructor(public payload: any) {
  }
}

export class GetAppointmentsSuccess implements Action {
  readonly type = AppointmentActionTypes.GetAppointmentsSuccess;

  constructor(public payload: Array<AppointmentModel>) {
  }
}

export class GetOneAppointment implements Action {
  readonly type = AppointmentActionTypes.GetOneAppointment;

  constructor(public payload: string) {
  }
}

export class GetOneAppointmentSuccess implements Action {
  readonly type = AppointmentActionTypes.GetOneAppointmentSuccess;

  constructor(public payload: AppointmentModel) {
  }
}

export class GetOneAppointmentFail implements Action {
  readonly type = AppointmentActionTypes.GetOneAppointmentFail;

  constructor(public payload: any) {
  }
}

export type AppointmentActions =
  GetAppointments |
  GetAppointmentsFail |
  GetAppointmentsSuccess |
  GetOneAppointment |
  GetOneAppointmentSuccess |
  GetOneAppointmentFail;
