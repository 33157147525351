export enum BusinessTypeEnum {
  MOT = 'MOT',
  BEAUTY = 'BEAUTY',
  DRIVER = 'DRIVER',
  GARAGE = 'GARAGE',
  HAIR = 'HAIR',
  FOOD = 'FOOD',
  HEALTH = 'HEALTH',
  BASICSERVICE = 'BASICSERVICE',
  ETHTEST = 'ETHTEST'
}
