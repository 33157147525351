import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '~/framework';
import { Event, EventRequestModel, FieldConfig } from '~/shared/models';
import { serviceForm } from '~/mock-api/form/service.form';
import { Update } from '@ngrx/entity';
import { FreeTimeRequestModel } from '~/shared/models/agenda/free-time.model';
import { ICalendarRule } from '@apfr/components/calendar-rule-modal';

export enum NotificationKeyType {
  SMS_CONFIRMATION = 'sms_confirmation',
  MAIL_CONFIRMATION = 'mail_confirmation',
  PAYMENT_LINK = 'payment_link'
}

type Resources = Array<number>;
type Institutions = Array<number>;

export interface Promotion {
  id: number;
  type: string;
  promoPrice: number;
  price: string;
  ftcolor: string;
  bgcolor: string;
  resources: Resources;
  is_payment_displayed: boolean;
  is_payment_mandatory: boolean;
}

export interface Time {
  resources: Resources;
  institutions: Institutions;
  promotion: Promotion | false;
}
/**
 * [key: string] = HH:MM
 */
export interface Times {
  [key: string]: Time;
}

export interface CalendarRuleObject {
  rule: ICalendarRule;
  resources: Resources;
  institutions: Institutions;
  start: string;
  end: string;
}

/**
 * [key: string] = MM/DD/YYYY
 */
interface DateObject<T> {
  [key: string]: T;
}

/**
 * it should be date in YYYY-MM-DD
 */
interface StartEndDate {
  start: string;
  end: string;
}

export type FreeTime = DateObject<Times>;
export type BusyTime = DateObject<CalendarRuleObject[]>;

export interface FreeTimeResponse {
  agendaInfo: StartEndDate;
  resources: Resources;
  timeTable: Array<string>;
  promotions: Array<Promotion>;
  freeTimes: FreeTime;
  busyTimes: BusyTime;
  slotDuration: number;
}

@Injectable({providedIn: 'root'})
export class CalendarService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAgenda(data: EventRequestModel): Observable<Event[]> {
    return this.http.get<Event[]>(
      `${this.baseUrl}/events?start=${data.startDate}&end=${data.endDate}&resources=${data.resourceIds.join(',')}`
    );
  }

  getFreeTime(data: FreeTimeRequestModel): Observable<FreeTimeResponse> {
    return this.http.get<FreeTimeResponse>(
      `${this.apiUrl}/freetime?start=${data.startDate}&duration=${data.duration}&entities=${data.entityIds.join(',')}${data.serviceIds ? '&services=' + data.serviceIds.join(',') : ''}${data.direction ? '&' + data.direction : ''}${data.exceptEventIds ? '&exceptEvents=' + data.exceptEventIds.join(',') : ''}`
    );
  }

  getServiceFormFields(): Observable<FieldConfig[]> {
    return observableOf(serviceForm)
      .pipe(map((cur: any) => cur.inputs));
  }

  updateEvent(updateData: Update<Event>): Observable<Event> {
    const body = updateData.changes as any;
    if (body.resource) {
      body.resource = body.resource.id;
    }
    return this.http.patch<Event>(`${this.baseUrl}/events/internal/${updateData.id}`, body);
  }

  deleteEvent(id: number) {
    return this.http.delete(`${this.apiUrl}/events/${id}`);
  }

  getEventById(id: number) {
    return this.http.get<Event>(`${this.baseUrl}/events/internal/${id}`);
  }

  resendNotification(eventId: number, notificationKey: NotificationKeyType) {
    return this.http.get(`${this.baseUrl}/events/${eventId}/resend-notification/${notificationKey}`);
  }
}
