import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { EVENTS_BUFFER } from './events-buffer.state';
import { eventsBufferReducer } from './events-buffer.reducer';
import { EventsBufferEffects } from './events-buffer.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(EVENTS_BUFFER, eventsBufferReducer),
    EffectsModule.forFeature([EventsBufferEffects])
  ]
})
export class EventsBufferStateModule {
}
