import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Institution as InstitutionModel } from '~/shared/models';
import { BaseService } from '~/framework';
import { Observable } from 'rxjs';

export interface FreeTimeShortData {
  slotDuration: number;
  dayStart: string;
  dayEnd: string;
  workingDays: string[];
}

export interface InstitutionsResponse {
  institutions: InstitutionModel[];
  freeTimeShortData: FreeTimeShortData;
}

@Injectable({
  providedIn: 'root'
})
export class InstitutionService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getInstitutions(ids: string[]): Observable<InstitutionsResponse> {
    return this.http.get<InstitutionsResponse>(`${this.apiUrl}/institution?ids=${ids.join(',')}`);
  }
}
