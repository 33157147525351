import { Injectable } from '@angular/core';
import { BaseService } from '~/framework';
import { CurrentUser, CurrentUserContext, CurrentUserDTO } from '~/shared/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class CurrentUserService extends BaseService {
  updateProfile(context: Partial<CurrentUserContext>) {
    const body = {
      profile: {
        context
      }
    };

    return this.http.patch(`${this.apiUrl}/profile`, body);
  }

  getProfile(): Observable<CurrentUser> {
    return this.http.get<CurrentUserDTO>(`${this.apiUrl}/profile`)
      .pipe(
        map(dto => CurrentUser.fromDto(dto))
      );
  }
}
