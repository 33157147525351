import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '~/framework/core/src/base.service';

export const EVENT_ORIGIN: string = 'App\\Entity\\Event';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends BaseService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  upload(formData) {
    return this.httpClient.post(`${this.baseUrl}/files`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteAll(origin: string, originId: number) {
    return this.httpClient.delete(`${this.baseUrl}/files/${origin}/${originId}`);
  }

  delete(fileId: number) {
    return this.httpClient.delete(`${this.baseUrl}/files/${fileId}`);
  }
}
