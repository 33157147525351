import { AfterViewInit, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { filter } from 'rxjs/operators';

import { ConfigAppState } from '~/store/config-app/config-app.state';
import { selectIsAppOnline } from '~/store/config-app/config-app.reducers';

/**
 * ActionButtonDirective
 *
 * @description
 * add disabled property to component when if application offline
 */
@Directive({
  selector: '[apfrActionButton]'
})
export class ActionButtonDirective implements OnInit, AfterViewInit {

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private store: Store<ConfigAppState>) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.store
      .pipe(
        select(selectIsAppOnline),
        filter((payload) => payload !== undefined)
      )
      .subscribe((isOnline) => {
        if (!isOnline) {
          this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
        } else {
          this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        }
      });
  }
}
