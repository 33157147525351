<div class="collapsed-events"  #popup>
  <div class="collapsed-event-time">
    <span>{{ eventRes.overlapCount }} {{ 'events' | translate }} at {{ +eventRes.startTime | fromUnixToTime }}</span>
  </div>
  <div class="collapsed-events-container">
    <ng-container *ngFor="let event of eventRes.events; trackBy: trackByFn">
      <div class="collapsed-event" tabindex="1"
           (apfrClick)="selectEvent.emit(event)"
           (click)="selectEvent.emit(event)"
           [style.height.px]="100">
        <div *ngIf="(event.type !== EventTypes.Tampon && event.type !== EventTypes.Ferme)"
             class="popover-trigger"
             [mdePopoverTriggerFor]="appPopover"
             mdePopoverTriggerOn="hover"
             mdePopoverArrowWidth="0">
        </div>
        <div class="agenda-time">
          <div>
            <span>{{ event.startTime | fromUnixToTime }} - {{ event.endTime | fromUnixToTime }}</span>
          </div>
          <span>{{ event.name }}</span>
        </div>
      </div>

      <mde-popover #appPopover="mdePopover"
                   mdePopoverOffsetY="-19"
                   mdePopoverPositionX="center"
                   mdePopoverEnterDelay="600"
                   mdePopoverLeaveDelay="300"
                   [mdePopoverOverlapTrigger]="true">
        <mat-card style="min-width: 200px; max-width: 200px">
          <mat-card-content>
            <apfr-agenda-info
              [isGroupedByResources]="options.isGroupedByResources"
              [isDraggable]="event.type !== EventTypes.Tampon && options.isDraggable"
              [isMobile]="options.isMobile"
              [event]="event"
            ></apfr-agenda-info>
          </mat-card-content>
        </mat-card>
      </mde-popover>
    </ng-container>
  </div>
</div>
