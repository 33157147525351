  import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
  import {ComponentPortal} from '@angular/cdk/portal';

  import {select, Store} from '@ngrx/store';
  import {RouterReducerState} from '@ngrx/router-store';
  import { combineLatest, fromEvent, Observable } from 'rxjs';
  import { filter, map, takeUntil } from 'rxjs/operators';

  import {BaseComponent, DATE_FORMAT, FromStore} from '~/framework';
  import {I18NService} from '~/framework/i18n/src/i18n.service';

  import {TokenService, UserEntitiesService} from '~/shared/services';

  import {CurrentUserState, getUserFromToken, removeCurrentUser, selectCurrentUser} from '~/store/current-user';
  import {
    ConfigAppOffline,
    ConfigAppOnline,
    ConfigAppSetUrls,
    ConfigAppState,
    ConfigCheckMobile,
    selectIsAppOnline
  } from '~/store/config-app';
  import {HideNotification, NotificationState, ShowNotification} from '~/store/notification';
  import {getCurrentUrl} from '~/store/router';

  import * as momentTz from 'moment-timezone';
  import moment from 'moment';

  import {AgendaNavComponent} from '~/shared/components/agenda-nav/agenda-nav.component';
  import { ExternalMenuLinksModel, UserTokenData, WsEventModel } from '~/shared/models';
  import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
  import {environment} from '../environments/environment';
  import {ShowFormChange} from '~/store/agenda-view';

  import {GetAgenda, RemoveEvents} from '~/store/calendar';
  import {NavigationState} from '~/store/navigation/navigation.reducer';
  import {selectIsResolverLoading} from '~/store/navigation/navigation.selectors';
  import {MatSidenav} from '@angular/material/sidenav';
  import {Router} from '@angular/router';
  import {UrlRedirector} from "~/shared/services/url-generator/url-redirector";
  import {ResponsiveService, UrlEmitterService} from "@apfr/components/core";
  import { SnackBarComponent } from '~/shared/components';
  import { MatSnackBar } from '@angular/material/snack-bar';
  import { EventWebsocketService } from '~/framework/websocket/src/event-websocket.service';
  import { getEventNotification, UserSettingsState } from '~/store/user-settings';

  // simple externalLink mock using on dev mode only
  const externalMenuLink = {
    Administration: {
      items: [
        {
          name: 'Pricing',
          url: '//preprod.auto-planning.fr/agenda/pricing',
          slug: 'pricing'
        },
        {
          name: 'Payment extra',
          url: '//preprod.auto-planning.fr/agenda/payment_extra',
          slug: 'payment-extra'
        },
      ]
    },
    Institution: {
      items: [
        {
          name: 'Follow up',
          url: '//preprod.auto-planning.frrelance.php?area=1',
          slug: 'follow-up'
        }
      ]
    },
    Support: {
      items: [
        {
          name: 'Support backend',
          url: '//support.auto-planning.fr/backend.php/ticket?login=agalas',
          slug: 'support-backed'
        }
      ]
    }
  };

  @Component({
    selector: 'apfr-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
  })
  export class AppComponent extends BaseComponent implements OnInit, FromStore {
    currentUser$: Observable<UserTokenData>;
    isMobile$: Observable<boolean> = this.responsiveService.isMobile$;
    isDashboardAccessible: boolean;
    currentPagePortal: ComponentPortal<any>;
    isMobileAgent: boolean;
    iframeSrc: SafeUrl;
    externalMenuLinks: ExternalMenuLinksModel;
    isResolverLoading$ = this.store.pipe(select(selectIsResolverLoading));
    routeName: Router;
    @ViewChild('iframe', {static: true})
    iframeRef: ElementRef;
    @ViewChild('sidenav', {static: true})
    sidenav: MatSidenav;

    @HostListener('window:message', ['$event'])
    onMessage(event) {
      this.receiveMessage(event);
    }

    regExp = /[0-9&\/\\#,+()$~%.'":*?<>{}]/g;

    constructor(
      private responsiveService: ResponsiveService,
      private readonly i18n: I18NService,
      private readonly store: Store<CurrentUserState | ConfigAppState | NotificationState |
      RouterReducerState | NavigationState | UserSettingsState>,
      private readonly userEntitiesService: UserEntitiesService,
      private readonly sanitizer: DomSanitizer,
      private readonly router: Router,
      private readonly urlGenerator: UrlRedirector,
      private readonly urlEmitterService: UrlEmitterService,
      private readonly eventWebsocketService: EventWebsocketService,
      private snackBar: MatSnackBar
    ) {
      super();

      this.i18n.init();
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.baseIframeUrl}/heartbeat`);
    }

    ngOnInit(): void {
      this.isMobile$.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((state) => this.isDashboardAccessible = state);
      this.routeName = this.router;
      this.router.events.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(event => {
          if (!this.isDashboardAccessible && event['url'] === '/dashboard') {
            this.router.navigate(['/calendar']);
          }
        });

      this.urlEmitterService.$url.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((url: string) => {
        this.urlGenerator.redirectTo(url);
      });
      momentTz.tz.setDefault('Europe/Paris');

      if (TokenService.isUserLoggedIn()) {
        this.store.dispatch(getUserFromToken());

        this.userEntitiesService
          .getUserEntities()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe();
      }
      this.checkIsOnline();
      this.store.dispatch(new ConfigCheckMobile());
      this.getDataFromStore();
      combineLatest([
        this.store
          .pipe(
            select(getEventNotification)
          ),
        this.eventWebsocketService.onEvents()
      ])
        .pipe(
          filter(([eventNotification, message]: [boolean, WsEventModel]) => eventNotification),
          map(([eventNotification, message]: [boolean, WsEventModel]) => message),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((message: WsEventModel) => {
          // global notification
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 5_000,
            data: {
              message: 'websocket.event.' + message.action,
              action: 'ok'
            }
          });
        });
      this.isMobileAgent = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      this.externalMenuLinks = externalMenuLink;

      // Use only on Dev mode
      if (!environment.production) {
        this.store.dispatch(new ConfigAppSetUrls({externalLinks: this.externalMenuLinks}));
      }
    }

    getDataFromStore(): void {
      this.currentUser$ = this.store.pipe(
        select(selectCurrentUser),
        takeUntil(this.ngUnsubscribe)
      );
      this.store
        .pipe(
          select(selectIsAppOnline),
          filter((payload) => payload !== undefined),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(isOnline => {
          if (!isOnline) {
            this.store.dispatch(new ShowNotification({style: 'warn', message: 'App is Offline'}));
          } else {
            this.store.dispatch(new HideNotification(0));
          }
        });

      this.store
        .pipe(
          select(getCurrentUrl),
          filter((url) => url !== undefined),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(url => {
          if (url.includes('calendar')) {
            this.currentPagePortal = new ComponentPortal(AgendaNavComponent);
          } else {
            this.currentPagePortal = undefined;
          }
        });
    }

    private checkIsOnline() {
      fromEvent(window, 'online')
        .pipe(
          map(() => this.store.dispatch(new ConfigAppOnline())),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe();

      return fromEvent(window, 'offline')
        .pipe(
          map(() => this.store.dispatch(new ConfigAppOffline())),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe();
    }

    signOut() {
      this.store.dispatch(removeCurrentUser());
      const iframe = this.iframeRef.nativeElement as HTMLIFrameElement;
      iframe.contentWindow.postMessage({type: 'remove_cookie'}, environment.targetOrigin);
      this.iframeSrc = this.iframeSrc;
      this.sidenav.close();
    }

    receiveMessage(event) {
      if (event.origin !== environment.targetOrigin) {
        return;
      }

      if (event.data.type) {
        const data = event.data;
        switch (data.type) {
          case 'auth':
            if (data.value === false) {
              this.store.dispatch(removeCurrentUser());
            } else {
              this.iframeSrc = this.iframeSrc;
            }
            break;
          case 'menuLinks':
            this.externalMenuLinks = event.data.value;
            this.store.dispatch(new ConfigAppSetUrls({externalLinks: this.externalMenuLinks}));
            break;
          case 'iframe_close':
            this.store.dispatch(new ShowFormChange({isShowForm: false}));
            break;
          case 'events_refresh':
            if (event.data.value) {
              const resourceIds = event.data.value.resource_list;
              const startDate = moment(event.data.value.startTime.gte)
                .format(DATE_FORMAT);
              const endDate = moment(event.data.value.startTime.lte)
                .format(DATE_FORMAT);
              this.store.dispatch(new GetAgenda({resourceIds, startDate, endDate}));
            }

            break;
          case 'events_delete':
            if (event.data.value) {
              this.store.dispatch(new RemoveEvents({eventsIds: event.data.value}));
            }
            break;
        }
      }
    }
  }
