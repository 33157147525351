import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { BASE_TIME_FORMAT } from '~/framework';

@Pipe({name: 'fromUnixToTime'})

export class FromUnixToTimePipe implements PipeTransform {

  transform(unix: number): string {
    return moment.unix(unix)
      .format(BASE_TIME_FORMAT);
  }

}
