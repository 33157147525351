import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as formActions from './notification.actions';
import { NotificationActionTypes } from './notification.actions';
import { initialNotificationState, NOTIFICATIONS, NotificationState } from './notification.state';

export function notificationReducer(
  state: NotificationState = initialNotificationState,
  action: formActions.NotificationActions
): NotificationState {
  switch (action.type) {
    case NotificationActionTypes.ShowNotification:
      return {
        notification: {
          ...action.payload
        }
      };
    case NotificationActionTypes.HideNotification:
      return {
        notification: undefined
      };
    default:
      return state;
  }
}

const selectNotificationState = createFeatureSelector<NotificationState>(NOTIFICATIONS);

export const selectNotifications = createSelector(
  selectNotificationState,
  (state) => state.notification
);
