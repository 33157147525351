import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EventsBufferState, getAllBufferEvents, PastedEvent, RemoveEventBuffer } from '~/store/events-buffer';
import { FromStore } from '~/framework';
import { Observable } from 'rxjs';
import { Event as EventModel } from '~/shared/models';
import { BusinessTypeEnum } from '~/shared/models/agenda/business-type.enum';

@Component({
  selector: 'apfr-events-buffer',
  templateUrl: './events-buffer.component.html',
  styleUrls: ['./events-buffer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsBufferComponent implements OnInit, FromStore {
  businessType = BusinessTypeEnum;

  copiedEvents$: Observable<EventModel[]>;
  constructor(private readonly store: Store<EventsBufferState>) { }

  ngOnInit() {
    this.getDataFromStore();
  }

  getDataFromStore(): void {
    this.copiedEvents$ = this.store
      .pipe(select(getAllBufferEvents));
  }

  removeEvent(event: EventModel) {
    this.store.dispatch(new RemoveEventBuffer({eventId: event.id}));
  }

  pasteEvent(event: EventModel) {
    this.store.dispatch(new PastedEvent({eventId: event.id}));
  }

}
