import { Action } from '@ngrx/store';
import { NotificationModel } from '~/shared/models';

export enum NotificationActionTypes {
  ShowNotification = '[Notification] Show Notification',
  HideNotification = '[Notification] Hide Notification'
}

export class ShowNotification implements Action {
  readonly type = NotificationActionTypes.ShowNotification;

  constructor(public payload: NotificationModel) {
  }
}

export class HideNotification implements Action {
  readonly type = NotificationActionTypes.HideNotification;

  constructor(public payload: number) {
  }
}

export type NotificationActions =
  ShowNotification |
  HideNotification;
