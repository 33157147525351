export function updateArrayElement(arr: any[], el: any) {
  const index = arr.findIndex(item => item.id === el.id);
  if (index < 0) {
    return arr;
  }
  return [...arr.slice(0, index), el, ...arr.slice(index + 1)];
}

export function updateArrayElementByIndex(arr: any[], el: any, index: number) {
  if (index < 0) {
    return arr;
  }
  return [...arr.slice(0, index), el, ...arr.slice(index + 1)];
}

export function addElementYoArrayByIndex(arr: any[], el: any, index: number) {
  if (index < 0) {
    return arr;
  }
  return [...arr.slice(0, index), el, ...arr.slice(index)];
}
