import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'replaceContactName'})
export class ReplaceContactNamePipe implements PipeTransform {

  transform(value: string): string {
    return value.replace('--', ' ');
  }

}
