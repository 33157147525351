import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomerService } from '~/+customer/shared/customer.service';

import * as fromActions from './customer-form.actions';
import { CustomerFormActionTypes } from './customer-form.actions';

@Injectable()
export class CustomerFormEffects {
  loadCustomerForm$ = createEffect(() => this.actions$
    .pipe(
      ofType(CustomerFormActionTypes.LoadForm),
      switchMap(() => {
        return this.customerService.getCustomerForm()
          .pipe(
            map(fields => new fromActions.LoadFormSuccess(fields)),
            catchError(error => observableOf(new fromActions.LoadFormFail(error)))
          );
      })
    ));

  constructor(
    private actions$: Actions,
    private customerService: CustomerService) {
  }
}
