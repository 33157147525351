import moment from 'moment';
import { AgendaViewTypeEnum, Institution as InstitutionModel, Resource as ResourceModel } from '~/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AgendaSideInfoPayload, IAgendaSettings } from '~/store/agenda-view/agenda-view.actions';
import { FreeTimeShortData } from '~/+calendar/shared/institution.service';

export const AGENDA_VIEW = 'agenda_view';

export interface SelectedTimeI {
  secondaryColumn: { key: string, name: string };
  dateTime: string;
  resources?: Array<any>;
}

export const selectedInstitutionsAdapter: EntityAdapter<InstitutionModel> =
  createEntityAdapter<InstitutionModel>({
    selectId: cur => cur ? cur.id : null
  });

export const selectedResourcesAdapter: EntityAdapter<ResourceModel> =
  createEntityAdapter<ResourceModel>({
    selectId: cur => cur ? cur.id : null
  });

export interface AgendaViewState extends IAgendaSettings {
  selectedInstitutions: EntityState<InstitutionModel>;
  selectedResources: EntityState<ResourceModel>;
  isShowForm: boolean;
  groupedResources: boolean;
  maxDayAmount: number;
  groupedInstitutions: boolean;
  freeTimeShortData: FreeTimeShortData;
  columnsCount: number;
  agendaSideInfo: AgendaSideInfoPayload;
}

export const initialAgendaViewState = {
  numberOfDays: 1,
  selectedDate: moment(),
  intervalAgenda: 30,
  viewType: AgendaViewTypeEnum.DayView,
  selectedInstitutions: selectedInstitutionsAdapter.getInitialState(),
  selectedResources: selectedResourcesAdapter.getInitialState(),
  isShowForm: false,
  groupedResources: false,
  maxDayAmount: 6,
  groupedInstitutions: false,
  columnsCount: undefined,
  freeTimeShortData: undefined,
  agendaSideInfo: undefined,
  displayTimeLabel: true,
  isFormSticky: false
};
