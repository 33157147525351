import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { NAVIGATION, navigationInitialState, navigationReducer } from '~/store/navigation/navigation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NavigationEffects } from '~/store/navigation/navigation.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(NAVIGATION, navigationReducer, {initialState: navigationInitialState}),
    EffectsModule.forFeature([NavigationEffects])
  ]
})
export class NavigationStateModule {
}
