import { Action } from '@ngrx/store';
import { FieldConfig } from '~/shared/models';

export enum CustomerFormActionTypes {
  LoadForm = '[Customer Form] Load Form',
  LoadFormSuccess = '[Customer Form] Load Form Success',
  LoadFormFail = '[Customer Form] Load Form Fail'
}

export class LoadForm implements Action {
  readonly type = CustomerFormActionTypes.LoadForm;
}

export class LoadFormSuccess implements Action {
  readonly type = CustomerFormActionTypes.LoadFormSuccess;

  constructor(public payload: Array<FieldConfig>) {
  }
}

export class LoadFormFail implements Action {
  readonly type = CustomerFormActionTypes.LoadFormFail;

  constructor(public payload: any) {
  }
}

export type CustomerFormActions =
  LoadForm |
  LoadFormSuccess |
  LoadFormFail;
