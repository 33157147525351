import { createFeatureSelector } from '@ngrx/store';
import { entityErrorFn, entityStartLoadingFn, entityStopLoadingFn } from '~/store/entity-reducer.utils';

import { initialNoteState, noteAdapter, NOTES, NoteState } from './note.state';
import * as fromActions from './note.actions';
import { NoteActionTypes } from './note.actions';

export function noteReducer(
  state: NoteState = initialNoteState,
  action: fromActions.NoteActions
): NoteState {
  switch (action.type) {
    case NoteActionTypes.GetNotes:
      return entityStartLoadingFn(state);
    case NoteActionTypes.GetNotesSuccess:
      return noteAdapter.setAll([action.payload], entityStopLoadingFn(state));
    case NoteActionTypes.GetNotesFail:
      return entityErrorFn(state, action.payload);
    default:
      return state;
  }
}

const selectNoteState = createFeatureSelector<NoteState>(NOTES);

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = noteAdapter.getSelectors();

export const selectNoteIds = noteAdapter.getSelectors(selectNoteState).selectIds;
export const selectNoteEntities = noteAdapter.getSelectors(selectNoteState).selectEntities;
export const selectAllNotes = noteAdapter.getSelectors(selectNoteState).selectAll;
export const selectNotesTotal = noteAdapter.getSelectors(selectNoteState).selectTotal;

