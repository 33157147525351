import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { IframeContainerComponent } from './iframe-container.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    IframeContainerComponent
  ],
  exports: [
    IframeContainerComponent
  ]
})
export class IframeContainerModule {}
