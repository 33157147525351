import { ConfigAppState, CONFIG_APP, initialConfigAppState } from '~/store/config-app/config-app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromActions from './config-app.actions';
import { ConfigAppActionTypes } from './config-app.actions';

export function configAppReducers(
  state: ConfigAppState = initialConfigAppState,
  action: fromActions.ConfigAppActions
): ConfigAppState {
  switch (action.type) {
    case ConfigAppActionTypes.ConfigAppOnline:
      return {
        ...state,
        online: true
      };
    case ConfigAppActionTypes.ConfigAppOffline:
      return {
        ...state,
        online: false
      };
    case ConfigAppActionTypes.ConfigSetIsMobile:
      return {
        ...state,
        isTablet: false,
        isMobile: action.payload
      };
    case ConfigAppActionTypes.ConfigSetIsTablet:
      return {
        ...state,
        isMobile: false,
        isTablet: action.payload
      };
    case ConfigAppActionTypes.ConfigSetIsDesktop:
      return {
        ...state,
        isMobile: false,
        isTablet: false
      };
    case ConfigAppActionTypes.ConfigAppSetUrls:
      return {
        ...state,
        externalLinks: action.payload.externalLinks
      };
    default:
      return state;
  }
}

const selectConfigAppState = createFeatureSelector<ConfigAppState>(CONFIG_APP);

export const selectIsAppOnline = createSelector(
  selectConfigAppState,
  (state) => state.online
);

export const selectIsMobile = createSelector(
  selectConfigAppState,
  (state) => state ? state.isMobile : false
);

export const selectIsTablet = createSelector(
  selectConfigAppState,
  (state) => state.isTablet
);

export const selectIsNotDesktop = createSelector(
  selectConfigAppState,
  (state) => state ? (state.isMobile || state.isTablet) : false
);

export const selectExternalLinks = createSelector(
  selectConfigAppState,
  (state) => state.externalLinks
);
