import { AgendaComponent } from '~/shared/components';
import { select } from '@ngrx/store';
import { combineLatest, merge } from 'rxjs';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { flatMap, isEqual } from 'lodash/fp';
import { AgendaViewTypeEnum, Event as EventModel, Institution as InstitutionModel, Resource } from '~/shared/models';
import {
  getColumnsCount,
  getDisplayTimeLabel, getFreeTimeShortData,
  getGroupedByResources,
  getIsGroupedInstitutions,
  getNumberOfDays,
  getSelectedDate,
  getSelectedInst,
  getSelectedRes,
  getViewType, GroupedResourceType
} from '~/store/agenda-view';
import { getAllBufferEvents, getPastingEvent, getPastingEventId } from '~/store/events-buffer';
import { EventsBufferComponent } from '~/shared/components/agenda/events-buffer/events-buffer.component';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { getFreeTime } from '~/store/free-time';
import { FreeTimeResponse } from '~/+calendar/shared/calendar.service';
import { selectAllEvents, selectedEventId } from '~/store/calendar';
import { defaultCellHeight } from '~/shared/components/agenda/agenda.util';
import { FreeTimeShortData } from '~/+calendar/shared/institution.service';
import { Moment } from 'moment';
import { calendarRuleDataSelector } from '~/store/calendar-rule/calendar-rule.selectors';

/**
 * updateRouterUrl
 *
 * Update Router query params depending on the data that has been changed.
 */
export function updateRouterUrl(this: AgendaComponent) {
  combineLatest([
    this.store
      .pipe(
        select(getSelectedInst),
        filter(cur => cur.length > 0)
      ),
    this.store
      .pipe(
        select(getSelectedRes),
        filter(cur => cur.length > 0)
      ),
    this.store.select(getIsGroupedInstitutions),
    this.store.select(getGroupedByResources),
    this.store
      .pipe(
        select(getSelectedDate)
      )
  ])
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(([
                  selectedInstitutions,
                  selectedResources,
                  groupedByInst,
                  groupedResources,
                  startAtDate
                ]) => {

      const resourcesIds = flatMap((cur: InstitutionModel) => {
        return cur.children.map(res => res.id);
      })(selectedInstitutions);

      const selectedResourcesIds = selectedResources.map(cur => cur ? cur.id : null);
      const selectedInstitutionsIds = selectedInstitutions.map(cur => cur ? cur.id : null);

      const institutionsLabel = selectedInstitutionsIds.join(',');
      let resourcesLabel = selectedResourcesIds.join(',');

      resourcesLabel = isEqual(resourcesIds, selectedResourcesIds) ? GroupedResourceType.All : resourcesLabel;

      this.router.navigate(['.'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          'institutions': institutionsLabel,
          'date': startAtDate.format('YYYY-MM-DD'),
          'resources': groupedResources ? GroupedResourceType.Grouped : resourcesLabel,
          'groupedByInst': groupedByInst
        },
        queryParamsHandling: 'merge'
      });
    });
}

export function getDataFromStore(this: AgendaComponent) {
  this.displayTimeLabel$ = this.store.pipe(
    select(getDisplayTimeLabel),
    takeUntil(this.ngUnsubscribe)
  );

  combineLatest([
    this.store.select(getSelectedDate),
    this.store
      .pipe(
        select(getFreeTimeShortData),
        // skip first initial call
        filter(curr => curr !== undefined)
      ),
    this.store
      .select(getNumberOfDays),
    this.store
      .select(getViewType),
    this.store
      .pipe(
        select(getSelectedRes),
        filter(cur => cur.length > 0))
  ])
    .pipe(
      filter((params: [Moment, FreeTimeShortData, number, AgendaViewTypeEnum, Resource[]]) => {
        return !isEqual(this.agendaParamsState, params);
      }),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe((
      [date, freeTimeShortData, numberOfDays, viewType, resources]:
        [Moment, FreeTimeShortData, number, AgendaViewTypeEnum, Resource[]]
    ) => {
      this.agendaParamsState = [date, freeTimeShortData, numberOfDays, viewType, resources];
      this.dateChange(date);
      this.freeTimeShortDataChange(freeTimeShortData);
      this.numberOfDaysChange(numberOfDays);
      this.viewType = viewType;
      this.selectedResourcesChange(resources);

      this.updateCalendar();
    });

  this.store
    .pipe(
      select(getGroupedByResources),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(isGroupedByRes => {
      this.groupedRes(isGroupedByRes);
    });

  this.store
    .pipe(
      select(getSelectedInst),
      filter(cur => cur.length > 0),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe((institutions) => {
      this.cellHeight = institutions.length !== 1 ?
        defaultCellHeight :
        institutions[0].internalCellHeight;
      // set global variable for scss
      document.documentElement.style.setProperty('--cell-height', this.cellHeight + 'px');

      if (this.isGroupedByInst) {
        this.groupedInst(true);
      } else {
        this.selectedInstitutions = institutions;
      }
      this.cdRef.markForCheck();
    });

  this.store
    .pipe(
      select(getIsGroupedInstitutions),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(isGroupedIns => {
      this.groupedInst(isGroupedIns);
    });

  this.store
    .pipe(
      select(getColumnsCount),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(columnCount => {
      const el = document.querySelector('.agenda-calendar');
      const contentWidth = Math.floor(el.getBoundingClientRect().width);
      const windowWidth = window.innerWidth;

      this.checkWindowAndContentWidth({windowWidth, contentWidth, columnCount});
    });

  this.store
    .pipe(
      select(getPastingEventId),
      switchMap(() => this.store.pipe(select(getPastingEvent()))),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(pastingEvent => {
      this.pastingEvent = pastingEvent;
      this.cdRef.markForCheck();
    });

  this.store
    .pipe(
      select(getAllBufferEvents),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(res => {
      this.copiedEvents = res;
      let eventsBufferRef: MatBottomSheetRef;

      // if any copied events and any bottom sheets are opened
      if (this.copiedEvents.length > 0) {
        if (!this.bottomSheet._openedBottomSheetRef) {
          eventsBufferRef = this.bottomSheet.open(EventsBufferComponent, {
            hasBackdrop: false,
            disableClose: true
          });
        }
      } else {
        this.bottomSheet.dismiss();
      }
    });

  this.store
    .pipe(
      select(getFreeTime),
      // skip first initial call
      filter(curr => curr !== undefined),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe((freeTimeResponse: FreeTimeResponse) => {
      this.loadingFreeTime = false;
      this.freeTimeChange(freeTimeResponse);
    });

  if (this.isCalendarCmp) {
    this.store
      .pipe(
        select(selectAllEvents),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((events: EventModel[]) => {
        this.eventsChange(events);
      });
  }

  merge(
    this.store
      .pipe(
        select(selectedEventId),
        filter(curr => !!curr)
      ),
    this.store
      .pipe(
        select(calendarRuleDataSelector),
        filter(curr => !!curr)
      )
  )
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {
      if (!this.loadingFreeTime) {
        this.loadFreeTime();
      }
    });
}
