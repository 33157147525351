import { Injectable } from '@angular/core';
import { WebsocketService } from '~/framework/websocket/src/websocket.service';
import { WsEventModel } from '~/shared/models';

@Injectable({
  providedIn: 'root'
})
export class EventWebsocketService {
  constructor(private readonly wsService: WebsocketService) {
  }

  onEvents() {
    return this.wsService.on<WsEventModel>('events');
  }
}
