<mat-list>
  <mat-list-item *ngFor="let copiedEvent of (copiedEvents$ | async)">
    <div *ngIf="copiedEvent" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
      <label class="service">
        <mat-icon>storage</mat-icon>
        <span *ngIf="copiedEvent.typeCar">{{copiedEvent.typeCar.abreviation}}</span>
        <span *ngIf="copiedEvent.typeFuel"> - {{copiedEvent.typeFuel.abreviation}}</span>
      </label>
      <label class="name" *ngIf="copiedEvent.name && copiedEvent.resource.root.name === businessType.MOT">
        <mat-icon>directions_car</mat-icon>
        {{ copiedEvent.name }}
      </label>
      <label class="time">
        <mat-icon>schedule</mat-icon>
        <span>{{ copiedEvent.duration }}</span>
      </label>
      <div class="actions">
        <button mat-icon-button color="primary" (click)="pasteEvent(copiedEvent)">
          <mat-icon>reply</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="removeEvent(copiedEvent)">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>
</mat-list>
