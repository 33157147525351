import { createAction, props } from '@ngrx/store';
import { CurrentUser, UserTokenData } from '~/shared/models';

export const loadUser = createAction(
  '[Current User] Load'
);

export const loadUserSuccess = createAction(
  '[Current User] Load Success',
  props<{ currentUser: CurrentUser }>()
);

export const loadUserFail = createAction(
  '[Current User] Load Fail',
  props<{ error: any }>()
);

export const getUserFromToken = createAction(
  '[Current User] Get User From Token'
);

export const setUserFromToken = createAction(
  '[Current User] Set User From Token',
  props<{ userTokenData: UserTokenData }>()
);

export const removeCurrentUser = createAction(
  '[Current User] Remove'
);
