import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DomSanitizer } from '@angular/platform-browser';

import moment from 'moment';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';

import { BASE_DATE_FORMAT, BaseComponent, FromStore } from '~/framework';
import { Event as EventModel } from '~/shared/models';

import { ConfigAppState, selectIsMobile } from '~/store/config-app';
import { AgendaSideInfo, AgendaSideInfoType, AgendaViewState } from '~/store/agenda-view';
import { CopyEvent, EventsBufferState, getBufferEventsTotal } from '~/store/events-buffer';

import { EventsBufferComponent } from '../events-buffer/events-buffer.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FileUploadModalComponent } from '~/shared/components/agenda/file-upload-modal/file-upload-modal.component';
import { AgendaFormUrlParams, SnackBarComponent } from '~/shared/components';
import { CalendarService, NotificationKeyType } from '~/+calendar/shared/calendar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { BusinessTypeEnum } from '~/shared/models/agenda/business-type.enum';
import { isNotInPastEvent } from '~/shared/components/agenda/agenda.util';
import { DeleteEvent } from '~/store/calendar';
import { MatAlertDialogService } from '@apfr/components/core';

@Component({
  selector: 'apfr-agenda-info',
  templateUrl: './agenda-info.component.html',
  styleUrls: ['./agenda-info.component.scss']
})
export class AgendaInfoComponent extends BaseComponent implements OnInit, FromStore, OnDestroy {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input()
  event: EventModel;

  @Input()
  isMobile: boolean;
  @Input()
  isDraggable = false;

  @Input()
  isGroupedByResources: boolean;
  businessType = BusinessTypeEnum;
  NotificationKeyType = NotificationKeyType;
  isMobile$: Observable<boolean>;
  bufferEventsTotal$: Observable<number>;
  isShowConfirmationList: boolean = false;
  isNotInPast = false;
  isNotPastTimeout: NodeJS.Timeout | number;

  constructor(
    private readonly store: Store<ConfigAppState | AgendaViewState | EventsBufferState>,
    private readonly sanitizer: DomSanitizer,
    private readonly bottomSheet: MatBottomSheet,
    private readonly dialog: MatDialog,
    private readonly matAlertDialogService: MatAlertDialogService,
    private readonly calendarService: CalendarService,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.setIsNotPast();
    this.isNotPastTimeout = setInterval(() => {
      // update variable every minute
      this.setIsNotPast();
      this.cdr.markForCheck();
    }, 60000);
    this.getDataFromStore();

    if (this.bottomSheet._openedBottomSheetRef) {
      this.bottomSheet
        ._openedBottomSheetRef
        .backdropClick()
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(() => {
          this.bottomSheet.open(EventsBufferComponent, {
            hasBackdrop: false,
            disableClose: true
          });
        });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this.isNotPastTimeout);
  }

  private setIsNotPast() {
    this.isNotInPast = isNotInPastEvent(this.event);
  }

  getDataFromStore(): void {
    this.isMobile$ = this.store
      .pipe(select(selectIsMobile));

    this.bufferEventsTotal$ = this.store.pipe(
      select(getBufferEventsTotal)
    );
  }

  closeConfirmationList(): void {
    this.isShowConfirmationList = false;
    this.cdr.detectChanges();
  }

  toggleConfirmationList(): void {
    this.isShowConfirmationList = !this.isShowConfirmationList;
    this.cdr.detectChanges();
  }

  resendNotification(event: EventModel, notificationKey: NotificationKeyType): void {
    this.calendarService.resendNotification(+event.id, notificationKey)
      .subscribe(
        () => {
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 5_000,
            data: {
              message: 'notification_was_sent',
              action: 'ok'
            }
          });
        },
          error => {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 5_000,
              data: {
                message: 'something_went_wrong',
                action: 'ok'
              }
            });
          }
      );
  }

  openEditDialog() {
    const iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.baseFormIframeUrl}?id=${this.event.id}`);
    const dateTime = moment(new Date(this.event.startTime * 1000), BASE_DATE_FORMAT);
    const time: Partial<AgendaFormUrlParams> = {
      day: +dateTime.format('D'),
      minute: +dateTime.format('m'),
      hour: +dateTime.format('H'),
      year: +dateTime.format('YYYY'),
      month: +dateTime.format('M')
    };
    const secondaryColumn = {
      key: this.event.resource.id,
      name: this.event.resource.name
    };
    const selectedTime = {
      dateTime,
      secondaryColumn,
      time,
      selectedResource: this.event.resource.id,
      selectedResourceName: this.event.resource.name,
      selectedInstitution: this.event.resource.parent.id,
      selectedInstitutionName: this.event.resource.parent.name,
      isGroupedByResources: this.isGroupedByResources
    };
    this.store.dispatch(new AgendaSideInfo({
      type: AgendaSideInfoType.EventForm,
      data: {
        iframeSrc,
        event: this.event,
        selectedTime
      }
    }));
  }

  openFilesDialog(): void {
    let dialogConfig: MatDialogConfig = {
      panelClass: ['full-size-dialog', 'files-upload-modal'],
      data: {
        id: this.event.id,
        files: this.event.files,
        isMobile: this.isMobile
      }
    };
    if (this.isMobile) {
      dialogConfig = {
        ...dialogConfig,
        width: '100vw',
        maxWidth: '100%',
        height: '100vh'
      };
    } else {
      dialogConfig = {
        ...dialogConfig,
        width: '768px'
      };
    }
    this.dialog.open(FileUploadModalComponent, dialogConfig);
  }

  copyEvent() {
    this.bottomSheet.dismiss();
    // it's necessary for mobile copied dialog
    if (this.bottomSheet._openedBottomSheetRef) {
      this.bottomSheet._openedBottomSheetRef.afterDismissed()
        .subscribe(() => {
          this.store.dispatch(new CopyEvent({ event: this.event }));
        });
    } else {
      this.store.dispatch(new CopyEvent({ event: this.event }));
    }
  }

  deleteEvent() {
    this.matAlertDialogService.openDialog()
      .subscribe(() => {
        this.store.dispatch(new DeleteEvent({ eventId: this.event.id }));
      });
  }

}
