import { createFeatureSelector, createSelector } from '@ngrx/store';
import { entityErrorFn, entityStartLoadingFn, entityStopLoadingFn } from '~/store/entity-reducer.utils';
import { AppointmentActionTypes } from '~/store/customers';

import { appointmentAdapter, APPOINTMENTS, AppointmentState, initialAppointmentState } from './appointment.state';
import * as fromActions from './appointment.actions';

export function appointmentReducer(
  state: AppointmentState = initialAppointmentState,
  action: fromActions.AppointmentActions
): AppointmentState {
  switch (action.type) {
    case AppointmentActionTypes.GetAppointments:
      return entityStartLoadingFn(state);
    case AppointmentActionTypes.GetAppointmentsSuccess:
      return appointmentAdapter.setAll(action.payload, entityStopLoadingFn(state));
    case AppointmentActionTypes.GetAppointmentsFail:
      return entityErrorFn(state, action.payload);

    case AppointmentActionTypes.GetOneAppointment:
      return entityStartLoadingFn(state);
    case AppointmentActionTypes.GetOneAppointmentSuccess:
      return appointmentAdapter.upsertOne(action.payload, {
        ...entityStopLoadingFn(state),
        selectedId: action.payload.id
      });
    case AppointmentActionTypes.GetOneAppointmentFail:
      return entityErrorFn(state, action.payload);
    default:
      return state;
  }
}

const selectAppointmentState = createFeatureSelector<AppointmentState>(APPOINTMENTS);

export const selectAppointmentIds = appointmentAdapter.getSelectors(selectAppointmentState).selectIds;
export const selectAppointmentEntities = appointmentAdapter.getSelectors(selectAppointmentState).selectEntities;
export const selectAllAppointments = appointmentAdapter.getSelectors(selectAppointmentState).selectAll;
export const selectAppointmentTotal = appointmentAdapter.getSelectors(selectAppointmentState).selectTotal;

export const selectLoadingAppointment = createSelector(
  selectAppointmentState,
  (state) => state.isLoading
);

export const selectAppointmentId = createSelector(
  selectAppointmentState,
  (state) => state.selectedId
);

export const selectOneAppointment = createSelector(
  selectAppointmentEntities,
  selectAppointmentId,
  (entities, appointmentId) => entities[appointmentId]
);
