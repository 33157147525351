import { Component, ElementRef, Inject, OnInit, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { differenceWith, flatMap, getOr, head, intersectionWith, sortBy } from 'lodash/fp';

import { BaseComponent, DatabaseService, FromStore } from '~/framework';
import { BaseEntity, Institution as InstitutionModel, LocalStorageLabels, Resource } from '~/shared/models';
import { groupResources } from '~/shared/components/agenda/agenda.util';
import {
  AgendaViewState, FreeTimeShortDataChange,
  getColumnsCount,
  getGroupedByResources,
  getIsGroupedInstitutions,
  getSelectedInst,
  getSelectedRes,
  GroupedInstitutionsChange,
  GroupedResourcesChange,
  SelectedInstResChange
} from '~/store/agenda-view';
import { FreeTimeShortData, InstitutionService, InstitutionsResponse } from '~/+calendar/shared/institution.service';
import { SnackBarComponent } from '~/shared/components/snack-bar/snack-bar.component';
import { selectIsMobile } from '~/store/config-app';

function updateResources(institutions: Array<InstitutionModel>): Array<Resource> {
  return flatMap((cur: InstitutionModel) => {
    return getOr([], 'children', cur);
  }
  )(institutions);
}

@Component({
  selector: 'apfr-inst-hint-dialog',
  templateUrl: './inst-hint-dialog.component.html',
  styleUrls: ['./inst-hint-dialog.component.scss']
})
export class InstHintDialogComponent extends BaseComponent implements OnInit, FromStore {
  @ViewChild('institutionInput') institutionInput: ElementRef<HTMLInputElement>;
  @ViewChild('institutionAuto') institutionAuto: MatAutocomplete;

  selectedInstitutions: Array<InstitutionModel> = [];
  freeTimeShortData: FreeTimeShortData;
  filteredInstitutionOptions$: Observable<Array<BaseEntity>>;
  institutionControl = new FormControl();
  selectedResourcesControl: FormControl = new FormControl(null, Validators.required);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  resources: Array<Resource> = [];
  institutions: Array<BaseEntity>;
  groupedResources;

  isGroupByResource: boolean;
  isGroupByInst: boolean;

  isNotEnoughRoom: boolean;
  snackBarRef: MatSnackBarRef<SnackBarComponent>;
  columnsCount: number;

  isMobile: boolean;

  constructor(public dialogRef: MatDialogRef<InstHintDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private readonly store: Store<AgendaViewState>,
              private readonly databaseService: DatabaseService,
              private readonly institutionService: InstitutionService,
              private readonly snackBar: MatSnackBar,
              private readonly renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    this.getDataFromStore();
    this.valueChanges();

    this.dialogRef.afterClosed()
      .subscribe(() => {
        if (this.snackBarRef) {
          this.snackBarRef.dismiss();
        }
      });
  }

  private valueChanges() {
    this.selectedResourcesControl
      .valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((selectedResources) => {
        const selectedResourcesCount = selectedResources.length;
        if (!this.isGroupByResource) {
          if (this.columnsCount < selectedResourcesCount) {
            this.displaySnackBar(selectedResourcesCount, 'resource');
          } else {
            this.dismissSnackBar();
          }
        } else if (this.selectedInstitutions.length <= this.columnsCount) {
          this.dismissSnackBar();
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  removeInstitution(institution: InstitutionModel): void {
    const _selectedInstitutionsTemp = this.selectedInstitutions.filter(cur => cur.id !== institution.id);

    if (_selectedInstitutionsTemp.length === 0) {
      return;
    }

    this.selectedInstitutions = _selectedInstitutionsTemp;
    this.resources = updateResources(this.selectedInstitutions);
    this.groupedResources = groupResources(this.resources);
    this.resetInputValue();

    if (this.selectedInstitutions.length === 1) {
      this.isGroupByResource = false;
    }

    if (this.selectedInstitutions.length <= this.columnsCount) {
      this.dismissSnackBar();
    } else {
      this.displaySnackBar(this.selectedInstitutions.length, 'institution');
    }
    this.selectedResourcesControl.setValue(this.resources);
    // load again institutions because of freeTimeShortData property, it can change depends on how many institutions do we have
    this.getInstitutions(this.getSelectedInstitutionIds());
  }

  selectInstitution(institutionId: string) {
    const ids = this.getSelectedInstitutionIds();
    ids.push(institutionId);
    this.getInstitutions(ids);

    if (this.institutionInput) {
      this.institutionInput.nativeElement.blur();
    }
  }

  private getSelectedInstitutionIds(): string[] {
    return this.selectedInstitutions.map((inst: InstitutionModel) => inst.id);
  }

  displayInstitutionFn(institution?: BaseEntity): string | undefined {
    return institution ? institution.name : undefined;
  }

  /**
   * filterInstitution
   *
   * The First filter is in charge of
   * exclusion of the selected institutions from the search result.
   * The Second one is responsible for search by name.
   */
  private _filterInstitution(name: string): Array<BaseEntity> {
    const filterValue = name.toLowerCase();

    return this.institutions
      .filter((el) => {
        return !this.selectedInstitutions.map(cur => parseInt(cur.id))
          .includes(parseInt(el.id));
      })
      .filter(
        option => option.name.toLowerCase()
          .includes(filterValue)
    );
  }

  getDataFromStore(): void {
    this.databaseService.getInstitutions()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(institutions => {
        const iw = intersectionWith((a, b) => a.id === b.id, institutions, this.selectedInstitutions);
        const dw = differenceWith((a1, b) => a1.id === b.id, institutions, this.selectedInstitutions);

        this.institutions = [
          ...sortBy<BaseEntity>('name')(iw),
          ...sortBy<BaseEntity>('name')(dw),
        ];

        this.filteredInstitutionOptions$ = this.institutionControl
          .valueChanges
          .pipe(
            startWith<string | BaseEntity>(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterInstitution(name) : this.institutions
              .filter((el) => {
                return !this.selectedInstitutions.map(cur => parseInt(cur.id))
                  .includes(parseInt(el.id));
              })
            )
          );
      });

    this.store
      .pipe(
        select(getSelectedInst),
        map(institutions => {
          this.selectedInstitutions = [...institutions];

          return updateResources(institutions);
        }),
        filter((resources: Array<any>) => resources.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(resources => {
        this.resources = resources;
        this.groupedResources = groupResources(resources);
      });

    this.store
      .pipe(
        select(getSelectedRes),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((res) => {
        this.selectedResourcesControl.patchValue(res);
      });

    this.store
      .pipe(
        select(getGroupedByResources),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        this.isGroupByResource = res;
      });

    this.store
      .pipe(
        select(getIsGroupedInstitutions),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isGroupedIns => {
        this.isGroupByInst = isGroupedIns;
      });

    this.store
      .pipe(
        select(getColumnsCount),
        filter(cur => cur !== undefined)
      )
      .subscribe(columnsCount => {
        this.columnsCount = columnsCount;
      });

    this.store
      .pipe(
        select(selectIsMobile),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isMobile => this.isMobile = isMobile);
  }

  saveSelected() {
    this.dialogRef.close();
    this.store.dispatch(new SelectedInstResChange({
      selectedResources: this.selectedResourcesControl.value,
      selectedInstitutions: this.selectedInstitutions
    }));
    this.store.dispatch(new GroupedResourcesChange({
      groupedResources: this.isGroupByResource
    }));

    this.store.dispatch(new GroupedInstitutionsChange({
      groupedInstitutions: this.isGroupByInst
    }));

    this.store.dispatch(new FreeTimeShortDataChange({
      freeTimeShortData: this.freeTimeShortData
    }));

    const selectedInstitutionIds = [];
    this.selectedInstitutions.forEach(institution => selectedInstitutionIds.push(institution.id));
    // save selected institution_id to localStorage
    localStorage.setItem(LocalStorageLabels.selectedInstitution, selectedInstitutionIds.join(','));
  }

  private getInstitutions(ids: string[]) {
    this.institutionService
      .getInstitutions(ids)
      .pipe(
        map((institutionsResponse: InstitutionsResponse) => institutionsResponse),
        map((institutionsResponse: InstitutionsResponse) => {
          this.selectedInstitutions = institutionsResponse.institutions;
          this.freeTimeShortData = institutionsResponse.freeTimeShortData;

          this.resetInputValue();

          return updateResources(this.selectedInstitutions);
        }),
        filter((resources: Array<any>) => resources.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(resources => {
        this.resources = resources;
        this.groupedResources = groupResources(resources);

        if (this.selectedInstitutions.length === 1) {
          this.selectedResourcesControl.setValue(this.resources);
        } else if (this.selectedInstitutions.length > 1) {
          this.isGroupByResource = true;
          this.selectedResourcesControl.setValue(this.resources);
        }

        if (this.selectedInstitutions.length > this.columnsCount) {
          if (!this.isGroupByInst) {
            this.displaySnackBar(this.selectedInstitutions.length, 'institution');
          }
        }
      });
  }

  toggleGroupedResources(checked: boolean) {
    this.isGroupByResource = checked;

    this.selectedResourcesControl.setValue(this.resources);
  }

  toggleGroupedInst(checked: boolean) {
    this.isGroupByInst = checked;

    if (checked) {
      this.dismissSnackBar();
    } else {
      if (this.selectedInstitutions.length > this.columnsCount) {
        this.displaySnackBar(this.selectedInstitutions.length, 'institution');
      }
    }
  }

  displayResourcesFn(): string {
    return this.selectedResourcesControl.value
      .map(cur => cur.name)
      .join(', ');
  }

  private resetInputValue() {
    if (this.institutionInput) {
      this.institutionInput.nativeElement.value = '';
      this.institutionControl.setValue('');
      this.institutionInput.nativeElement.focus();
    }
  }

  private displaySnackBar(messageValue: number, actionType: 'resource' | 'institution') {
    this.isNotEnoughRoom = true;
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: 'not_enough_room_error',
        messageValue,
        action: 'grouped_all'
      }
    });

    this.snackBarRef.onAction()
      .subscribe(() => {
        actionType === 'resource' ?
          this.toggleGroupedResources(true) :
          this.toggleGroupedInst(true);
      });
  }

  private dismissSnackBar() {
    this.isNotEnoughRoom = false;
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  selectedInstitutionsChange(event: MatSelectChange) {
    if (event.value.length < this.selectedInstitutions.length) {
      const diff = differenceWith((a, b) => {
        return a.id === b.id;
      }, this.selectedInstitutions, (event.value as Array<BaseEntity>));

      this.removeInstitution(head(diff));
    } else {
      const diff = differenceWith((a, b) => {
        return a.id === b.id;
      }, (event.value as Array<BaseEntity>), this.selectedInstitutions);

      this.selectInstitution(head(diff).id);
    }
  }

  compareInstitutionFn(a: BaseEntity, b: InstitutionModel) {
    return a.id === b.id;
  }

  changeSelectHeight() {
    if (this.isMobile) {
      const el = document.querySelector('.selectBoxInModal');
      const {top} = el.getBoundingClientRect();
      this.renderer.setStyle(el, 'max-height', `calc(100vh - ${top}px - 100px)`, RendererStyleFlags2.Important);
    }
  }
}
