import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventStack } from '~/shared/models/events.model';
import { AgendaViewTypeEnum } from '~/shared/models';
import { ColorModeEnum } from '~/shared/models/agenda/color-mode.enum';
import { Event as EventModel } from '../../../models/graphql/generated';
import { calculateHeight, calculateStartPosition, isNotInPastEvent } from '~/shared/components/agenda/agenda.util';
import { EventTypes } from '~/store/calendar';
import { MouseDown } from '~/shared/components/agenda/agenda-event/agenda-event.component';


@Component({
  selector: 'apfr-agenda-event-stack',
  templateUrl: './agenda-event-stack.component.html',
  styleUrls: ['./agenda-event-stack.component.scss']
})
export class AgendaEventStackComponent implements OnInit {

  @Input()
  set eventStack(eventStack: EventStack) {
    this._eventStack = eventStack;
    this.setStyles();
  }

  get eventStack(): EventStack {
    return this._eventStack;
  }
  @Input() isGroupedByResources: boolean;
  @Input() viewType: AgendaViewTypeEnum;
  @Input() cellHeight: number;
  @Input() startAgenda: string;
  @Input() intervalAgenda: number;
  @Input() isMobile: boolean;
  @Input() numberOfDays: number;
  @Input() colorMode: ColorModeEnum;
  @Input() isGroupedByInst: boolean;
  @Input() draggingEvent?: EventModel;
  @Input() selectedColor?: string;
  @Input() allowOverbooking: boolean;
  @Input()
  isDraggable = false;
  @Output() selectEvent = new EventEmitter<EventModel>();
  @Output() mouseDownEvent = new EventEmitter<MouseDown>();
  styles: Object;
  _eventStack: EventStack;

  isDraggableEvent(event: EventModel): boolean {
    return event.type !== EventTypes.Tampon && this.isDraggable;
  }

  isShowEvent(event: EventModel): boolean {
    if (!this.selectedColor) {
      return true;
    }

    return event?.eventType.color === this.selectedColor || event?.creator?.profile?.color === this.selectedColor;
  }

  ngOnInit(): void {
    this.setStyles();
  }

  setStyles() {
    if (!this.startAgenda || !this.intervalAgenda || !this.cellHeight) {
      return;
    }

    const event = this.eventStack.events[0] as EventModel;
    const backgroundColor = this.colorMode === ColorModeEnum.Type ? event?.eventType?.color : event?.creator?.profile?.color;
    const transparentBackgroundColor = backgroundColor + '7B'; // hex equivalent to rgba

    this.styles = {
      top: `${calculateStartPosition(this.eventStack.startTime,
        this.startAgenda,
        this.intervalAgenda,
        this.cellHeight)}px`,
      height: `${calculateHeight(this.eventStack.startTime, this.eventStack.endTime,
        this.intervalAgenda,
        this.cellHeight)}px`,
      'background-color': transparentBackgroundColor,
      width: this.allowOverbooking ? 'calc(100% - 20px)' : '100%'
    };
  }

  _selectEvent($event: EventModel) {
    if (isNotInPastEvent($event)) {
      this.selectEvent.emit($event);
    }
  }

  _mouseDown($event: MouseDown) {
    if (isNotInPastEvent($event.event)) {
      this.mouseDownEvent.emit($event);
    }
  }

  trackByFn(index, item) {
    return item.id ?? index;
  }
}
