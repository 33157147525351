import { NgModule } from '@angular/core';

import { FilterPipe } from './filter.pipe';
import { HumanizePipe } from './humanize.pipe';
import { ObjectValuePipe } from './object-value.pipe';
import { SplitPipe } from '~/shared/pipes/split-schedule.pipe';
import { TimeAgoExtendsPipe } from '~/shared/pipes/time-ago-extends.pipe';
import { ReplaceContactNamePipe } from '~/shared/pipes/replace-contact-name.pipe';
import { FromUnixToTimePipe } from '~/shared/pipes/from-unix-to-time.pipe';

const PIPES = [
  FilterPipe,
  HumanizePipe,
  ObjectValuePipe,
  SplitPipe,
  ReplaceContactNamePipe,
  TimeAgoExtendsPipe,
  FromUnixToTimePipe,
];

@NgModule({
  declarations: [
    PIPES
  ],
  exports: [
    PIPES
  ]
})
export class CommonPipeModule { }
