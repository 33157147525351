import { Event as EventModel } from '~/shared/models/graphql/generated';
import { ReorganizeEvents } from '~/shared/components/agenda/agenda.util';
import { maxBy, minBy } from 'lodash';
import { BASE_TIME_FORMAT } from '~/framework';
import moment from 'moment';

export class EventStack {
  constructor(events: EventModel[]) {
    this._events = events;
  }

  private _events: Array<EventModel | ReorganizeEvents> = [];

  get events(): Array<EventModel | ReorganizeEvents> {
    return this._events;
  }

  get count(): number {
    return this.events.length;
  }

  get startTime(): number {
    return this.count ? minBy(this._events as ArrayLike<EventModel>, e => e.startTime).startTime : 0;
  }

  get startTimeString(): string {
    return moment.unix(this.startTime).format(BASE_TIME_FORMAT);
  }

  get endTime(): number {
    return this.count ? maxBy(this._events as ArrayLike<EventModel>, e => e.endTime).endTime : 0;

  }

  update(events: EventModel[]) {
    this._events = events;
  }

  insert(event: EventModel | ReorganizeEvents, options: EventStackInsertOptions = defaultInsertOptions) {
    const o = {...defaultInsertOptions, ...options};
    this._events.push(event);
    if (o.sort) {
      this._events.sort(o.sortPredicate);
    }
  }

}

const defaultSortPredicate = (a: EventModel | ReorganizeEvents, b: EventModel | ReorganizeEvents) => +a.startTime - +b.startTime;

const defaultInsertOptions: EventStackInsertOptions = {
  sort: false,
  sortPredicate: defaultSortPredicate
};

export interface EventStackInsertOptions {
  sort: boolean;

  sortPredicate?(a: EventModel | ReorganizeEvents, b: EventModel | ReorganizeEvents): number;
}
