import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, ComponentRef, ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { AgendaViewTypeEnum, Event, Event as EventModel } from '~/shared/models';
import { BREAKPOINT_NUMBER_OF_DAYS, setStyleToEvent } from '~/shared/components/agenda/agenda.util';
import { ColorModeEnum } from '~/shared/models/agenda/color-mode.enum';
import { EventStatuses, EventTypes } from '~/store/calendar';
import { BusinessTypeEnum } from '~/shared/models/agenda/business-type.enum';
import { AgendaEventMotComponent } from '~/shared/components/agenda/agenda-event/agenda-event-mot/agenda-event-mot.component';
import { AgendaEventBeautyComponent } from '~/shared/components/agenda/agenda-event/agenda-event-beauty/agenda-event-beauty.component';
import { AgendaEventDriverComponent } from '~/shared/components/agenda/agenda-event/agenda-event-driver/agenda-event-driver.component';
import { AgendaEventGarageComponent } from '~/shared/components/agenda/agenda-event/agenda-event-garage/agenda-event-garage.component';
import { AgendaEventHairComponent } from '~/shared/components/agenda/agenda-event/agenda-event-hair/agenda-event-hair.component';
import { AgendaEventFoodComponent } from '~/shared/components/agenda/agenda-event/agenda-event-food/agenda-event-food.component';
import { AgendaEventHealthComponent } from '~/shared/components/agenda/agenda-event/agenda-event-health/agenda-event-health.component';
import { AgendaEventBasicserviceComponent } from '~/shared/components/agenda/agenda-event/agenda-event-basicservice/agenda-event-basicservice.component';
import { AgendaEventEthtestComponent } from '~/shared/components/agenda/agenda-event/agenda-event-ethtest/agenda-event-ethtest.component';
import { AgendaEventDefaultComponent } from '~/shared/components/agenda/agenda-event/agenda-event-default/agenda-event-default.component';
import { lightenDarkenColor } from '~/framework/util/color.util';

const components = {
  [BusinessTypeEnum.MOT]: AgendaEventMotComponent,
  [BusinessTypeEnum.BEAUTY]: AgendaEventBeautyComponent,
  [BusinessTypeEnum.DRIVER]: AgendaEventDriverComponent,
  [BusinessTypeEnum.GARAGE]: AgendaEventGarageComponent,
  [BusinessTypeEnum.HAIR]: AgendaEventHairComponent,
  [BusinessTypeEnum.FOOD]: AgendaEventFoodComponent,
  [BusinessTypeEnum.HEALTH]: AgendaEventHealthComponent,
  [BusinessTypeEnum.BASICSERVICE]: AgendaEventBasicserviceComponent,
  [BusinessTypeEnum.ETHTEST]: AgendaEventEthtestComponent
};

export interface MouseDown {
  event: Event;
  mouseEvent: MouseEvent;
}

@Component({
  selector: 'apfr-agenda-event',
  templateUrl: './agenda-event.component.html',
  styleUrls: ['./agenda-event.component.scss']
})
export class AgendaEventComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('eventTemplateContainer' , {read: ViewContainerRef}) private vcr: ViewContainerRef;

  @Input()
  set event(event: EventModel) {
    this._event = event;
    this.businessName = event.resource.root.name;
    this.loadEventTemplate();
  }

  get event(): EventModel {
    return this._event;
  }

  @Input()
  startAgenda: string;

  @Input()
  intervalAgenda: number;

  @Input()
  isMobile: boolean;

  @Input()
  isGroupedByResources: boolean;

  @Input()
  viewType: AgendaViewTypeEnum;

  @Input()
  isDraggable: boolean;

  @Input()
  numberOfDays: number;

  @Input()
  cellHeight: number;

  @Input()
  set colorMode(colorMode: ColorModeEnum) {
    this._colorMode = colorMode;
    this.setStyles();
  }
  get colorMode(): ColorModeEnum {
    return this._colorMode;
  }

  @Input() draggingEvent?: EventModel;

  @Output()
  mouseDown = new EventEmitter<MouseDown>();

  @ViewChild('agendaEventEl') agendaEventEl: ElementRef;
  _colorMode: ColorModeEnum;
  _event: EventModel;
  EventStatuses = EventStatuses;
  EventTypes = EventTypes;
  businessName: string;
  componentRef: ComponentRef<any>;
  breakpointNumberOfDays = BREAKPOINT_NUMBER_OF_DAYS;
  clicked = false;
  styles: Object;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setStyles();
  }

  private setStyles() {
    this.styles = setStyleToEvent(this.event, this.startAgenda, this.intervalAgenda, this.cellHeight, this.viewType, this.colorMode);
  }

  ngAfterViewInit() {
    this.loadEventTemplate();
  }

  ngOnDestroy() {
    this.destroyComponent();
  }

  mousedown(mouseEvent: MouseEvent) {
    if (this.event.contactTel || this.event.contactName || this.event.contactEmail) {
      this.mouseDown.emit({
        event: this.event,
        mouseEvent: mouseEvent
      });
    }
  }

  getColor(darker = false): string | null {
    const color = this.colorMode === ColorModeEnum.Type ? this.event?.creator?.profile?.color : this.event.eventType.color;
    if (color) {
      return lightenDarkenColor(color, darker ? -30 : 0);
    }

    return null;
  }

  private destroyComponent(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  private loadEventTemplate(): void {
    if ((!this.event.contactName && !this.event.contactTel && !this.event.contactEmail) || !this.vcr) {
      return;
    }
    this.destroyComponent();
    const resolver = this.getTemplate();

    this.componentRef = this.vcr.createComponent(resolver);
    this.componentRef.instance.event = this.event;
    this.componentRef.instance.numberOfDays = this.numberOfDays;
    this.cdRef.detectChanges();
  }

  private getTemplate() {
    const component = components[this.businessName] ? components[this.businessName] : AgendaEventDefaultComponent;
    return this.componentFactoryResolver.resolveComponentFactory(component);
  }
}
