import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { USER_SETTINGS } from './user-settings.state';
import { userSettingsReducer } from './user-settings.reducer';
import { UserSettingsEffects } from './user-settings.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(USER_SETTINGS, userSettingsReducer),
    EffectsModule.forFeature([UserSettingsEffects])
  ]
})
export class UserSettingsStateModule {
}
