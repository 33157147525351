import { Action } from '@ngrx/store';
import { Event as EventModel, EventRequestModel } from '~/shared/models';
import { Update } from '@ngrx/entity';

export enum EventStatuses {
  InProgress = 'y',
  CV = 'v',
  Done = 'd',
  NotDone = 'w'
}

export enum EventTypes {
  CT = 'A',
  Tampon = 'C',
  Ferme = 'B'
}

export enum CalendarActionTypes {
  GetAgenda = '[Calendar] Get Agenda',
  GetAgendaSuccess = '[Calendar] Get Agenda Success',
  GetAgendaFail = '[Calendar] Get Agenda Fail',

  GetAgendaFromDB = '[Calendar] Get FromDB',
  SetAgendaToDB = '[Calendar] Set to DB',
  SetAgendaFromDB = '[Calendar] Set Agenda from DB',

  RemoveEvents = '[Calendar] Remove Events',

  UpdateEvent = '[Calendar] Update Event',
  UpdateEventSuccess = '[Calendar] Update Event Success',
  AddEventFromWS = '[Calendar] Add Event From WS',

  AddEvent = '[Calendar] Add New Event',
  AddEventSuccess = '[Calendar] Add New Event Success',
  AddEventFail = '[Calendar] Add New Event Fail',

  DeleteEvent = '[Calendar] Delete Event',
  DeleteEventSuccess = '[Calendar] Delete Event Success',
  DeleteEventFromWS = '[Calendar] Delete Event From WS',
  DeleteEventFail = '[Calendar] Delete Event Fail'
}

export class GetAgenda implements Action {
  readonly type = CalendarActionTypes.GetAgenda;

  constructor(public payload: EventRequestModel) {
  }
}

export class GetAgendaSuccess implements Action {
  readonly type = CalendarActionTypes.GetAgendaSuccess;

  constructor(public payload: Array<EventModel>) {
  }
}

export class GetAgendaFail implements Action {
  readonly type = CalendarActionTypes.GetAgendaFail;

  constructor(public payload: any) {
  }
}

export class GetAgendaFromDB implements Action {
  readonly type = CalendarActionTypes.GetAgendaFromDB;
}

export class SetAgendaToDB implements Action {
  readonly type = CalendarActionTypes.SetAgendaToDB;

  constructor(public payload: Array<EventModel>) {
  }
}

export class SetAgendaFromDB implements Action {
  readonly type = CalendarActionTypes.SetAgendaFromDB;

  constructor(public payload: Array<EventModel>) {
  }
}

export class RemoveEvents implements Action {
  readonly type = CalendarActionTypes.RemoveEvents;

  constructor(public payload: { eventsIds: Array<number> }) {
  }
}

export class UpdateEvent implements Action {
  readonly type = CalendarActionTypes.UpdateEvent;

  constructor(public payload: { updateData: Update<EventModel> }) {
  }
}

export class UpdateEventSuccess implements Action {
  readonly type = CalendarActionTypes.UpdateEventSuccess;

  constructor(public payload: { updatedEvent: EventModel }) {
  }
}
export class AddEventFromWS implements Action {
  readonly type = CalendarActionTypes.AddEventFromWS;

  constructor(public payload: { updatedEvent: EventModel }) {
  }
}

export class AddEventSuccess implements Action {
  readonly type = CalendarActionTypes.AddEventSuccess;

  constructor(public payload: { eventId: number }) {
  }
}

export class AddEventFail implements Action {
  readonly type = CalendarActionTypes.AddEventFail;
  constructor(public payload: any) {
  }
}

export class DeleteEvent implements Action {
  readonly type = CalendarActionTypes.DeleteEvent;

  constructor(public payload: { eventId: number }) {
  }
}

export class DeleteEventSuccess implements Action {
  readonly type = CalendarActionTypes.DeleteEventSuccess;

  constructor(public payload: { eventId: number }) {
  }
}

export class DeleteEventFail implements Action {
  readonly type = CalendarActionTypes.DeleteEventFail;

  constructor(public payload: any) {
  }
}

export class DeleteEventFromWS implements Action {
  readonly type = CalendarActionTypes.DeleteEventFromWS;

  constructor(public payload: { eventId: number }) {
  }
}
export type CalendarActions =
  GetAgenda |
  GetAgendaSuccess |
  GetAgendaFail |
  GetAgendaFromDB |
  SetAgendaToDB |
  SetAgendaFromDB |
  RemoveEvents |
  UpdateEvent |
  UpdateEventSuccess |
  AddEventSuccess |
  AddEventFail |
  DeleteEvent |
  DeleteEventSuccess |
  AddEventFromWS |
  DeleteEventFromWS |
  DeleteEventFail;
