import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { MaterialModule, SharedModule } from '~/framework';

import { CardDetailComponent } from './card-detail.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    MatExpansionModule
  ],
  exports: [
    CardDetailComponent
  ],
  declarations: [CardDetailComponent]
})
export class CardDetailModule { }
