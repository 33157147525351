import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService, configApiUrls } from '~/framework';

@Injectable({
  providedIn: 'root'
})
export class NoteService extends BaseService {

  getNotes(customerId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${configApiUrls.customers}/${customerId}/${configApiUrls.notes}`)
      .pipe(map((cur: any) => cur[0]));
  }
}
