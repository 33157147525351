import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'apfr-iframe-container',
  styleUrls: ['./iframe-container.component.scss'],
  template: `<mat-spinner *ngIf="iframeLoading"></mat-spinner>
  <div class="iframe-container" [hidden]="iframeLoading">
    <iframe *ngIf="iframeSrc" [src]="iframeSrc" (load)="loadComplete()"></iframe>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeContainerComponent {
  @Input()
  set iframeSrc(iframeSrc: SafeResourceUrl) {
   this.iframeLoading = true;
   this._iframeSrc = iframeSrc;
  }
  get iframeSrc() {
    return this._iframeSrc;
  }
  iframeLoading = true;

  private _iframeSrc: SafeResourceUrl;

  loadComplete() {
    this.iframeLoading = false;
  }
}
