import { ModuleWithProviders, NgModule } from '@angular/core';
import { WebSocketConfig, WebsocketService, WEB_SOCKET_CONFIG } from '~/framework/websocket/src/websocket.service';
import { EventWebsocketService } from '~/framework/websocket/src/event-websocket.service';

@NgModule()
export class WebsocketModule {
  static config(wsConfig: WebSocketConfig): ModuleWithProviders<any> {
    return {
      ngModule: WebsocketModule,
      providers: [
        {
          provide: WEB_SOCKET_CONFIG,
          useValue: wsConfig
        },
        WebsocketService,
        EventWebsocketService
      ]
    };
  }
}
