import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CURRENT_USER } from './current-user.state';
import { currentUserReducerFn } from './current-user.reducers';
import { CurrentUserEffects } from './current-user.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CURRENT_USER, currentUserReducerFn),
    EffectsModule.forFeature([CurrentUserEffects])
  ]
})
export class CurrentUserStateModule {
}
