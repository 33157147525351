import { Action } from '@ngrx/store';
import { FreeTimeRequestModel } from '~/shared/models/agenda/free-time.model';
import { FreeTimeResponse } from '~/+calendar/shared/calendar.service';

export const enum FreeTimeActionTypes {
  GetFreeTime = '[FreeTime] Get Free Time',
  GetFreeTimeSuccess = '[FreeTime] Get Free Time Success',
  GetFreeTimeFail = '[FreeTime] Get Free Time Success'
}

export class GetFreeTime implements Action {
  readonly type = FreeTimeActionTypes.GetFreeTime;

  constructor(public payload: FreeTimeRequestModel) {
  }
}

export class GetFreeTimeSuccess implements Action {
  readonly type = FreeTimeActionTypes.GetFreeTimeSuccess;

  constructor(public payload: { freeTimeResponse: FreeTimeResponse }) {
  }
}

export class GetFreeTimeFail implements Action {
  readonly type = FreeTimeActionTypes.GetFreeTimeFail;

  constructor(public payload: { error: any }) {
  }
}

export type FreeTimeActions =
  GetFreeTime |
  GetFreeTimeSuccess |
  GetFreeTimeFail;
