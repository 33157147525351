import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CUSTOMER_FORM } from './customer-form.state';
import { customerFormReducer } from './customer-form.reducer';
import { CustomerFormEffects } from './customer-form.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(CUSTOMER_FORM, customerFormReducer),
    EffectsModule.forFeature([CustomerFormEffects])
  ]
})
export class CustomerFormStateModule {
}
