import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { AppointmentFormState, LoadForm, selectAppointmentFields } from '~/store/customers/appointment/appointment-form';

@Injectable()
export class AppointmentFormResolver implements Resolve<void> {
  constructor(private store: Store<AppointmentFormState>) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): void {
    this.store.select(selectAppointmentFields)
      .subscribe((fields) => {
        if (fields.length === 0) {
          this.store.dispatch(new LoadForm());
        }
      });
  }
}
