import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'apfr-select-all',
  template: `
    <div class="full-width position-relative">
      <mat-checkbox class="mat-option"
                    [disableRipple]="true"
                    [indeterminate]="isIndeterminate()"
                    [checked]="isChecked()"
                    [disabled]="disabled"
                    (change)="toggleSelection($event)" #trigger>
        {{ text }}
      </mat-checkbox>
      <div class="mat-option-ripple" mat-ripple [matRippleTrigger]="trigger._elementRef.nativeElement"></div>
    </div>
  `,
  styles: ['']
})
export class SelectAllCheckboxComponent implements OnInit {
  @Input() control: FormControl;
  @Input() values = [];
  @Input() text = 'Select All';
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit() {
  }

  isChecked(): boolean {
    return this.control.value && this.values.length
      && this.control.value.length === this.values.length;
  }

  isIndeterminate(): boolean {
    return this.control.value && this.values.length && this.control.value.length
      && this.control.value.length < this.values.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.control.setValue(this.values);
    } else {
      this.control.setValue([]);
    }
  }
}
