import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';

import { CardTabComponent } from './card-tab/card-tab.component';

@Component({
  selector: 'apfr-card-relationship',
  templateUrl: './card-relationship.component.html',
  styleUrls: ['./card-relationship.component.scss']
})
export class CardRelationshipComponent implements OnInit, AfterContentInit {
  @ContentChildren(CardTabComponent) tabs: QueryList<CardTabComponent>;

  constructor() {}

  ngOnInit() {
  }

  ngAfterContentInit() {
    if (this.tabs.length === 0) {
      return;
    }

    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: CardTabComponent) {
    this.tabs.toArray()
      .forEach((item) => item.active = false);

    tab.active = true;
  }
}
