import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Event as EventModel } from '~/shared/models';
import { ReorganizeEvents } from '~/shared/components/agenda/agenda.util';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventTypes } from '~/store/calendar';
import { EventGroupDialogOptions } from '~/shared/components/agenda/agenda-event-grouped/agenda-event-grouped.component';

@Component({
  selector: 'apfr-agenda-event-group-dialog',
  templateUrl: './agenda-event-group-dialog.component.html',
  styleUrls: ['./agenda-event-group-dialog.component.scss']
})
export class AgendaEventGroupDialogComponent implements OnInit {
  EventTypes = EventTypes;
  @Output() selectEvent = new EventEmitter<EventModel>();
  eventRes: ReorganizeEvents;
  options: { isDraggable: boolean; isMobile: boolean; isGroupedByResources: boolean };

  constructor(@Inject(MAT_DIALOG_DATA) data: EventGroupDialogOptions) {
    this.eventRes = data.eventRes;
    this.options = data.options;
  }

  ngOnInit(): void {
  }

  trackByFn(index, event: EventModel) {
    return event.id;
  }
}
