<div mat-dialog-title>
  <header>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button class="material-drawer-button" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
        <span class="spacer"></span>
        <button mat-button cdkFocusInitial (click)="applySettings()">
          {{ 'apply' | translate }}
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </header>
</div>
<div mat-dialog-content fxLayout="row" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" fxLayout.lt-sm="column">
  <div fxFlex="1 1 75%" fxFlex.lt-sm="0 1 auto">
    <mat-calendar [startAt]="dateValue" [selected]="dateValue" #matCalendar="matCalendar"
                  [headerComponent]="datepickerHeaderComponent"
                  [dateClass]="currentDateSelection"
                  (selectedChange)="selectedDateChange($event)">
    </mat-calendar>
  </div>
  <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start center">
    <mat-form-field class="full-width" floatLabel="never">
      <mat-select [placeholder]="'select_number_of_days'"
                  [value]="numberOfDaysValue"
                  [disabled]="viewType === AgendaViewTypeEnum.WeekView"
                  (selectionChange)="numberOfDaysChangeEvent($event.value)">
        <mat-option *ngFor="let day of dayAmount" [value]="day">
          {{ day }} {{ 'days' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="slides" fxLayout="column" fxLayoutGap="24px">
      <div class="slide-toggle-container">
        <label>{{ 'by_week' | translate }}</label>
        <mat-slide-toggle [checked]="viewType === AgendaViewTypeEnum.WeekView"
                          (change)="viewTypeChange($event.checked)">
        </mat-slide-toggle>
      </div>

      <div class="slide-toggle-container">
        <label>{{ 'display_time_labels' | translate }}</label>
        <mat-slide-toggle [checked]="displayTimeLabel"
                          (change)="displayTimeLabelChange($event.checked)">
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
