import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalStorageLabels } from '~/shared/models';
import { CurrentUserService } from '~/store/current-user/current-user.service';
import { DATE_FORMAT } from '~/framework';
import { Moment } from 'moment';

import {
  AgendaViewActionTypes,
  DateChange, IAgendaSettings,
  NumberOfDaysChange,
  SaveAgendaSettings,
  SetAgendaSettings,
  ShowFormChange
} from './agenda-view.actions';

@Injectable()
export class AgendaViewEffects {
  selectedEvent$ = createEffect(() => this.actions$
    .pipe(
      ofType(AgendaViewActionTypes.AgendaSideInfo),
      switchMap(
        () => of(new ShowFormChange({isShowForm: true}))
      )
    ));
  dateChange$ = createEffect(() => this.actions$
    .pipe(
      ofType(AgendaViewActionTypes.DateChange),
      map((action: DateChange) => action.payload.date),
      map((date: Moment) => {
        localStorage.setItem(LocalStorageLabels.selectedDate, date.format(DATE_FORMAT));
      })
    ), {dispatch: false});
  numberOfDateChange$ = createEffect(() => this.actions$
    .pipe(
      ofType(AgendaViewActionTypes.NumberOfDaysChange),
      map((action: NumberOfDaysChange) => action.payload),
      map(numberOfDays => {
        localStorage.setItem(LocalStorageLabels.numberOfDays, numberOfDays.toString());
      })
    ), {dispatch: false});
  agendaSettingsChange$ = createEffect(() => this.actions$
    .pipe(
      ofType(AgendaViewActionTypes.SetAgendaSettings),
      filter((action: SetAgendaSettings) => !action.payload.excludeUpdate),
      map((action: SetAgendaSettings) => action.payload.agendaSettings),
      map(({selectedDate, numberOfDays, displayTimeLabel, viewType, isFormSticky}) => {
        if (selectedDate) {
          localStorage.setItem(LocalStorageLabels.selectedDate, selectedDate.format(DATE_FORMAT));
        }
        if (numberOfDays) {
          localStorage.setItem(LocalStorageLabels.numberOfDays, numberOfDays.toString());
        }

        if (isFormSticky) {
          localStorage.setItem(LocalStorageLabels.stickyForm, isFormSticky.toString());
        }

        return new SaveAgendaSettings({
          agendaSettings: {
            selectedDate,
            numberOfDays,
            displayTimeLabel,
            viewType,
            isFormSticky
          }
        });
      })
    ));
  saveAgendaSettingsEffect$ = createEffect(() => this.actions$
    .pipe(
      ofType(AgendaViewActionTypes.SaveAgendaSettings),
      map((action: SaveAgendaSettings) => action.payload.agendaSettings),
      switchMap((agendaSettings: Partial<IAgendaSettings>) => {
        return this.userService.updateProfile({
          isTimeLabelDisplay: agendaSettings.displayTimeLabel,
          howManyDaysDisplay: agendaSettings.numberOfDays,
          isFormSticky: agendaSettings.isFormSticky
        });
      })
    ), {dispatch: false});

  constructor(private actions$: Actions,
              private userService: CurrentUserService) {
  }
}
