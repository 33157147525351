import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { CALENDAR_RULE } from '~/store/calendar-rule/calendar-rule.state';
import { CalendarRuleReducer } from '~/store/calendar-rule/calendar-rule.reducer';
import { CalendarRuleEffects } from '~/store/calendar-rule/calendar-rule.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CALENDAR_RULE, CalendarRuleReducer),
    EffectsModule.forFeature([CalendarRuleEffects])
  ]
})
export class CalendarRuleStateModule {
}
