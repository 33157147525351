import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment, { Moment } from 'moment';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';
import {
  BaseComponent,
  BASE_DATE_FORMAT,
  BASE_DATE_TIME_FORMAT,
  BASE_TIME_FORMAT,
  DatabaseService,
  DATE_FORMAT,
  objectToQueryParams, SHORT_DATE_FORMAT
} from '~/framework';
import { concatDateTime, fromResize, getMomentDateTimeFromBaseFormat, getScrollbarWidth } from '~/framework/util';
import { ShowNotification } from '~/store/notification';
import { AddEventFromWS, DeleteEventFromWS, EventState, GetAgenda } from '~/store/calendar';
import {
  AgendaSideInfo,
  AgendaSideInfoType,
  DateChange,
  HowManyColumnsAvailable,
  MaxDayAmountChange,
  NumberOfDaysChange,
  SelectedInstResChange
} from '~/store/agenda-view';
import { PastedEvent, PasteEvent, UndoPaste } from '~/store/events-buffer';
// @ts-ignore
import {
  AgendaViewTypeEnum, CalendarRuleDataModel,
  Event as EventModel,
  Institution as InstitutionModel,
  Resource,
  UserExtraDataModel, WsActionEnum, WsEventModel
} from '~/shared/models';
import { SnackBarComponent } from '~/shared/components/snack-bar';
import { InstHintDialogComponent } from '~/shared/components/agenda-nav/inst-hint/inst-hint-dialog.component';
import { MatAlertDialogData } from '@apfr/components/core';
import { ColorModeEnum } from '~/shared/models/agenda/color-mode.enum';
import {
  BusyTime,
  CalendarService,
  FreeTime,
  FreeTimeResponse
} from '~/+calendar/shared/calendar.service';
import { GetFreeTime } from '~/store/free-time';
import { ESCAPE } from '@angular/cdk/keycodes';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBarDismiss } from '@angular/material/snack-bar/snack-bar-ref';
import { FreeTimeShortData } from '~/+calendar/shared/institution.service';
import { MouseDown } from '~/shared/components/agenda/agenda-event/agenda-event.component';
import { FreeTimeDirection, FreeTimeDirectionEnum } from '~/shared/models/agenda/free-time.model';
import { MatAlertDialogService } from '@apfr/components/core';
import { createCalendarRules } from '~/store/calendar-rule';
import { flow, groupBy, intersectionWith } from 'lodash/fp';
import { RRule } from 'rrule';

import { environment } from '../../../../environments/environment';

import {
  AgendaFreeTimeSlot, calculateHeight,
  calculateHowManyColumnsAvailable,
  calculateMaxDayAmount,
  calculateMaxHeight,
  calculateMinWidthOfDay,
  calculateNearestTime,
  calculateStartPosition,
  CheckWindowAndContentWidthParams, ColumnIndexesKeys, ColumnKeys, DAY_VIEW_DAYS, DRAG_DIFFERENCE_START_PX,
  getNewEventStartTime,
  groupedEventsByDay, isNotInPast,
  isNotInPastEvent,
  PasteDropEventParams,
  reorganizeGroupedBusyTime,
  reorganizeGroupedEventsDay,
  reorganizeGroupedEventsWeek, reorganizeGroupedFreeTime,
  timelineLabels,
  WEEK_VIEW_DAYS
} from './agenda.util';
import { getDataFromStore, updateRouterUrl } from './agenda-store.util';
import { uniq } from 'lodash';
import { CalendarRulePriority, CalendarRuleTypes } from '@apfr/components/calendar-rule-modal';
import { EventWebsocketService } from '~/framework/websocket/src/event-websocket.service';
// to have access to index in object
const flatMap = require('lodash/fp/flatMap')
  .convert({'cap': false});

interface FirstColumn {
  key: string;
  name: string;
}

interface SecondaryColumn extends FirstColumn {
  resource?: Resource;
}

export interface AgendaFormUrlParams {
  resource: string;
  day: number;
  minute: number;
  hour: number;
  year: number;
  month: number;
  dateTime?: string;
}

interface DragStartData extends ColumnIndexesKeys {
  startTime: Moment;
  x: number;
  y: number;
}

interface PriorityColumn extends ColumnKeys {
  priority: number;
}
const ALLOW_OVERBOOKING_PERMISSION = 'allow_overbooking';

@Component({
  selector: 'apfr-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly AgendaViewTypeEnum = AgendaViewTypeEnum;

  @ViewChild('scrollable')
  scrollableRef: ElementRef;
  @ViewChild('content')
  contentRef: ElementRef;
  @ViewChild('header')
  headerRef: ElementRef;
  @ViewChildren('calendarDateHeader')
  calendarDateHeaderRef: QueryList<ElementRef>;
  @ViewChildren('oneCol')
  oneColRef: QueryList<ElementRef>;
  @Input()
  isMobile: boolean;

  @Input()
  isNotDesktop: boolean;

  @Input()
  isShowFormPanel: boolean;

  @Input()
  selectedTime: any;
  @Input()
  isCalendarCmp: boolean;
  @Input()
  isEnabledSelection: boolean;
  filterHeight = 0;
  displayedDates: Moment[] = [];

  timeTable: string[];

  startAgenda: string;
  endAgenda: string;
  workingDays: string[];
  intervalAgenda: number;

  selectedResources: Resource[] = [];
  maxDayAmount: number;
  numberOfDays: number;
  minWidthOfDay: number;
  cellHeight: number;
  groupedEvents: any = [];
  groupedFreeTimes: any = [];
  groupedBusyTimes: any = [];

  mainColumns: FirstColumn[] = [];
  todayMainColumnKey: string;
  hoveredSecondColumn: string;
  hoveredFirstColumn: string;
  hoveredTime: string;
  redLineTimeout: NodeJS.Timeout | number;
  redLineTopPosition = 0;
  secondaryColumns: SecondaryColumn[] = [];
  isGroupedByResources: boolean;
  selectingCalendarRule = false;
  calendarRuleSelectionStyles: Object = {};
  dragStartData: DragStartData | undefined;
  startRuleTime: string;
  endRuleTime: string;
  startColumnIndex: number;
  endColumnIndex: number;
  draggingEvent: EventModel;
  tooltipMessage: string;
  isValidRuleStart = false;
  dndContainerStyles: Object;
  previousStateOfPastedEvent: EventModel;
  isGroupedByInst: boolean;
  selectedInstitutions: InstitutionModel[];
  selectedColorMode: ColorModeEnum = ColorModeEnum.Type;
  selectedColor: string;
  agendaParamsState = [];
  getDataFromStore = getDataFromStore;
  updateRouterUrl = updateRouterUrl;
  displayTimeLabel$: Observable<boolean>;
  copiedEvents: EventModel[];
  pastingEvent: EventModel;
  activeEvent: EventModel;
  activeSecondaryColumn: SecondaryColumn;
  hasOverbookingPermission = false;
  // this variable needed to avoid infinity loop.
  // because we load calendar rules from freetime endpoint.
  // but when we change something in these rules, it should automatically update freetime with rules
  loadingFreeTime = false;

  events: EventModel[] = [];
  private freeTime: FreeTime;
  private busyTime: BusyTime;
  private startDate: Moment;

  constructor(
    protected readonly cdRef: ChangeDetectorRef,
    protected readonly store: Store<EventState>,
    private readonly renderer: Renderer2,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    protected readonly sanitizer: DomSanitizer,
    protected readonly snackBar: MatSnackBar,
    protected readonly dialog: MatDialog,
    private readonly matAlertDialogService: MatAlertDialogService,
    protected readonly bottomSheet: MatBottomSheet,
    private readonly eventWebsocketService: EventWebsocketService,
    private readonly databaseService: DatabaseService,
  ) {
    super();
  }

  private _viewType: AgendaViewTypeEnum;

  get viewType(): AgendaViewTypeEnum {
    return this._viewType;
  }

  set viewType(value: AgendaViewTypeEnum) {
    this._viewType = value;
  }

  get columnWidth() {
    return (this.oneColRef.first.nativeElement as HTMLElement).getBoundingClientRect().width;
  }

  @HostListener('window:keyup', ['$event']) onKeyUpL(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE) {
      this.clearDndData();
    }
  }

  ngOnInit() {
    this.getColorMode();
    this.howManyColumnsAvailableChange();
    this.getDataFromStore();
    this.updateRouterUrl();
    if (this.isCalendarCmp) {
      this.databaseService.getUserData()
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((userData: UserExtraDataModel) => {
          this.hasOverbookingPermission = userData?.permissions?.includes(ALLOW_OVERBOOKING_PERMISSION);
        });
      this.eventWebsocketService.onEvents()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((message: WsEventModel) => {
          const {startDate, endDate, resourceIds} = this.getEventsContext();
          const action = message.action;
          const event = message.event;
          const startEventTime = moment.unix(event.startTime);

          if (startDate <= startEventTime && startEventTime <= endDate.endOf('day') && resourceIds.includes(event.resource.id)) {
            // in agenda range
            if (action === WsActionEnum.Removed) {
              this.store.dispatch(new DeleteEventFromWS({eventId: event.id}));
            } else {
              this.store.dispatch(new AddEventFromWS({updatedEvent: event}));
            }
            if (!this.hasOverbookingPermission) {
              // reload freetime if w/o overbooking
              this.loadFreeTime();
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cdRef.detach();
    clearInterval(this.redLineTimeout);
  }

  ngAfterViewInit(): void {
    this.checkResizeAgenda();
    this.setMarginRight();
    this.setMinWidthOfDay();
    this.setRedLineTopPosition();
    // timeout needed to avoid wrong agenda size because of navigation.
    setTimeout(() => {
      this.setMaxHeight();
    });
    this.redLineTimeout = setInterval(() => {
      // recalculate position every minute
      this.setRedLineTopPosition(true);
    }, 60000);
  }

  freeTimeShortDataChange(freeTimeShortData: FreeTimeShortData): void {
    this.startAgenda = freeTimeShortData.dayStart;
    this.endAgenda = freeTimeShortData.dayEnd;
    this.workingDays = freeTimeShortData.workingDays;
    this.intervalAgenda = freeTimeShortData.slotDuration ? freeTimeShortData.slotDuration : 15;
    this.timeTable = timelineLabels(this.startAgenda, this.endAgenda, this.intervalAgenda, 'minutes');
  }

  freeTimeChange(freeTimeResponse: FreeTimeResponse) {
    if (this.isCalendarCmp) {
      this.freeTime = freeTimeResponse.freeTimes;
    }
    this.busyTime = freeTimeResponse.busyTimes;
    this.groupFreeTimeAndBusyTime();

    this.cdRef.markForCheck();
  }

  eventsChange(events: EventModel[]) {
    this.events = events;
    this.onUpdatedPastedEvent();
    if (this.isWeekView()) {
      this.weekView();
    } else {
      this.groupEventsInViewDay();
    }
    this.cdRef.markForCheck();
  }

  isDraggableEvent(): boolean {
    return !this.isGroupedByResources && !this.isGroupedByInst && !this.isWeekView();
  }

  mouseDownEvent(mouseDown: MouseDown, secondaryColumn: SecondaryColumn) {
    this.activeEvent = mouseDown.event;
    this.activeSecondaryColumn = secondaryColumn;
  }

  selectEvent(event: EventModel, secondaryKey?: any) {
    const dateTime = moment(new Date(event.startTime * 1000), BASE_DATE_FORMAT);
    const time: Partial<AgendaFormUrlParams> = {
      day: +dateTime.format('D'),
      minute: +dateTime.format('m'),
      hour: +dateTime.format('H'),
      year: +dateTime.format('YYYY'),
      month: +dateTime.format('M')
    };
    const iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.baseEventInfo}?id=${event.id}`);
    const selectedTime = {
      dateTime,
      secondaryColumn: secondaryKey,
      time,
      selectedResource: this.isGroupedByResources ? event.resource.id : secondaryKey.key,
      selectedResourceName: this.isGroupedByResources ? event.resource.name : secondaryKey.name,
      selectedInstitution: this.isGroupedByResources ? secondaryKey.key : this.selectedInstitutions[0].id,
      selectedInstitutionName: this.isGroupedByResources ? secondaryKey.key : this.selectedInstitutions[0].name,
      isGroupedByResources: this.isGroupedByResources
    };

    this.store.dispatch(new AgendaSideInfo({
      type: AgendaSideInfoType.EventInfo,
      data: {
        iframeSrc,
        event,
        selectedTime
      }
    }));
  }

  isToday(date: Moment) {
    const now = moment();
    const nowStrDate = now.format(BASE_DATE_FORMAT);
    const startDateTime = moment(this.startAgenda + nowStrDate, BASE_DATE_TIME_FORMAT);
    const endDateTime = moment(this.endAgenda + nowStrDate, BASE_DATE_TIME_FORMAT)
      .add(this.intervalAgenda, 'minutes');

    return now
        .isSame(date, 'd')
      && startDateTime <= now && now <= endDateTime;
  }

  /**
   * selectTime
   *
   * Select Time and dispatch {@link [AgendaSideInfo]} action with necessary data
   *
   */
  selectTime(mouseEvent: MouseEvent, mainColumnKey: string, secondaryColumn: SecondaryColumn, time: string) {
    mouseEvent.stopPropagation();
    if (this.draggingEvent) {
      return;
    }
    if (this.pastingEvent) {
      this.pasteDropEvent({
        keys: {
          mainColumnKey,
          secondaryColumnKey: secondaryColumn.key
        },
        event: this.pastingEvent,
        time
      });

      return;
    }

    const dateTime = concatDateTime(moment(mainColumnKey, DATE_FORMAT), time);

    if (!isNotInPast(dateTime)) {
      this.openAlertConfirmDialog({message: 'past_time_not_available', type: 'alert'});
      return;
    }

    let objectParams: Partial<AgendaFormUrlParams> = {
      day: dateTime.date(),
      minute: dateTime.minutes(),
      hour: dateTime.hours(),
      year: dateTime.year(),
      month: dateTime.month() + 1
    };
    const timeData = {...objectParams};
    let firstResource: Resource;
    if (this.isGroupedByResources) {
      const resources = this.selectedResources.filter(cur => cur.parent.id === secondaryColumn.key);
      firstResource = resources[0];

      objectParams = {
        ...objectParams,
        resource: firstResource.id
      };
    } else {
      objectParams = {
        ...objectParams,
        resource: secondaryColumn.key
      };
    }
    const selectedTime = {
      dateTime,
      secondaryColumn: secondaryColumn,
      time: timeData,
      selectedResource: this.isGroupedByResources ? firstResource.id : secondaryColumn.key,
      selectedResourceName: this.isGroupedByResources ? firstResource.name : secondaryColumn.name,
      selectedInstitution: this.isGroupedByResources ? secondaryColumn.key : this.selectedInstitutions[0].id,
      selectedInstitutionName: this.isGroupedByResources ? secondaryColumn.name : this.selectedInstitutions[0].name,
      isGroupedByResources: this.isGroupedByResources
    };

    const iframeSrc = this.sanitizer
      .bypassSecurityTrustResourceUrl(`${environment.baseFormIframeUrl}?${objectToQueryParams(objectParams)}`);

    this.store.dispatch(new AgendaSideInfo({
      type: AgendaSideInfoType.EventForm,
      data: {
        iframeSrc,
        selectedTime
      }
    }));
  }

  /**
   * updateDate
   *
   * Update Display dates.
   */
  dateChange(date: Moment) {
    this.startDate = date;
  }

  selectedResourcesChange(selected: Resource[]) {
    this.selectedResources = [...selected];
    if (this.isGroupedByResources) {
      this.groupedRes(true);
    } else {
      this.secondaryColumns = this.setSecondaryColumn();

      if (selected.length === 0) {
        this.store.dispatch(
          new ShowNotification({style: 'warn', message: 'please_select_resource'})
        );

        return;
      }
      this.setMinWidthOfDay();
    }

  }

  numberOfDaysChange(numberOfDays: number) {
    this.numberOfDays = numberOfDays;
    this.setMinWidthOfDay();
  }

  weekView() {
    const startStringDate = this.getFirstDisplayedDay();
    const startDate = moment(startStringDate, DATE_FORMAT);

    const endStringDate = this.getLastDisplayedDay();
    const endDate = moment(endStringDate, DATE_FORMAT);

    const key = `${startDate.format(BASE_DATE_FORMAT)}-${endDate.format(BASE_DATE_FORMAT)}`;

    this.mainColumns = [{
      key: key,
      name: key
    }];

    this.groupedEvents = {};

    this.groupedEvents[key] = reorganizeGroupedEventsWeek(this.events);
    this.setMaxHeight();
    this.setMarginRight();
    this.setRedLineTopPosition();
  }

  dayView() {
    if (this.displayedDates.length) {
      this.setMainColumn();
    }
    this.howManyColumnsAvailableChange();
    this.setMaxDayAmount();
    this.setMaxHeight();
    this.setMarginRight();
    this.setRedLineTopPosition();

    this.groupEventsInViewDay();

  }

  groupedRes(isGroupedRes: boolean) {
    this.isGroupedByResources = isGroupedRes;

    this.secondaryColumns = isGroupedRes ?
      this.setSecondaryColumn(false, this.selectedInstitutions) :
      this.secondaryColumns = this.setSecondaryColumn();
    this.groupEvents();
    this.groupFreeTimeAndBusyTime();
    this.setMinWidthOfDay();

    this.cdRef.markForCheck();
  }

  groupedInst(isGroupedIns: boolean) {
    this.isGroupedByInst = isGroupedIns;
    this.groupEventsInViewDay();

    this.setMinWidthOfDay();

    this.cdRef.markForCheck();
  }

  nextDay() {
    this.updateSelectedDate(this.getNextOrPreviousStartDate(FreeTimeDirectionEnum.NEXT));
  }

  prevDay() {
    this.updateSelectedDate(this.getNextOrPreviousStartDate(FreeTimeDirectionEnum.PREVIOUS));
  }

  private getNextOrPreviousStartDate(direction: FreeTimeDirection = null): Moment {
    if (!this.workingDays.length) {
      return;
    }

    const duration = this.isWeekView() ? WEEK_VIEW_DAYS : DAY_VIEW_DAYS;
    const isPrevious = direction === FreeTimeDirectionEnum.PREVIOUS;
    let startDate = this.getFirstDisplayedDay()
      .clone();
    let foundDays = 0;
    while (duration !== foundDays) {
      startDate = startDate.add((isPrevious ? -DAY_VIEW_DAYS : DAY_VIEW_DAYS), 'days');
      const day = startDate.format('dd')
        .toUpperCase();
      if (this.workingDays.includes(day)) {
        foundDays++;
      }
    }

    return startDate;
  }

  private isWeekView(): boolean {
    return this.viewType === AgendaViewTypeEnum.WeekView;
  }

  onUpdatedPastedEvent() {
    if (!this.previousStateOfPastedEvent) {
      return;
    }
    const snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 5_000,
      data: {
        message: 'event_paste_success',
        action: 'undo',
        close_action: true
      }
    });

    snackBarRef.onAction()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.store.dispatch(
          new UndoPaste({
              updateData: {
                id: this.previousStateOfPastedEvent.id,
                changes: {
                  startTime: this.previousStateOfPastedEvent.startTime,
                  endTime: this.previousStateOfPastedEvent.endTime,
                  resource: this.previousStateOfPastedEvent.resource
                }
              }
            }
          )
        );

      });

    snackBarRef.afterDismissed()
      .subscribe((dismiss: MatSnackBarDismiss) => {
        // clear to avoid circular reference
        this.previousStateOfPastedEvent = undefined;

        if (!dismiss.dismissedByAction) {
          this.loadData();
        }
      });

  }

  private groupFreeTimeAndBusyTime() {
    if (this.isCalendarCmp) {
      this.groupedFreeTimes = reorganizeGroupedFreeTime(this.freeTime, this.isGroupedByResources);
    }
    this.groupedBusyTimes = reorganizeGroupedBusyTime(this.busyTime, this.isGroupedByResources);
  }

  private groupEvents() {
    this.groupedEvents = reorganizeGroupedEventsDay(this.events, this.isGroupedByResources);
  }

  protected checkWindowAndContentWidth({windowWidth, contentWidth, columnCount}: CheckWindowAndContentWidthParams) {
    if (windowWidth < contentWidth) {
      if (this.mainColumns.length === 1) {
        const v = this.secondaryColumns.length - columnCount;

        this.store.dispatch(new SelectedInstResChange({
          selectedInstitutions: this.isGroupedByResources ?
            this.selectedInstitutions.slice(0, this.secondaryColumns.length - v) :
            this.selectedInstitutions,
          selectedResources: this.isGroupedByResources ?
            this.selectedResources :
            this.selectedResources.slice(0, this.secondaryColumns.length - v)
        }));

        setTimeout(() => {
          const snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 5_000,
            data: {
              message: this.isGroupedByResources ? 'some_institutions_hidden' : 'some_resources_hidden',
              action: this.isGroupedByResources ? 'change_institutions' : 'change_resources'
            }
          });

          snackBarRef
            .onAction()
            .subscribe(() => {
              this.openInstHintDialog();
            });
        });

        this.cdRef.detectChanges();
      }

      if (this.selectedTime) {
        // TODO: not sure that we still need that
        this.updateSelectedDate(getMomentDateTimeFromBaseFormat(this.selectedTime.dateTime));
      }
    }
  }

  private setRedLineTopPosition(markForCheck: boolean = false) {
    if (this.mainColumns.length && this.displayedDates.length) {
      this.todayMainColumnKey = undefined;
      for (let i = 0; i < this.displayedDates.length; i++) {

        const date = this.displayedDates[i];
        if (this.isToday(date)) {
          this.todayMainColumnKey = this.viewType === AgendaViewTypeEnum.DayView ? date.format(DATE_FORMAT) : this.mainColumns[0].key;
          break;
        }
      }
    }

    if (this.startAgenda && this.intervalAgenda) {
      this.redLineTopPosition = calculateStartPosition(
        moment()
          .unix(),
        this.startAgenda,
        this.intervalAgenda,
        this.cellHeight
      );
      if (markForCheck) {
        this.cdRef.markForCheck();
      }
    }
  }

  private loadEvents() {
    const {startDate, endDate, resourceIds} = this.getEventsContext();

    this.store.dispatch(new GetAgenda({startDate: startDate.format(DATE_FORMAT), endDate: endDate.format(DATE_FORMAT), resourceIds}));
  }

  private getEventsContext(): {startDate: Moment, endDate: Moment, resourceIds: string[]} {
    const resourceIds = this.selectedResources.map(cur => cur.id);
    const startDate = this.getFirstDisplayedDay();
    const endDate = this.getLastDisplayedDay();
    return {startDate, endDate, resourceIds};
  }

  loadFreeTime() {
    const resourceIds = this.selectedResources.map(cur => cur.id);
    this.loadingFreeTime = true;
    this.store.dispatch(new GetFreeTime({
      startDate: this.getFirstDisplayedDay()
        .format(DATE_FORMAT),
      duration: this.isWeekView() ? WEEK_VIEW_DAYS : this.numberOfDays,
      entityIds: resourceIds,
      direction: null
    }));
    this.cdRef.markForCheck();
  }

  private loadData(): void {
    if (this.isCalendarCmp) {
      this.loadEvents();
    }
    this.loadFreeTime();
  }

  /**
   * Detect .agenda-calendar width use {@link [ResizeObserver]} {@link fromResize}
   */
  private checkResizeAgenda(): void {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(event => {
        this.howManyColumnsAvailableChange();
        this.setMaxDayAmount();
        this.setMaxHeight();
        this.setMarginRight();
      });
  }

  private openInstHintDialog() {
    let dialogConfig: MatDialogConfig = {
      panelClass: ['full-size-dialog', 'inst-hint-modal']
    };
    if (this.isMobile) {
      dialogConfig = {
        ...dialogConfig,
        width: '100vw',
        maxWidth: '100%',
        height: '100vh'
      };
    } else {
      dialogConfig = {
        ...dialogConfig,
        width: '768px'
      };
    }
    this.dialog.open(InstHintDialogComponent, dialogConfig);
  }

  /**
   * setMarginRight
   *
   * Setting margin-right to element based on {@link getScrollbarWidth}
   */
  private setMarginRight() {
    if (this.scrollableRef) {
      const el = this.scrollableRef.nativeElement;

      this.renderer.setStyle(
        this.headerRef.nativeElement,
        'margin-right',
        `${getScrollbarWidth(el)}px`
      );
    }
  }

  /**
   * Setting max-height to scrollableRef use {@link calculateMaxHeight}
   */
  private setMaxHeight(): void {
    this.cdRef.detectChanges();

    if (this.scrollableRef && (!this.isCalendarCmp || this.filterHeight)) {
      const maxHeight = calculateMaxHeight(
        this.scrollableRef.nativeElement,
        this.filterHeight
      );

      if (maxHeight) {
        this.renderer.setStyle(
          this.scrollableRef.nativeElement,
          'max-height',
          `${maxHeight}px`
        );
      }
    }
  }

  filterHeightChanged(height: number) {
    this.filterHeight = height;
    this.setMaxHeight();
  }

  /**
   * Setting min-width of day column use {@link calculateMinWidthOfDay}
   */
  private setMinWidthOfDay() {
    const secondaryColumnsCount = this.isGroupedByInst ? 1 : this.secondaryColumns.length;
    this.minWidthOfDay = calculateMinWidthOfDay(secondaryColumnsCount);

    this.setMaxDayAmount();

    if (this.calendarDateHeaderRef) {
      this.calendarDateHeaderRef.toArray()
        .forEach((el) => {
          this.renderer.setStyle(
            el.nativeElement,
            'min-width',
            `${this.minWidthOfDay}px`
          );
        });
    }
  }

  /**
   * setMaxDayAmount
   *
   * Calculate how many days with how many resources we can fit on the screen
   * Called after {@link [checkResizeAgenda]} {@link [setMinWidthOfDay]}
   */
  private setMaxDayAmount() {
    const maxDayAmountCache = this.maxDayAmount;

    this.maxDayAmount = calculateMaxDayAmount({
      minWidthOfDay: this.minWidthOfDay,
      isShowFormPanel: this.isShowFormPanel,
      isMobile: this.isMobile
    });

    if (maxDayAmountCache !== this.maxDayAmount) {
      this.store.dispatch(new MaxDayAmountChange({maxDayAmount: this.maxDayAmount}));
    }
    if (this.numberOfDays > this.maxDayAmount) {
      this.store.dispatch(new NumberOfDaysChange(this.maxDayAmount));

      setTimeout(() => {
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 5_000,
          data: {
            message: 'some_days_hidden',
            action: 'ok'
          }
        });
      });

    }
  }

  private groupEventsInViewDay(): void {
    if (this.isGroupedByInst) {
      this.groupedEvents = groupedEventsByDay(this.events);
    } else {
      this.groupEvents();
    }
    this.setMaxHeight();
    this.setMarginRight();
  }

  updateCalendar(): void {
    this.updateDisplayedDates();
    if (this.isWeekView()) {
      this.weekView();
    } else {
      this.dayView();
    }
    this.cdRef.markForCheck();

    this.loadData();
  }

  private updateDisplayedDates() {
    if (this.workingDays.length === 0) {
      return;
    }
    const duration = this.isWeekView() ? WEEK_VIEW_DAYS : this.numberOfDays;
    const displayedDates = [];

    let startDate = this.startDate.clone();
    let foundDays = 0;
    while (duration !== foundDays) {
      const day = startDate.format('dd')
        .toUpperCase();

      if (this.workingDays.includes(day)) {
        displayedDates.push(startDate.clone());
        foundDays++;
      }
      startDate = startDate.add(DAY_VIEW_DAYS, 'days');
    }

    this.displayedDates = displayedDates;
  }

  private setMainColumn(): void {
    this.mainColumns = this.displayedDates.map(date => ({
      key: date.format(DATE_FORMAT),
      name: date.format('dddd DD MMM')
    }));
  }

  private setSecondaryColumn(
    isResources = true,
    columns: { id: string, name: string }[] = this.selectedResources
  ): SecondaryColumn[] {
    return columns.map(item => ({
      key: item.id,
      name: item.name,
      resource: isResources ? item as Resource : undefined
    }));
  }

  private howManyColumnsAvailableChange() {
    this.store.dispatch(new HowManyColumnsAvailable(
      {
        columnsCount: calculateHowManyColumnsAvailable()
      })
    );
  }

  private updateSelectedDate(date: Moment) {
    this.store.dispatch(new DateChange({date}));
  }

  private getFirstDisplayedDay(): Moment {
    return this.displayedDates[0];
  }

  private getLastDisplayedDay(): Moment {
    return this.displayedDates[this.displayedDates.length - 1];
  }

  mousemove(ev: MouseEvent) {
    const hoveredTime = this.getHoveredTime(ev.y);

    const columnKeys = this.getColumnsKeys(ev.x);
    this.hoveredTime = hoveredTime.format(BASE_TIME_FORMAT);
    this.hoveredSecondColumn = columnKeys.secondaryColumnKey;
    this.hoveredFirstColumn = columnKeys.mainColumnKey;

    const ruleDragging = this.displayDraggingRuleContainer(columnKeys, hoveredTime, ev);
    const eventDragging = this.displayDraggingEventContainer(columnKeys);
    if (!ruleDragging && !eventDragging) {
      this.clearDndData();
    }
  }

  mousedown(event: MouseEvent) {
    const startTime = this.getHoveredTime(event.y);
    const startColumnKeys = this.getColumnsKeys(event.x);
    this.dragStartData = {...startColumnKeys, startTime, x: event.x, y: event.y};
  }

  mouseup(ev: MouseEvent) {
    if (this.draggingEvent) {
      // drop
      this.pasteDropEvent({
        keys: this.getColumnsKeys(ev.x),
        event: this.draggingEvent,
        time: this.getHoveredTime(ev.y)
          .format(BASE_TIME_FORMAT)
      });
    } else if (this.activeEvent && this.activeSecondaryColumn) {
      // click
      this.selectEvent(this.activeEvent, this.activeSecondaryColumn);
    }

    this.createRules();
    this.clearDndData();
  }

  private clearDndData() {
    this.draggingEvent = undefined;
    this.activeEvent = undefined;
    this.activeSecondaryColumn = undefined;
    if (this.pastingEvent) {
      this.store.dispatch(new PastedEvent({eventId: null}));
      this.pastingEvent = undefined;
    }

    this.tooltipMessage = undefined;
  }

  private getDraggingEvent(): EventModel | undefined {
    return this.pastingEvent || this.activeEvent;
  }

  private displayDraggingEventContainer(currentColumnIndexesKeys: ColumnIndexesKeys): boolean {
    const draggingEvent = this.getDraggingEvent();
    if (this.isCalendarCmp && draggingEvent && this.isDraggableEvent() && isNotInPastEvent(draggingEvent)) {
      const {secondaryColumnKey, mainColumnKey, index} = currentColumnIndexesKeys;

      // dragging event
      this.draggingEvent = draggingEvent;
      const width = this.columnWidth;
      const startTime = getNewEventStartTime(
        mainColumnKey,
        this.hoveredTime,
        this.draggingEvent.startTime,
        this.intervalAgenda,
        this.startAgenda
      );

      this.dndContainerStyles = {
        top: `${calculateStartPosition(startTime.unix(), this.startAgenda, this.intervalAgenda, this.cellHeight)}px`,
        height: `${calculateHeight(this.draggingEvent.startTime, this.draggingEvent.endTime, this.intervalAgenda, this.cellHeight)}px`,
        width: `${width}px`,
        left: `${width * index}px`
      };

      const result = this.validateNewEventPosition({
        keys: {
          mainColumnKey,
          secondaryColumnKey
        },
        event: draggingEvent,
        time: this.hoveredTime
      });

      this.tooltipMessage = result?.message ?? undefined;
      return true;
    }
    return false;
  }

  private displayDraggingRuleContainer(
    currentColumnKeys: ColumnIndexesKeys,
    currentHoveredTime: Moment,
    currentMouseEvent: MouseEvent
  ): boolean {
    this.selectingCalendarRule = this.isEnabledSelection && this.dragStartData && !this.getDraggingEvent()
      // if we have difference > DRAG_DIFFERENCE_START_PX then it means user dragging. otherwise, user clicks
      // just to avoid problem with clicks, because humans can't always click perfectly without moving
      && (
        Math.abs(this.dragStartData.x - currentMouseEvent.x) > DRAG_DIFFERENCE_START_PX ||
        Math.abs(this.dragStartData.y - currentMouseEvent.y) > DRAG_DIFFERENCE_START_PX
      );

    if (this.selectingCalendarRule) {
      const currentColumnIndex = currentColumnKeys.index;
      const startTime = this.dragStartData.startTime;

      const endTime = currentHoveredTime.clone();
      let realStartTime: Moment, realEndTime: Moment;
      if (endTime >= startTime) {
        endTime.add(this.intervalAgenda, 'minutes');
        realStartTime = startTime;
        realEndTime = endTime;
      } else {
        realStartTime = endTime;
        realEndTime = startTime;
      }

      this.startRuleTime = realStartTime.format(BASE_TIME_FORMAT);
      this.endRuleTime = realEndTime.format(BASE_TIME_FORMAT);

      const startIndex = this.dragStartData.index;
      let realStartIndex,
        realEndIndex;
      if (startIndex < currentColumnIndex) {
        realStartIndex = startIndex;
        realEndIndex = currentColumnIndex;
      } else {
        realStartIndex = currentColumnIndex;
        realEndIndex = startIndex;
      }

      this.startColumnIndex = realStartIndex;
      this.endColumnIndex = realEndIndex;

      const columnSelection = (realEndIndex - realStartIndex) + 1;
      const columnWidth = this.columnWidth;
      const width = columnWidth * columnSelection;
      const startUnix = realStartTime.unix();
      this.calendarRuleSelectionStyles = {
        top: `${calculateStartPosition(startUnix, this.startAgenda, this.intervalAgenda, this.cellHeight)}px`,
        height: `${calculateHeight(
          startUnix,
          realEndTime.unix(),
          this.intervalAgenda,
          this.cellHeight
        )}px`,
        width: `${width}px`,
        left: `${columnWidth * realStartIndex}px`
      };
      const firstSelectedStrDate = this.oneColRef.toArray()[realStartIndex].nativeElement
        .getAttribute('maincolumnkey');
      const firstSelectedDate = this.isWeekView() ?
        moment(firstSelectedStrDate.split('-')[0], BASE_DATE_FORMAT) :
        moment(firstSelectedStrDate);
      this.isValidRuleStart = firstSelectedDate >= moment()
        .startOf('week');
      this.tooltipMessage = this.isValidRuleStart ? undefined : 'rule_in_past';

      return true;
    }
    return false;
  }

  private createRules(): void {
    if (this.selectingCalendarRule && this.isValidRuleStart) {
      // calendar rule selection finished, create rules
      const selectedColumns: ColumnKeys[] = this.oneColRef.toArray()
        .slice(this.startColumnIndex, this.endColumnIndex + 1)
        .map((column: ElementRef) => {
          const columnElem = column.nativeElement as HTMLElement;
          // main - date/range dates(from-to, if week view)
          const mainColumnKey = columnElem.getAttribute('maincolumnkey');
          // secondary - resource/institution(grouped by resources) / null(if grouped by inst || weekView)
          const secondaryColumnKey = columnElem.getAttribute('secondarycolumnkey');
          return {
            mainColumnKey,
            secondaryColumnKey
          };
        });

      const isWeekView = this.isWeekView();
      const includesAllInst = isWeekView || this.isGroupedByInst || this.isGroupedByResources;

      const calendarRuleData: CalendarRuleDataModel[] = flow(
        // group by day, range of days if week view
        groupBy((column: ColumnKeys) => column.mainColumnKey),
        // modify to one flattened array instead of multi 2d array
        flatMap((groupedColumns: ColumnKeys[], mainColumnKey) => {
          // modify to one flattened array instead of multi 2d array
          return flatMap((institution: InstitutionModel) => {
            const intersection = intersectionWith((groupedColumn: ColumnKeys, resource: Resource) => {
              return resource.id === groupedColumn.secondaryColumnKey;
            })(groupedColumns, institution.children);
            // checking if everything belongs to all institutions, then use them.
            // otherwise, trying to find full match of selected resource in existing resources in one day(or range of days)
            // just to simplify creation of rules.
            // because it doesn't make sense to create rule for each resource if we can create 1 rule for 1 institution
            // also adding priority at this step
            return includesAllInst || intersection.length === institution.children.length ? [{
              mainColumnKey,
              secondaryColumnKey: institution.id,
              priority: CalendarRulePriority.PRIORITY_INSTITUTION
            }] : intersection.map((col) => {
              return {
                ...col,
                priority: CalendarRulePriority.PRIORITY_RESOURCE
              };
            });
          })(this.selectedInstitutions);
        }),
        // group by entity id(resource, institution)
        groupBy((column: PriorityColumn) => column.secondaryColumnKey),
        // modify to one flattened array instead of multi 2d array
        flatMap((columns: PriorityColumn[], entityId) => {
          let priority: number;
          // collecting selected dates
          const dates: Moment[] = isWeekView ? this.displayedDates : [];
          columns.map((column: PriorityColumn) => {
            priority = column.priority;
            if (!isWeekView) {
              // take one day
              dates.push(moment(column.mainColumnKey, DATE_FORMAT));
            }
          });

          return {
            // transform to week days
            // uniq to avoid selection the same week days. for example from monday to next monday(includes)
            weekDays: uniq(dates.map((date: Moment) => date.format('dd')
              .toUpperCase()
            )),
            entityId,
            startDate: dates[0],
            priority
          };
        })
      )(selectedColumns) as CalendarRuleDataModel[];

      this.store.dispatch(createCalendarRules({
        calendarRules: calendarRuleData.map((data: CalendarRuleDataModel) => {
          const startDate = data.startDate;
          const startDateStr = startDate.format(DATE_FORMAT);
          const startDateShortStr = startDate.format(SHORT_DATE_FORMAT);
          const ruleData = 'DTSTART:' + startDateShortStr +  '\n' + 'RRULE:FREQ=WEEKLY;BYDAY=' + data.weekDays.join(',');
          return {
            priority: data.priority,
            type: CalendarRuleTypes.Closing,
            start_date_time: startDateStr +  'T' + this.startRuleTime,
            end_date_time:  startDateStr +  'T' + this.endRuleTime,
            entity: data.entityId,
            first_criterion: null,
            // TODO: should come somewhere from user config, instead of hardcode
            rule_data: ruleData,
            color: '#cccccc',
            name: RRule.fromString(ruleData)
              .toText()
          };
        })
      }));
    }

    this.dragStartData = undefined;
    this.selectingCalendarRule = false;
    this.isValidRuleStart = false;
  }

  private getColumnsKeys(xCoordinate: number): ColumnIndexesKeys {
    const pastedIndex = (xCoordinate - this.contentRef.nativeElement.offsetLeft + 1) / this.columnWidth;
    const colIndex = Math.floor(pastedIndex);
    const arrayCols = this.oneColRef.toArray();
    const maxColIndex = arrayCols.length - 1;
    const index = colIndex > maxColIndex ? maxColIndex : colIndex;
    const pastedCol = arrayCols[index].nativeElement as HTMLElement;

    const secondaryColumnKey = pastedCol.getAttribute('secondarycolumnkey');
    const mainColumnKey = pastedCol.getAttribute('maincolumnkey');

    return {
      secondaryColumnKey,
      mainColumnKey,
      index,
      maxColIndex
    };
  }

  private getHoveredTime(yCoordinate: number): Moment {
    const scrollable = this.scrollableRef.nativeElement as HTMLElement;
    const offset = Math.floor(
      (scrollable.scrollTop + yCoordinate - scrollable.getBoundingClientRect().top)
      / this.cellHeight
    ) * this.intervalAgenda;

    const time = concatDateTime(
      moment(),
      this.startAgenda
    );
    time.add(offset, 'minutes');
    return time;
  }

  /**
   * pasteDropEvent
   *
   * Base function which update event with new data after drop or paste.
   * Calculate new time and resource based on position of pasted or droped.
   */
  private pasteDropEvent(dropParams: PasteDropEventParams) {
    const newResourceId = dropParams.keys.secondaryColumnKey;
    const timePosition = this.validateNewEventPosition(dropParams);
    const message = timePosition?.message;
    if (message) {
      this.openAlertConfirmDialog({message, type: 'alert'});
      return;
    }
    const event = dropParams.event;
    const {startTime, endTime} = timePosition;
    const startTimeUnix = startTime.unix();
    const endTimeUnix = endTime.unix();

    if (startTimeUnix === event.startTime && endTimeUnix === event.endTime && newResourceId === event.resource.id) {
      // the same position of event, then don't do anything
      return;
    }
    const resource = this.selectedResources.find(res => res.id === newResourceId);

    this.openAlertConfirmDialog({message: 'are_you_sure', type: 'confirm'})
      .subscribe(() => {
        const updatedEventData: Update<EventModel> = {
          id: event.id,
          changes: {
            startTime: startTimeUnix,
            endTime: endTimeUnix,
            resource
          }
        };
        this.previousStateOfPastedEvent = event;
        this.store.dispatch(new PasteEvent({updateData: updatedEventData}));
      });
  }

  private validateNewEventPosition(
    dropParams: PasteDropEventParams
    ): {startTime?: Moment, endTime?: Moment, message?: string} {
    const newDate = dropParams.keys.mainColumnKey;
    const event = dropParams.event;
    const startTime = getNewEventStartTime(
      newDate,
      dropParams.time,
      event.startTime,
      this.intervalAgenda,
      this.startAgenda
    );

    if (!isNotInPast(startTime)) {

      return {
        message: 'move_past_time'
      };
    }

    const newResourceId = dropParams.keys.secondaryColumnKey;
    const dayFreeTime = this.groupedFreeTimes[newDate];
    // clone array
    const columnFreeTime: AgendaFreeTimeSlot[] = dayFreeTime ? [...dayFreeTime[newResourceId]] : [];

    if (!this.hasOverbookingPermission) {
      const oldDate = moment.unix(event.startTime)
        .format(DATE_FORMAT);
      const oldResource = event.resource.id;

      if (oldDate === newDate && oldResource === newResourceId) {
        // add old event position to column freetime if user dragging event in the same column as it was
        // just to make sure he could drop a little below or above event with intersection of old position
        const oldTimeRange = timelineLabels(calculateNearestTime(moment.unix(event.startTime), this.intervalAgenda),
          calculateNearestTime(moment.unix(event.endTime), this.intervalAgenda, true),
          this.intervalAgenda, 'minutes');
        oldTimeRange.map((time: string) => {
          columnFreeTime.push({
            startTime: time,
            resources: [+newResourceId],
            promotion: false,
            institutions: [+event.resource.parent.id]
          });
        });
      }
    }

    const endTime = startTime.clone()
      .add(
        event.duration,
        'minutes'
      );

    const newTimeRange = timelineLabels(calculateNearestTime(startTime, this.intervalAgenda),
      calculateNearestTime(endTime, this.intervalAgenda, true),
      this.intervalAgenda, 'minutes');

    const notEnoughTime = newTimeRange.some((columnTime: string) =>
      !columnFreeTime.find((slot: AgendaFreeTimeSlot) => slot.startTime === columnTime));

    if (notEnoughTime) {
      return {
        message: 'not_enough_time'
      };
    }

    return {
      startTime,
      endTime
    };
  }

  private getColorMode(): void {
    if (!this.activatedRoute.queryParams) {
      return;
    }
    this.activatedRoute.queryParams
      .pipe(
        filter(params => params && params['colormode']),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.selectedColorMode = params['colormode'];
        this.cdRef.markForCheck();
      });
  }

  private openAlertConfirmDialog({message, type}: MatAlertDialogData): Observable<any> {
    return this.matAlertDialogService.openDialog({message, type});
  }
}
