export const environment = {
  production: true,
  token: 'auth_token',
  apiUrl: '//api.preprod.auto-planning.fr/api-platform',
  baseIframeUrl: '//preprod.auto-planning.fr/agenda',
  baseFormIframeUrl: '//preprod.auto-planning.fr/agenda/internal_new_event',
  baseEventInfo: '//preprod.auto-planning.fr/agenda/view_event',
  baseUrl: '//api.preprod.auto-planning.fr',
  targetOrigin: 'http://preprod.auto-planning.fr',
  gmoUrl: '//gateway.preprod.getmyopinion.fr',
  partnerToken: '9cfe3ffad13affb7f9e890619c9a181f',
  surveyId: 538792,
  ws: 'ws://u1604-103.auto-planning.fr:8080',
  wsSessionId: 'WS-SESSION-ID'
};
