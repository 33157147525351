import { createAction, props } from '@ngrx/store';
import { ICalendarRule, ICalendarRuleBody } from '@apfr/components/calendar-rule-modal';

export const getCalendarRule = createAction('[Calendar Rule] Get Calendar Rule', props<{ page: number, items: number, filters: object }>());
export const getCalendarRuleFail = createAction('[Calendar Rule] Get Calendar Rule Fail', props<{ error: any }>());
export const getCalendarRuleSuccess = createAction('[Calendar Rule] Get Calendar Rule Success', props<{ calendarRules: ICalendarRule[] }>());
export const getCalendarRuleById = createAction('[Calendar Rule] Get Calendar Rule By Id', props<{ id: number }>());
export const getCalendarRuleByIdFail = createAction('[Calendar Rule] Get Calendar Rule By Id Fail', props<{ error: any }>());
export const getCalendarRuleByIdSuccess = createAction('[Calendar Rule] Get Calendar Rule By Id Success', props<{ selectedCalendarRule: ICalendarRule }>());
export const createCalendarRule = createAction('[Calendar Rule] Create Calendar Rule', props<{ body: ICalendarRuleBody }>());
export const createCalendarRuleFail = createAction('[Calendar Rule] Create Calendar Rule Fail', props<{ error: any }>());
export const createCalendarRuleSuccess = createAction('[Calendar Rule] Create Calendar Rule Success', props<{ selectedCalendarRule: ICalendarRule }>());
export const createCalendarRules = createAction('[Calendar Rule] Create Calendar Rules', props<{ calendarRules: ICalendarRuleBody[] }>());
export const createCalendarRulesFail = createAction('[Calendar Rule] Create Calendar Rules Fail', props<{ error: any }>());
export const createCalendarRulesSuccess = createAction('[Calendar Rule] Create Calendar Rules Success', props<{ calendarRules: ICalendarRule[] }>());
export const updateCalendarRule = createAction('[Calendar Rule] Update Calendar Rule', props<{ id: number, body: ICalendarRuleBody }>());
export const updateCalendarRuleFail = createAction('[Calendar Rule] Update Calendar Rule Fail', props<{ error: any }>());
export const updateCalendarRuleSuccess = createAction('[Calendar Rule] Update Calendar RuleSuccess', props<{ selectedCalendarRule: ICalendarRule }>());
export const deleteCalendarRule = createAction('[Calendar Rule] Delete Calendar Rule', props<{ id: number }>());
export const deleteCalendarRuleFail = createAction('[Calendar Rule] Delete Calendar Rule Fail', props<{ error: any }>());
export const deleteCalendarRuleSuccess = createAction('[Calendar Rule] Delete Calendar Rule Success', props<{ id: number }>());
