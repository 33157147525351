import {Location} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {TranslateLoader} from '@ngx-translate/core';
import {MaterialModule, SharedModule} from '~/framework';
import {I18NModule, translateFactory} from '~/framework/i18n/i18n.module';
import {HttpInterceptorModule} from '~/framework/http/http-interceptor.module';
import {StoreModule} from '~/store';
import * as Hammer from 'hammerjs';
import {NotificationWrapperModule} from '~/shared/components';
import {AgendaNavModule} from '~/shared/components/agenda-nav/agenda-nav.module';
import {AuthGuards} from '~/shared/guards';
import {HeaderComponent, SidenavMenuComponent} from '~/layout';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatPaginatorI18nService} from '~/shared/services/paginator-i18n/paginator-i18n.service';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {APFR_BASE_URL, GMO_BASE_URL} from '@apfr/components/core';
import {Injectable, NgModule} from '@angular/core';

import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ApfrUrlModule} from "@apfr/components/urls";
import { WebsocketModule } from '~/framework/websocket/websocket.module';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': {enable: false},
    'rotate': {enable: false}
  };
  options = {
    touchAction: 'auto',
    inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
  };
}

export const MY_DATE_PICKER_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SidenavMenuComponent,
    HeaderComponent
  ],
  imports: [
    ApfrUrlModule.forRoot({baseUrl: environment.baseUrl, gmoUrl: environment.gmoUrl}),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    MatMomentDateModule,
    HttpInterceptorModule,
    I18NModule.forRoot([
      {
        provide: TranslateLoader,
        useFactory: translateFactory,
        deps: [HttpClient, Location]
      }
    ]),
    StoreModule.forRoot(),
    AppRoutingModule,
    NotificationWrapperModule,
    AgendaNavModule,
    WebsocketModule.config({
      url: environment.ws
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APFR_BASE_URL,
      useValue: environment.baseUrl
    },
    {
      provide: GMO_BASE_URL,
      useValue: environment.gmoUrl
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    // material datepicker shouldn't use utc, because all project is using Europe\Paris tz. anyway project should use one tz
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_PICKER_FORMATS},
    AuthGuards
  ]
})
export class AppModule {
}
