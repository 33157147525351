import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CalendarRuleState, CALENDAR_RULE } from '~/store/calendar-rule/calendar-rule.state';

export const CalendarRuleFeatureSelector = createFeatureSelector<CalendarRuleState>(CALENDAR_RULE);

export const calendarRuleDataSelector = createSelector(
  CalendarRuleFeatureSelector,
  (state: CalendarRuleState) => state.calendarRules
);

export const selectedCalendarRuleDataSelector = createSelector(
  CalendarRuleFeatureSelector,
  (state: CalendarRuleState) => state.selectedCalendarRule
);
