import { Component, Input } from '@angular/core';
import { Event as EventModel } from '~/shared/models';
import { EventStatuses } from '~/store/calendar';
import { BREAKPOINT_NUMBER_OF_DAYS } from '~/shared/components/agenda/agenda.util';

@Component({
  template: ``
})
export abstract class BaseEventComponent {
  @Input() event: EventModel;
  @Input() numberOfDays: number;
  breakpointNumberOfDays = BREAKPOINT_NUMBER_OF_DAYS;
  eventStatuses = EventStatuses;
}
