<mat-card class="customer-list">
  <div class="card-header">
    <div class="title">
      <p>{{ title | translate }} ({{ data.length }})</p>
    </div>
    <ng-template [ngIf]="actions">
      <div class="actions">
        <ng-template [cdkPortalOutlet]="actions"></ng-template>
      </div>
    </ng-template>
  </div>
  <mat-card-content>
    <form [formGroup]="form">
      <mat-form-field floatLabel="never" class="full-width" >
        <input matInput [placeholder]="'search' | translate" formControlName="filter">
      </mat-form-field>
    </form>
    <mat-list>
      <ng-container *ngFor="let item of data | filter: filterText : 'username'; trackBy: trackList">
        <mat-list-item (click)="select(item)" [class.selected]="item.id === selectItem">
          {{ item.username }}
        </mat-list-item>
      </ng-container>
    </mat-list>
  </mat-card-content>
</mat-card>
