import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '~/framework';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EventListModalComponent } from '~/shared/components/event-list/event-list-modal/event-list-modal.component';
import { EventListService } from '@apfr/components/events';

@Component({
  selector: 'apfr-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent extends BaseComponent implements OnInit {

  @Input() isAgendaPage: boolean = false;

  isCopiedEvent: boolean = false;
  phone: string;
  email: string;
  payload: Object;
  eventData = null;
  tooltipText = '';
  form: FormGroup;

  constructor(
    public dialog: MatDialog,
    private readonly eventListService: EventListService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    if (this.isAgendaPage) {
      this.getQueryParams();
      if (this.phone) {
        return;
      }
      this.initView();
    } else {
      this.initView();
      this.createForm();
    }
  }

  initEventData(): void {
    this.eventData = this.eventListService.getEventData();
    if (!this.eventData) {
      return;
    }
    const contactEmail =
      this.eventData.name && this.eventData.email ? ', ' + this.eventData.email : this.eventData.email;
    const carModel = (this.eventData.name || this.eventData.email)
      && this.eventData.car_model ? ', ' + this.eventData.car_model : this.eventData.car_model;
    this.tooltipText = this.eventData.name + contactEmail + carModel;
  }

  createForm(): void {
    this.form = this.fb.group({
      phone: [this.phone],
      email: [this.email, [Validators.email]]
    });
  }

  submitForm(): void {
    if (!this.form.valid) {
      return;
    }
    this.phone = this.form.value.phone;
    this.email = this.form.value.email;
    this.setPayload();
  }

  initView(): void {
    this.initEventData();
    if (this.eventData) {
      this.isCopiedEvent = true;
      const contactTel = this.eventData.phone;
      // TODO: api should do that
      this.phone = contactTel ? contactTel.split(' ')
        .join('') : contactTel;
      this.email = this.eventData.email;
      this.setPayload();

      return;
    }
    this.phone = '';
    this.email = '';
    this.setPayload();
  }

  clear(): void {
    this.eventListService.clearEventData();
    this.isCopiedEvent = false;
  }

  search(): void {
    this.isCopiedEvent = false;
    this.openModal();
  }

  onCopy(): void {
    this.isCopiedEvent = true;
  }

  private setPayload() {
    this.payload = {
      contactTel: this.phone,
      contactEmail: this.email
    };
    if (false) {
      // TODO: should take defaultEntityId or institutionId() from local storage. not sure
      // const institutions = localStorage.getItem(LocalStorageLabels.selectedInstitution);
      // if (institutions) {
      //   const id = parseInt(institutions.split(',')[0]);
      // }
      this.payload['institution'] = {
        id: '1'
      };
    }
  }

  private getQueryParams(): void {
    this.route.queryParams
      .pipe(
        filter(r => !!r && r.phone),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.phone = params.phone;
        this.initEventData();
        if (this.eventData) {
          this.isCopiedEvent = true;
        }
        this.openModal();
      });
  }

  private openModal(): void {
    const dialogRef = this.dialog.open(EventListModalComponent,
      {
        minWidth: '80vw',
        maxWidth: '100vw',
        maxHeight: '90vh',
        data: { phone: this.phone }
      });
    dialogRef.afterClosed()
      .subscribe(isCopiedEvent => {
        // undefined - it means click outside of modal then show back phone modal
        this.isCopiedEvent = isCopiedEvent === undefined ? true : isCopiedEvent;
        this.initEventData();
      });
  }

}
