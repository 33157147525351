import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TokenService } from '~/shared/services';
import { UserTokenData } from '~/shared/models';
import { SetAgendaSettings } from '~/store/agenda-view/agenda-view.actions';

import * as CurrentUserActions from './current-user.actions';
import { CurrentUserService } from './current-user.service';
import { SetUserSettings } from '~/store/user-settings';

@Injectable()
export class CurrentUserEffects {
  loadCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentUserActions.loadUser),
    mergeMap(() => this.currentUserService.getProfile()
      .pipe(
        map(currentUser => CurrentUserActions.loadUserSuccess({currentUser})),
        catchError(error => observableOf(CurrentUserActions.loadUserFail({error})))
      )
    )
  ));
  loadCurrentUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentUserActions.loadUserSuccess),
    switchMap(({currentUser}) => [
      new SetAgendaSettings({agendaSettings: {
          isFormSticky: currentUser.context.isFormSticky,
          displayTimeLabel: currentUser.context.isTimeLabelDisplay,
          numberOfDays: currentUser.context.howManyDaysDisplay
        }, excludeUpdate: true}),
      new SetUserSettings({eventNotification: currentUser.context.eventNotification})
    ])
  ));
  getUserDataFromToken$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentUserActions.getUserFromToken),
    map(() => UserTokenData.fromToken()),
    switchMap(userTokenData => [
        CurrentUserActions.setUserFromToken({userTokenData}),
        CurrentUserActions.loadUser()
      ]
    )
  ));
  removeCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentUserActions.removeCurrentUser),
    tap(() => TokenService.removeToken()),
    tap(() => this.router.navigate(['/auth/sign-in']))
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private currentUserService: CurrentUserService,
    private router: Router
  ) {
  }

}
