import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CustomerEffects } from './customer.effects';
import { customerReducer } from './customer.reducers';
import { CUSTOMERS } from './customer.state';
import { NoteStateModule } from './note';
import { AppointmentStateModule } from './appointment';
import { CustomerFormStateModule } from './customer-form';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CUSTOMERS, customerReducer),
    EffectsModule.forFeature([CustomerEffects]),
    NoteStateModule,
    AppointmentStateModule,
    CustomerFormStateModule
  ]
})
export class CustomerStateModule {
}
