import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';

import { MaterialModule, SharedModule } from '~/framework';

import { SelectAllCheckboxModule } from '~/shared/components/select-all-checkbox';
import { MdePopoverModule } from '~/shared/components/popover';
import { EventActionModule } from '~/shared/components/event-action';
import { DatepickerHeaderModule } from '~/shared/components/datepicker-header';

import { AgendaInfoComponent } from './agenda-info/agenda-info.component';
import { AgendaComponent } from './agenda.component';
import { ClickModule } from '~/shared/directives/click/click.module';
import { AgendaEventComponent } from './agenda-event/agenda-event.component';
import { AgendaEventGroupedComponent } from './agenda-event-grouped/agenda-event-grouped.component';
import { AgendaFreeTimeComponent } from './agenda-free-time/agenda-free-time.component';
import { EventsBufferComponent } from './events-buffer/events-buffer.component';
import { FileUploadModalComponent } from '~/shared/components/agenda/file-upload-modal/file-upload-modal.component';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';
import { IframeContainerModule } from '~/shared/components/iframe-container/iframe-container.module';
import { MatMenuModule } from '@angular/material/menu';
import { AgendaEventMotComponent } from './agenda-event/agenda-event-mot/agenda-event-mot.component';
import { AgendaEventBeautyComponent } from './agenda-event/agenda-event-beauty/agenda-event-beauty.component';
import { AgendaEventDriverComponent } from './agenda-event/agenda-event-driver/agenda-event-driver.component';
import { AgendaEventGarageComponent } from './agenda-event/agenda-event-garage/agenda-event-garage.component';
import { AgendaEventHairComponent } from './agenda-event/agenda-event-hair/agenda-event-hair.component';
import { AgendaEventFoodComponent } from './agenda-event/agenda-event-food/agenda-event-food.component';
import { AgendaEventHealthComponent } from './agenda-event/agenda-event-health/agenda-event-health.component';
import { AgendaEventBasicserviceComponent } from './agenda-event/agenda-event-basicservice/agenda-event-basicservice.component';
import { AgendaEventEthtestComponent } from './agenda-event/agenda-event-ethtest/agenda-event-ethtest.component';
import { AgendaEventDefaultComponent } from './agenda-event/agenda-event-default/agenda-event-default.component';
import { CommonPipeModule } from '~/shared/pipes';
import { AgendaBusyTimeComponent } from './agenda-busy-time/agenda-busy-time.component';
import { AgendaEventStackComponent } from './agenda-event-stack/agenda-event-stack.component';
import { AgendaEventGroupDialogComponent } from './agenda-event-group-dialog/agenda-event-group-dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CalendarRuleStateModule } from '~/store/calendar-rule';
import { AgendaEventFilterComponent } from '~/shared/components/agenda/agenda-event-filter/agenda-event-filter.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatAlertDialogModule } from '@apfr/components/core';

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatListModule,
    SelectAllCheckboxModule,
    MdePopoverModule,
    EventActionModule,
    MatDialogModule,
    MatMenuModule,
    DatepickerHeaderModule,
    ClickModule,
    MatAlertDialogModule,
    IframeContainerModule,
    CommonPipeModule,
    ColorPickerModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    CalendarRuleStateModule,
    MatBottomSheetModule
  ],
  declarations: [
    AgendaComponent,
    AgendaInfoComponent,
    AgendaEventComponent,
    AgendaEventGroupedComponent,
    AgendaFreeTimeComponent,
    EventsBufferComponent,
    FileUploadModalComponent,
    FilePreviewModalComponent,
    AgendaEventMotComponent,
    AgendaEventBeautyComponent,
    AgendaEventDriverComponent,
    AgendaEventGarageComponent,
    AgendaEventHairComponent,
    AgendaEventFoodComponent,
    AgendaEventHealthComponent,
    AgendaEventBasicserviceComponent,
    AgendaEventEthtestComponent,
    AgendaEventDefaultComponent,
    AgendaBusyTimeComponent,
    AgendaEventStackComponent,
    AgendaEventGroupDialogComponent,
    AgendaEventFilterComponent
  ],
  exports: [
    AgendaComponent,
    AgendaInfoComponent
  ]
})
export class AgendaModule {
}
