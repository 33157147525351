<div mat-dialog-title>
  <header>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button class="material-drawer-button" (click)="onNoClick()">
          <mat-icon>close</mat-icon>
        </button>
        <span class="spacer"></span>
        <button mat-button cdkFocusInitial (click)="saveSelected()" [disabled]="isNotEnoughRoom || selectedResourcesControl.invalid">
          {{ 'apply' | translate }}
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </header>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="isMobile; else desktopContent">
    <ng-container *ngTemplateOutlet="mobileContent"></ng-container>
  </ng-container>
  <ng-container *ngTemplateOutlet="modalContent"></ng-container>
</div>

<ng-template #mobileContent>
  <mat-form-field class="full-width">
    <mat-select [placeholder]="'institutions' | translate"
                [value]="selectedInstitutions"
                [compareWith]="compareInstitutionFn"
                disableOptionCentering
                panelClass="selectBoxInModal"
                (opened)="changeSelectHeight()"
                (selectionChange)="selectedInstitutionsChange($event)"
                multiple #institutionSelect="matSelect">

      <mat-select-trigger>
        <mat-chip-list #chipInstitutionList>
          <mat-chip *ngFor="let institution of selectedInstitutions"
                    (removed)="removeInstitution(institution)">
            {{ institution.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>

      <div class="close-select">
        <button mat-icon-button color="warn" (click)="$event.stopPropagation(); institutionSelect.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-option *ngFor="let institution of institutions; trackBy: trackByFn"
                  [value]="institution">
        {{ institution.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="margin-bottom-25 slide-toggle-container">
    <label>{{ 'grouped_by_inst' | translate  }}</label>
    <mat-slide-toggle [checked]="isGroupByInst"
                      (change)="toggleGroupedInst($event.checked)">
    </mat-slide-toggle>
  </div>
</ng-template>

<ng-template #desktopContent>
  <mat-form-field class="full-width">
    <mat-chip-list #chipInstitutionList>
      <mat-chip *ngFor="let institution of selectedInstitutions"
                (removed)="removeInstitution(institution)">
        {{ institution.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <input type="text" matInput
             #institutionInput
             #institutionInputTrigger="matAutocompleteTrigger"
             [placeholder]="'institutions' | translate"
             [formControl]="institutionControl"
             [matAutocomplete]="institutionAuto"
             [matChipInputFor]="chipInstitutionList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
    </mat-chip-list>
    <mat-autocomplete #institutionAuto="matAutocomplete" (optionSelected)="selectInstitution($event.option.value.id)"
                      [displayWith]="displayInstitutionFn">
      <div class="close-select">
        <button mat-icon-button color="warn" (click)="institutionInputTrigger.closePanel()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-option *ngFor="let institution of filteredInstitutionOptions$ | async; trackBy: trackByFn" [value]="institution">
        {{ institution.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint class="error-hint" *ngIf="selectedInstitutions.length === 0">
      {{ 'please_select_institution' | translate }}
    </mat-hint>
  </mat-form-field>

  <div class="margin-bottom-25 slide-toggle-container">
    <label>{{ 'grouped_by_inst' | translate  }}</label>
    <mat-slide-toggle [checked]="isGroupByInst"
                      (change)="toggleGroupedInst($event.checked)">
    </mat-slide-toggle>
  </div>
</ng-template>

<ng-template #modalContent>
  <mat-form-field class="full-width">
    <mat-select [placeholder]="'resources' | translate" multiple
                panelClass="selectBoxInModal"
                disableOptionCentering
                (opened)="changeSelectHeight()"
                [formControl]="selectedResourcesControl" #resourceSelect>
      <div class="close-select">
        <button mat-icon-button color="warn" (click)="resourceSelect.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-select-trigger>
        <span *ngIf="!isGroupByResource">
          {{ selectedResourcesControl.value ? displayResourcesFn() : ''}}
        </span>
        <span *ngIf="isGroupByResource">
          {{ 'grouped_all' | translate }}
        </span>
      </mat-select-trigger>

      <div class="full-width position-relative">
        <mat-checkbox class="mat-option" #groupedResource="matCheckbox"
                      [disableRipple]="true"
                      [checked]="isGroupByResource"
                      (change)="toggleGroupedResources($event.checked)">
          {{ 'grouped_all' | translate }}
        </mat-checkbox>
        <div class="mat-option-ripple" mat-ripple [matRippleTrigger]="groupedResource._elementRef.nativeElement"></div>
      </div>
      <apfr-select-all
        [text]="'select_all' | translate"
        [control]="selectedResourcesControl"
        [values]="resources"
        [disabled]="isGroupByResource">
      </apfr-select-all>
      <ng-container *ngIf="selectedInstitutions?.length > 1; else withoutGroupOptions">
        <mat-optgroup *ngFor="let group of groupedResources"
                      [label]="group.name"
                      [disabled]="isGroupByResource">
          <mat-option *ngFor="let resource of group.resources"
                      [value]="resource">
            {{ resource.name }}
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <ng-template #withoutGroupOptions>
        <mat-option *ngFor="let resource of resources"
                    [disabled]="isGroupByResource"
                    [value]="resource">
          {{ resource.name }}
        </mat-option>
      </ng-template>
    </mat-select>
  </mat-form-field>
</ng-template>
