import { NgModule } from '@angular/core';
import { ClickDirective } from './click.directive';

/**
 * ClickModule
 *
 * It solves two issues:
 * 1. if there are two click-events: one is on parent and the second one is on child (not necessary use event.stopPropagation).
 * 2. it replaces click event with tap on mobile devices (required for drag and drop)
 */
@NgModule({
  declarations: [
    ClickDirective
  ],
  exports: [
    ClickDirective
  ]
})
export class ClickModule {}
