<ng-container *ngIf="parentForm; else newFormTemplate">
  <ng-container *ngFor="let field of config;"
                apfrDynamicField
                [config]="field"
                [group]="parentForm">
  </ng-container>
</ng-container>
<ng-template #newFormTemplate>
  <form [formGroup]="form" (ngSubmit)="submitted.emit(form.value)">
    <ng-container *ngFor="let field of config;"
                  apfrDynamicField
                  [config]="field"
                  [group]="form">
    </ng-container>
    <button *ngIf="!hideSubmit" class="full-width" color="primary" type="submit" mat-flat-button>{{ 'submit' | translate }}</button>
  </form>
</ng-template>
