import { Component, ContentChild, Input, OnInit } from '@angular/core';

import { CardActionDirective } from '~/shared/directives';

@Component({
  selector: 'apfr-card-tab',
  templateUrl: './card-tab.component.html',
  styleUrls: ['./card-tab.component.scss']
})
export class CardTabComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  active: boolean;

  /** Content for the actions `<ng-template cdk-portal>`. */
  @ContentChild(CardActionDirective) actions: CardActionDirective;

  constructor() { }

  ngOnInit() {
  }

}
