import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { APPOINTMENTS } from './appointment.state';
import { appointmentReducer } from './appointment.reducers';
import { AppointmentEffects } from './appointment.effects';
import { AppointmentFormStateModule } from './appointment-form';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(APPOINTMENTS, appointmentReducer),
    EffectsModule.forFeature([AppointmentEffects]),
    AppointmentFormStateModule
  ]
})
export class AppointmentStateModule {
}
