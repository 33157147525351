import { NavigationActions, NavigationActionTypes } from '~/store/navigation/navigation.actions';

export const NAVIGATION = 'navigation';

export interface NavigationState {
  isResolverLoading: boolean;
}

export const navigationInitialState: NavigationState = {
  isResolverLoading: false
};


export function navigationReducer(state = navigationInitialState, action: NavigationActions) {
  switch (action.type) {
    case NavigationActionTypes.ResolverLoadingState:
      return {
        ...state,
        isResolverLoading: action.payload
      };
    default:
      return state;
  }
}
