import { NgModule } from '@angular/core';
import { EventActionComponent } from './event-action.component';
import { MaterialModule, SharedModule } from '~/framework';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    MatButtonToggleModule
  ],
  declarations: [
    EventActionComponent
  ],
  exports: [
    EventActionComponent
  ]
})
export class EventActionModule { }
