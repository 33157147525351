import { Component } from '@angular/core';
import { BaseEventComponent } from '~/framework/core/src/base.event.component';

@Component({
  selector: 'apfr-agenda-event-hair',
  templateUrl: './agenda-event-hair.component.html',
  styleUrls: ['./agenda-event-hair.component.scss']
})
export class AgendaEventHairComponent extends BaseEventComponent {

}
