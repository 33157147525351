import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MaterialModule, SharedModule } from '~/framework';

import { DynamicFormComponent } from './dynamic-form.component';

import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { DynamicFieldDirective } from './components/dynamic-field.directive';
import { FormDateComponent } from './components/form-date/form-date.component';
import { FormToggleComponent } from './components/form-toggle/form-toggle.component';
import { FormAutocompleteComponent } from './components/form-autocomplete/form-autocomplete.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';

@NgModule({
    imports: [
        SharedModule,
        ReactiveFormsModule,
        MaterialModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatAutocompleteModule
    ],
    declarations: [
        DynamicFormComponent,
        FormInputComponent,
        FormSelectComponent,
        DynamicFieldDirective,
        FormDateComponent,
        FormToggleComponent,
        FormAutocompleteComponent,
        FormTextareaComponent
    ],
    exports: [DynamicFormComponent]
})
export class DynamicFormModule { }
