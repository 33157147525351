<div class="agenda-event" [ngStyle]="styles">
  <ng-container
    *ngFor="let event of eventStack.events; trackBy: trackByFn"
  >
    <apfr-agenda-event-grouped
      *ngIf="event.overlapCount !== undefined; else singleEvent"
      [eventRes]="$any(event)"
      [isDraggable]="isDraggable"
      [viewType]="viewType"
      [cellHeight]="cellHeight"
      [startAgenda]="eventStack.startTimeString"
      [intervalAgenda]="intervalAgenda"
      (selectEvent)="_selectEvent($event)"
    ></apfr-agenda-event-grouped>

    <ng-template #singleEvent>
      <apfr-agenda-event
        [event]="event"
        [viewType]="viewType"
        [cellHeight]="cellHeight"
        [startAgenda]="eventStack.startTimeString"
        [intervalAgenda]="intervalAgenda"
        [isMobile]="isMobile"
        [isGroupedByResources]="isGroupedByResources"
        [numberOfDays]="numberOfDays"
        [colorMode]="colorMode"
        [isDraggable]="isDraggableEvent(event)"
        [draggingEvent]="draggingEvent"
        [hidden]="!isShowEvent(event)"
        (mouseDown)="_mouseDown($event)"
      ></apfr-agenda-event>
    </ng-template>

  </ng-container>
</div>
