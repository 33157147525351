import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppointmentService } from '~/+customer/shared';

import * as fromActions from './appointment.actions';
import { AppointmentActionTypes } from './appointment.actions';

@Injectable()
export class AppointmentEffects {
  loadAppointments$ = createEffect(() => this.actions$
    .pipe(
      ofType(AppointmentActionTypes.GetAppointments),
      map((action: fromActions.GetAppointments) => action.payload),
      switchMap((customerId: string) => {
        return this.appointmentService.getAppointments(customerId)
          .pipe(
            map(appointments => new fromActions.GetAppointmentsSuccess(appointments)),
            catchError(error => observableOf(new fromActions.GetAppointmentsFail(error)))
          );
      })
    ));
  loadAppointment$ = createEffect(() => this.actions$
    .pipe(
      ofType(AppointmentActionTypes.GetOneAppointment),
      map((action: fromActions.GetOneAppointment) => action.payload),
      switchMap((id: string) => {
        return this.appointmentService.getAppointment(id)
          .pipe(
            map(appointment => new fromActions.GetOneAppointmentSuccess(appointment)),
            catchError(error => observableOf(new fromActions.GetOneAppointmentFail(error)))
          );
      })
    ));

  constructor(private actions$: Actions,
              private appointmentService: AppointmentService) {
  }
}
