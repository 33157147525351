import { Action } from '@ngrx/store';

export const enum UserSettingsActionTypes {
  SetUserSettings = '[User Settings] Set',
  UpdateUserSettings = '[User Settings] Update'
}

export interface UserSettings {
  eventNotification: boolean;
}

export class SetUserSettings implements Action {
  readonly type = UserSettingsActionTypes.SetUserSettings;

  constructor(public payload: UserSettings) {
  }
}

export class UpdateUserSettings implements Action {
  readonly type = UserSettingsActionTypes.UpdateUserSettings;

  constructor(public payload: UserSettings) {
  }
}

export type UserSettingsActions =
  SetUserSettings |
  UpdateUserSettings;
