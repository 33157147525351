import { NgModule } from '@angular/core';

import { MaterialModule, SharedModule } from '~/framework';

import { CardRelationshipComponent } from './card-relationship.component';
import { CardTabComponent } from './card-tab/card-tab.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule
  ],
  exports: [
    CardTabComponent,
    CardRelationshipComponent
  ],
  declarations: [
    CardRelationshipComponent,
    CardTabComponent
  ]
})
export class CardRelationshipModule { }
