import { createFeatureSelector, createSelector } from '@ngrx/store';
import { entityErrorFn, entityStartLoadingFn, entityStopLoadingFn } from '~/store/entity-reducer.utils';
import { customerAdapter, CUSTOMERS, CustomerState, initialCustomerState } from '~/store/customers/customer.state';

import * as fromActions from './customer.actions';
import { CustomerActionTypes } from './customer.actions';

export function customerReducer(
  state: CustomerState = initialCustomerState,
  action: fromActions.CustomerActions
): CustomerState {
  switch (action.type) {
    case CustomerActionTypes.AddOneCustomer:
      return entityStartLoadingFn(state);
    case CustomerActionTypes.AddOneCustomerSuccess:
      return customerAdapter.addOne(action.payload, {
        ...entityStopLoadingFn(state),
        selectedId: action.payload.id
      });
    case CustomerActionTypes.AddOneCustomerFail:
      return entityErrorFn(state, action.payload);

    case CustomerActionTypes.DeleteOneCustomer:
      return entityStartLoadingFn(state);
    case CustomerActionTypes.DeleteOneCustomerSuccess:
      return customerAdapter.removeOne(action.payload, {
        ...entityStopLoadingFn(state),
        selectedId: undefined
      });
    case CustomerActionTypes.DeleteOneCustomerFail:
      return entityErrorFn(state, action.payload);

    case CustomerActionTypes.GetAllCustomers:
      return entityStartLoadingFn(state);
    case CustomerActionTypes.GetAllCustomersSuccess:
      return customerAdapter.setAll(action.payload, entityStopLoadingFn<CustomerState>(state));
    case CustomerActionTypes.GetAllCustomersFail:
      return entityErrorFn(state, action.payload);

    case CustomerActionTypes.GetOneCustomer:
      return entityStartLoadingFn(state);
    case CustomerActionTypes.GetOneCustomerSuccess:
      return customerAdapter.upsertOne(action.payload, {
        ...entityStopLoadingFn(state),
        selectedId: action.payload.id
      });
    case CustomerActionTypes.GetOneCustomerFail:
      return entityErrorFn(state, action.payload);

    case CustomerActionTypes.SetCustomersFromDB:
      return customerAdapter.setAll(action.payload, entityStopLoadingFn<CustomerState>(state));

    default:
      return state;
  }
}

const selectCustomerState = createFeatureSelector<CustomerState>(CUSTOMERS);

export const selectCustomerIds = customerAdapter.getSelectors(selectCustomerState).selectIds;
export const selectCustomerEntities = customerAdapter.getSelectors(selectCustomerState).selectEntities;
export const selectAllCustomers = customerAdapter.getSelectors(selectCustomerState).selectAll;
export const selectCustomerTotal = customerAdapter.getSelectors(selectCustomerState).selectTotal;

export const selectCustomerId = createSelector(
  selectCustomerState,
  (state) => state.selectedId
);

export const selectOneCustomer = createSelector(
  selectCustomerEntities,
  selectCustomerId,
  (customerEntities, customerId) => customerEntities[customerId]
);
