<div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <button mat-button type="button" class="mat-calendar-period-button"
            (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
            [attr.aria-describedby]="_buttonDescriptionId"
            cdkAriaLive="polite">
      <span [attr.id]="_buttonDescriptionId">{{periodButtonText}}</span>
      <div class="mat-calendar-arrow"
           [class.mat-calendar-invert]="calendar.currentView !== 'month'"></div>
    </button>

    <div class="mat-calendar-spacer">
      <button mat-raised-button
              color="accent"
              class="today"
              type="button"
              [disabled]="isToday()" (click)="today()">Today</button>
    </div>

    <button mat-icon-button type="button" class="mat-calendar-previous-button"
            [disabled]="!previousEnabled()" (click)="previousClicked()"
            [attr.aria-label]="prevButtonLabel">
    </button>

    <button mat-icon-button type="button" class="mat-calendar-next-button"
            [disabled]="!nextEnabled()" (click)="nextClicked()"
            [attr.aria-label]="nextButtonLabel">
    </button>
  </div>
</div>
