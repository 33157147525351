<div *ngIf="event" class="agenda-event" #agendaEventEl
     tabindex="0"
     [attr.eventId]="event.id"
     [ngStyle]="styles"
     [ngClass]="{
     'not-done-event': event.statutEntry === EventStatuses.NotDone,
     'dragging-event':  draggingEvent?.id === event.id
     }"
     [class.not-done-event]="event.statutEntry === EventStatuses.NotDone"
     (mousedown)="mousedown($event)">
  <ng-container *ngIf="!isMobile">
    <div class="popover-trigger"
         *ngIf="event.type !== EventTypes.Tampon &&
          event.type !== EventTypes.Ferme &&
           (event.contactTel || event.contactEmail || event.contactName) && draggingEvent?.id !== event.id"
         [mdePopoverTriggerFor]="appPopover"
         mdePopoverTriggerOn="hover">
    </div>
  </ng-container>
  <div class="agenda-event-content">
    <div class="another-color-wrapper"
         *ngIf="numberOfDays >= breakpointNumberOfDays && (event.contactEmail || event.contactTel || event.contactName)"
         [ngStyle]="{
         'background-color': getColor(true)
         }"
    >
      <div class="another-color" [ngStyle]="{
         'background-color': getColor()
         }"></div>
    </div>
    <div class="agenda-time">
      <div #eventTemplateContainer></div>
      <span class="attach-icon" *ngIf="event.files && event.files.length">
        <mat-icon>attach_file</mat-icon>
      </span>
    </div>
  </div>
</div>
<mde-popover #appPopover="mdePopover"
             mdePopoverPositionX="after"
             mdePopoverOffsetY="20"
             mdePopoverOffsetX="10"
             mdePopoverEnterDelay="600"
             mdePopoverLeaveDelay="300"
             [mdePopoverCloseOnClick]="false"
             [mdePopoverOverlapTrigger]="true">
  <mat-card style="min-width: 280px; max-width: 280px;">
    <mat-card-content>
      <apfr-agenda-info
        [event]="event"
        [isDraggable]="isDraggable"
        [isMobile]="isMobile"
        [isGroupedByResources]="isGroupedByResources"
      ></apfr-agenda-info>
    </mat-card-content>
  </mat-card>
</mde-popover>
