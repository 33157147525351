import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EVENT_ORIGIN, FileUploadService } from '~/shared/services/file-upload/file-upload.service';
import { finalize, map } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { File } from '~/shared/models/agenda/file.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '~/shared/components/snack-bar';
import { FilePreviewModalComponent } from '~/shared/components/agenda/file-preview-modal/file-preview-modal.component';
import { Store } from '@ngrx/store';
import { EventState, UpdateEvent } from '~/store/calendar';
import { MatAlertDialogService } from '@apfr/components/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'apfr-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss'],
  providers: [FileUploadService]
})
export class FileUploadModalComponent implements OnInit {

  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
  files  = [];
  uploadedFiles: File[] = [];
  isMobile: boolean;

  constructor(
    public dialogRef: MatDialogRef<FileUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private readonly matAlertDialogService: MatAlertDialogService,
    private fileUploadService: FileUploadService,
    private snackBar: MatSnackBar,
    private readonly store: Store<EventState>
  ) {}

  ngOnInit() {
    this.uploadedFiles = this.data['files'];
    this.isMobile = this.data['isMobile'];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  uploadFile(files) {
    const formData = new FormData();

    // prepare formData
    files.forEach(file => formData.append('files[]', file.data));
    formData.append('origin', EVENT_ORIGIN);
    formData.append('originId', this.data['id']);

    // upload file
    this.fileUploadService.upload(formData)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.Response:
              return event;
          }
        }),
        finalize(() => {
          this.files = [];
        })
      )
      .subscribe(
        (event: any) => {
          if (typeof (event) === 'object') {
            event.body.forEach((uploadedFile: File) => this.uploadedFiles.push(uploadedFile));
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 5_000,
              data: {
                message: 'files_upload_succeed',
                action: 'ok'
              }
            });
            this.updateState();
          }
        },
        () => {
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 5_000,
            data: {
              message: 'files_upload_failed',
              action: 'ok'
            }
          });
        }
    );
  }

  // remove all files
  removeAllFiles(file: File) {
    this.openAlertConfirmDialog()
      .subscribe(() => {
        const originArr = file.origin.split('\\');
        const origin = originArr[originArr.length - 1];
        this.fileUploadService.deleteAll(origin, file.originId)
          .subscribe(
            () => {
              this.uploadedFiles = [];
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'files_removed',
                  action: 'ok'
                }
              });
              this.updateState();
            },
            () => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'files_not_removed',
                  action: 'ok'
                }
              });
            }
          );
      });
  }

  // remove specific file
  removeFile(file: File) {
    this.openAlertConfirmDialog()
      .subscribe(() => {
        this.fileUploadService.delete(file.id)
          .subscribe(
            () => {
              const index = this.uploadedFiles.findIndex((uploadedFile: File) => uploadedFile.id === file.id);
              if (index < 0) {
                return;
              }
              this.uploadedFiles.splice(index, 1);
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'file_removed',
                  action: 'ok'
                }
              });
              this.updateState();
            },
            () => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'file_not_removed',
                  action: 'ok'
                }
              });
            }
          );
      });
  }

  openPreviewModal(file) {
    const dialogConfig: MatDialogConfig = {
      panelClass: ['full-size-dialog', 'files-preview-modal'],
      height: '100vh',
      width: '100vw',
      maxWidth: '100%',
      data: {
        file: file
      }
    };
    this.dialog.open(FilePreviewModalComponent, dialogConfig);
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.uploadFile(this.files);
  }

  // confirm dialog
  private openAlertConfirmDialog(): Observable<any> {
    return this.matAlertDialogService.openDialog();
  }

  private updateState() {
    this.store.dispatch(new UpdateEvent({
      updateData: {
        id: this.data['id'],
        changes: {
          files: this.uploadedFiles
        }
      }
    }));
  }

}
