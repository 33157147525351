import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FreeTimeState, FREE_TIME, initialFreeTimeState } from './free-time.state';
import { FreeTimeActions, FreeTimeActionTypes } from './free-time.actions';

export function freeTimeReducer(
  state: FreeTimeState = initialFreeTimeState,
  action: FreeTimeActions
): FreeTimeState {
  switch (action.type) {
    case FreeTimeActionTypes.GetFreeTime:
      return {
        ...state,
        isLoading: true
      };
    case FreeTimeActionTypes.GetFreeTimeSuccess:
      return {
        ...state,
        // clone object, to let know angular that something is changed
        data: action.payload['freeTimeResponse'],
        isLoading: false
      };
    case FreeTimeActionTypes.GetFreeTimeFail:
      return {
        ...state,
        data: initialFreeTimeState.data,
        isLoading: false,
        error: action.payload['error']
      };
    default:
      return state;
  }
}

const selectFreeTimeState = createFeatureSelector<FreeTimeState>(FREE_TIME);

export const getFreeTime = createSelector(
  selectFreeTimeState,
  (state) => state.data
);

