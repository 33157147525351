import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { TokenService } from '~/shared/services';
import { CurrentUserState, removeCurrentUser } from '~/store/current-user';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuards implements CanActivate, CanLoad {

  constructor(private readonly router: Router,
              private readonly store: Store<CurrentUserState>) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean  {
    if (TokenService.isUserLoggedIn()) {
      return true;
    } else {
      this.store.dispatch(removeCurrentUser());
      this.router.navigate(['auth/sign-in']);

      return false;
    }
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean {
    if (TokenService.isUserLoggedIn()) {
      return true;
    } else {
      this.store.dispatch(removeCurrentUser());
      this.router.navigate(['auth/sign-in']);

      return false;
    }
  }
}
