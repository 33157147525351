import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Event as EventModel } from '~/shared/models';
import { EventState, UpdateEvent } from '~/store/calendar';

enum EventStatuses {
  InProgress = 'y',
  CV = 'v',
  Done = 'd',
  NotDone = 'w'
}

@Component({
  selector: 'apfr-event-action',
  templateUrl: './event-action.component.html',
  styleUrls: ['./event-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventActionComponent {

  @Input() event: EventModel;
  @Input() vertical = false;
  @Input() disabled = false;

  eventStatuses = EventStatuses;

  constructor(private readonly store: Store<EventState>) {}

  isChecked(status: string): boolean {
    return this.event.statutEntry === status;
  }

  changeStatus(status: string): void {
    this.store.dispatch(new UpdateEvent({
      updateData: {
        id: this.event.id,
        changes: {
          statutEntry: status
        }
      }
    }));
  }

}
