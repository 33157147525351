import { createFeatureSelector, createSelector } from '@ngrx/store';
import { entityErrorFn, entityStartLoadingFn, entityStopLoadingFn } from '~/store/entity-reducer.utils';

import { AppointmentFormState, APPOINTMENT_FORM, initialAppointmentFormState } from './appointment-form.state';
import * as formActions from './appointment-form.actions';
import { AppointmentFormActionTypes } from './appointment-form.actions';

export function appointmentFormReducer(
  state: AppointmentFormState = initialAppointmentFormState,
  action: formActions.AppointmentFormActions
): AppointmentFormState {
  switch (action.type) {
    case AppointmentFormActionTypes.LoadForm:
      return entityStartLoadingFn(state);
    case AppointmentFormActionTypes.LoadFormSuccess:
      return {
        ...entityStopLoadingFn(state),
        fields: action.payload
      };
    case AppointmentFormActionTypes.LoadFormFail:
      return entityErrorFn(state, action.payload);
    default:
      return state;
  }
}

export const selectAppointmentFormState = createFeatureSelector<AppointmentFormState>(APPOINTMENT_FORM);

export const selectAppointmentFields = createSelector(
  selectAppointmentFormState,
  (state) => state.fields
);

export const selectAppointmentFormError = createSelector(
  selectAppointmentFormState,
  (state) => state.error
);

export const selectAppointmentFormLoading = createSelector(
  selectAppointmentFormState,
  (state) => state.isLoading
);
