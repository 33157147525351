import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Event as EventModel } from '~/shared/models';

export const EVENTS_BUFFER = 'events_buffer';

export interface EventsBufferState extends EntityState<EventModel> {
  selectedId: number | null;
}

export const eventsBufferAdapter: EntityAdapter<EventModel> = createEntityAdapter<EventModel>({
  selectId: cur => cur ? cur.id : null
});

export const initialBufferState: EventsBufferState = eventsBufferAdapter.getInitialState({
  selectedId: null
});

