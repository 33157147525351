import { FreeTimeResponse } from '~/+calendar/shared/calendar.service';

export const FREE_TIME = 'freeTime';

export interface FreeTimeState {
  isLoading?: boolean;
  error: any;
  data: FreeTimeResponse | undefined;
}

export const initialFreeTimeState: FreeTimeState = {
  isLoading: undefined,
  error: undefined,
  data: undefined
};
