import { Injectable } from '@angular/core';

import { environment } from '~/../environments/environment';
import { UserTokenModel } from '~/shared/models/user/user-token.model';

/**
 * TokenService
 * @description
 * Manipulate with localStorage.
 * {@link CurrentUserEffects}
 */
@Injectable({
  providedIn: 'root'
})
export class TokenService {
  static getToken(): string {
    return localStorage.getItem(environment.token);
  }

  static setToken(token: string) {
    localStorage.setItem(environment.token, token);
  }

  static hasToken(): boolean {
    return !!localStorage.getItem(environment.token);
  }

  static getUserInfoFromToken(): UserTokenModel {
    return this.parseJWT(this.getToken());
  }

  static removeToken(): void {
    if (this.hasToken()) {
      localStorage.removeItem(environment.token);
    }
  }

  static isUserLoggedIn(): boolean {
    return (this.hasToken() && !this.isTokenExpired());
  }

  static getTokenExpirationDate(token: string = this.getToken()): Date | null {
    let decoded: any;
    decoded = this.parseJWT(token);

    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  static isTokenExpired(token: string = this.getToken(), offsetSeconds?: number): boolean {
    if (token === null || token === '') {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return true;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  private static parseJWT(token: string): UserTokenModel {
    const base64Url = token.split('.')[1];
    const base64 = base64Url
      .replace('-', '+')
      .replace('_', '/');

    return JSON.parse(window.atob(base64));
  }

}
