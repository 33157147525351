import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardActionDirective } from '~/shared/directives';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent } from '~/framework';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'apfr-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent extends BaseComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  data: any[];
  @Input()
  selectItem: string;

  @Output()
  selectedItem = new EventEmitter<any>();
  @Output()
  filterChanged = new EventEmitter();

  /** Content for the actions `<ng-template cdk-portal>`. */
  @ContentChild(CardActionDirective) actions: CardActionDirective;

  filterText = '';

  form: FormGroup;

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.onFilterChange();
  }

  select(item: any) {
    this.selectedItem.emit(item);
    this.selectItem = item.id;
  }

  trackList(index, item) {
    return item.id;
  }

  private createForm(): void {
    this.form = this.fb.group({
      filter: ''
    });
  }

  private onFilterChange(): void {
    this.form.get('filter').valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(data => this.filterChanged.emit(data));
  }

}
