import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { EVENTS } from './calendar.state';
import { calendarReducer } from './calendar.reducer';
import { CalendarEffects } from './calendar.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(EVENTS, calendarReducer),
    EffectsModule.forFeature([CalendarEffects])
  ]
})
export class CalendarStateModule {
}
