import { ExternalMenuLinksModel } from '~/shared/models';

export const CONFIG_APP = 'config_app';

export interface ConfigAppState {
  externalLinks: ExternalMenuLinksModel;
  online: boolean;
  isMobile: boolean;
  isTablet: boolean;
}

export const initialConfigAppState: ConfigAppState = {
  externalLinks: undefined,
  online: undefined,
  isMobile: false,
  isTablet: false
};
