<ng-container *ngIf="isCopiedEvent">
  <div class="copy-event-card" [matTooltip]="tooltipText">
    <mat-card>
      <button mat-icon-button (click)="search()">
        <mat-icon aria-hidden="false" aria-label="View">open_in_full</mat-icon>
      </button>
      <div class="phone-body">{{phone}}</div>
      <button mat-icon-button (click)="clear()">
        <mat-icon aria-hidden="false" aria-label="View">delete_outline</mat-icon>
      </button>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="isAgendaPage; else searchPage">

</ng-container>

<ng-template #searchPage>
  <form [formGroup]="form" (submit)="submitForm()">
    <mat-form-field class="full-width">
      <input formControlName="phone" matInput placeholder="{{'phone' | translate}}">
    </mat-form-field>
    <mat-form-field class="full-width">
      <input formControlName="email" matInput placeholder="{{'email' | translate}}">
    </mat-form-field>
    <button type="submit" mat-button [disabled]="!form.valid">{{'submit' | translate}}</button>
  </form>

  <div *ngIf="phone">
    <apfr-event-list-table [payload]="payload" (copied)="onCopy()"></apfr-event-list-table>
  </div>
</ng-template>
