<div *ngIf="timeSlot" class="free-time"
     [ngClass]="{'hovered': hovered}"
     [ngStyle]="styles"
>
  <label>{{ timeSlot.startTime }}</label>
  <div *ngIf="timeSlot.promotion"
         class="promotion"
         [ngStyle]="{'background-color': timeSlot.promotion.bgcolor}"
  >
    {{ timeSlot.promotion.price - timeSlot.promotion.promoPrice }} €
  </div>
</div>
