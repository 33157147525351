import moment, { Moment } from 'moment';
import { flow, groupBy, head, sortBy } from 'lodash/fp';
import { AgendaViewTypeEnum, Event as EventModel, Resource } from '~/shared/models';
import { BASE_TIME_FORMAT, concatDateTime, DATE_FORMAT } from '~/framework';
import { ColorModeEnum } from '~/shared/models/agenda/color-mode.enum';
import { BusyTime, CalendarRuleObject, FreeTime, Time, Times } from '~/+calendar/shared/calendar.service';
import { EventStack } from '~/shared/models/events.model';
import { reduce } from 'lodash';
// to have access to index in object
const map = require('lodash/fp/map')
  .convert({'cap': false});
const mapValuesWithKey = require('lodash/fp/mapValues')
  .convert({'cap': false});

export const defaultCellHeight = 40;
export const minColumnWidth = 140;
export const BREAKPOINT_NUMBER_OF_DAYS = 3;
export const WEEK_VIEW_DAYS = 7;
export const DRAG_DIFFERENCE_START_PX = 5;
export const DAY_VIEW_DAYS = 1;

export interface ReorganizeEvents {
  startTime: string;
  overlapCount: number;
  events: Array<EventModel>;
}

export interface ColumnIndexesKeys extends ColumnKeys {
  index: number;
  maxColIndex: number;
}
export interface ColumnKeys {
  secondaryColumnKey: string;
  mainColumnKey: string | null;
}

export interface PasteDropEventParams {
  keys: ColumnKeys;
  event: EventModel;
  time: string;
}

export interface CheckWindowAndContentWidthParams {
  windowWidth: number;
  contentWidth: number;
  columnCount: number;
}

export interface AgendaFreeTimeSlot extends Time {
  startTime: string;
}

export function isNotInPastEvent(event: EventModel): boolean {
  return isNotInPast(moment.unix(event.startTime));
}

export function isNotInPast(time: moment.Moment): boolean {
  return time >= moment()
    .startOf('day');
}

/**
 * reorganizeGroupedEventsDay
 *
 * {
 *   2019-01-01: {
 *     1779: [
 *       {
 *         startTime: '8:00',
 *         overlapCount: 1,
 *         events: [Event1, Event2]
 *       }
 *     ]
 *   }
 * }
 *
 */
export function reorganizeGroupedEventsDay(
  eventResource: Array<EventModel>,
  groupByResource: boolean
) {
  const result: {
    [key: string]: any
  } = {};

  flow(
    groupBy((event: EventModel) => moment.unix(event.startTime)
      .format(DATE_FORMAT)),
    mapValuesWithKey((value, key) => {
      result[key] = flow(
        groupBy((item: EventModel) => {
          if (groupByResource) {
            return item.resource.parent.id;
          } else {
            return item.resource.id;
          }
        }),
        mapValuesWithKey((v: Array<EventModel>) => {
          return reorganizeEventsArray(v);
        })
      )(value);
    })
  )(eventResource);

  return result;
}

export function reorganizeGroupedFreeTime(
  freeTime: FreeTime,
  groupByResource: boolean
) {
  const result: {
    [key: string]: any
  } = {};

  mapValuesWithKey((times: Times, day: string) => {
    const slotsArray: AgendaFreeTimeSlot[] = mapValuesWithKey((timeObject: Time, startTime: string) => {
      return {
        startTime,
        ...timeObject
      };
    })(times);
    result[day] = reduce(slotsArray, (res, slot) => {
      const groupedArrayProperty = groupByResource ? slot.institutions : slot.resources;

      return reduce(groupedArrayProperty, (acc, id) => {
        const stringId = id.toString();
        (acc[stringId] || (acc[stringId] = [])).push(slot);
        return acc;
      }, res);
    }, {});

  })(freeTime);

  return result;
}

export function reorganizeGroupedBusyTime(
  busyTime: BusyTime,
  groupByResource: boolean
) {
  const result: {
    [key: string]: any
  } = {};

  mapValuesWithKey((calendarRules: CalendarRuleObject[], day: string) => {

    result[day] = reduce(calendarRules, (res, calendarRule: CalendarRuleObject) => {
      const groupedArrayProperty = groupByResource ? calendarRule.institutions : calendarRule.resources;

      return reduce(groupedArrayProperty, (acc, id) => {
        const stringId = id.toString();
        (acc[stringId] || (acc[stringId] = [])).push(calendarRule);
        return acc;
      }, res);
    }, {});

  })(busyTime);

  return result;
}


/**
 * reorganizeGroupedEventsWeek
 *
 * {
 *   21/12/2018-28/12/2018: [
 *     {
 *       startTime: '8:00',
 *       overlapCount: 1,
 *       events: [Event1, Event2]
 *     }
 *   ]
 * }
 */
export function reorganizeGroupedEventsWeek(eventResource: Array<EventModel>) {
  return reorganizeEventsArray(eventResource);
}

/**
 * Group the same events into `ReorganizeEvents` object (same startTime and endTime) or simple EventModel
 *
 * Example:
 * <pre>
 * [
 *  {
 *    id: 123,
 *    startTime: 123,
 *    endTime: 234,
 *    ...
 *  },
 *  {
 *    overlapCount: 2,
 *    events: [event, event],
 *    startTime: 123,
 *    endTime: 234,
 *    ...
 *  }
 * ]
 * </pre>
 */
export function reorganizeEventsArray(value: Array<EventModel>): EventStack[] {
  const intersectedEvents = getIntersectedEvents(value);
  return intersectedEvents.map(event => {
    return flow(
      /**
       * Group all the events by combination of startTime and endTime with dash
       * "1636027200-1636029000": [...events]
       */
      groupBy((i: EventModel) => `${i.startTime}-${i.endTime}`),
      map((events: Array<EventModel | ReorganizeEvents>, startTime: string) => {
        // there is more than 1 events, so we have grouped events
        if (events.length > 1) {
          // @ts-ignore
          return {
            startTime: +startTime.split('-')[0],
            endTime: +startTime.split('-')[1],
            events,
            overlapCount: events.length,
            eventGroup: new EventStack([])
          } as ReorganizeEvents;
        }
        // there is single event, so we take it as-is
        return events[0] as EventModel;
      }),
      sortBy((item: any) => item.id)
    )(event);
  })
    .map(events => new EventStack(events));
}


export function groupedEventsByDay(eventResource: Array<EventModel>) {
  const result: {
    [key: string]: any
  } = {};

  flow(
    groupBy((event: any) => moment.unix(event.startTime)
      .format(DATE_FORMAT)),
    mapValuesWithKey((value, key) => {
      result[key] = reorganizeEventsArray(value);
    })
  )(eventResource);

  return result;
}

export function groupResources(resources: Array<Resource>) {
  return flow(
    groupBy((cur: Resource) => cur.parent.id),
    map((_resources: Array<Resource>, parentId: string) => {
      return {
        institutionId: parentId,
        name: head(_resources).parent.name,
        resources: _resources
      };
    })
  )(resources);
}

/**
 * calculateStartPosition
 *
 * Calculate Start Position of Event based on duration between startAgenda and startEvent.
 * We have to know for how many time-slots we should move this event.
 *
 * @example
 * startEvent = 10:00
 * startAgenda = 8:00
 * duration = 120 minutes
 * intervalAgenda = 30
 *
 * cellHeight = 40px;
 *
 * howManySlots = duration / intervalAgenda (4)
 * howManySlots * cellHeight = 160px
 *
 * So star position of event is 160px ( top: 160px )
 */
export function calculateStartPosition(
  startEvent: number,
  startAgenda: string,
  intervalAgenda: number,
  cellHeight: number
): number {
  const dateTime = concatDateTime(moment(), startAgenda);

  const startTime = concatDateTime(moment(),
    moment.unix(startEvent)
      .format(BASE_TIME_FORMAT)
  );

  const duration = Math.abs(
    moment.duration(
      moment(dateTime)
        .diff(startTime)
    )
      .as('minutes')
  );

  return duration / intervalAgenda * cellHeight;
}

/**
 * calculateHeight
 *
 * Calculate Height of Event based on duration between startEvent and endEvent
 * Calculation pretty the same as we have {@link [calculateStartPosition]}
 * We have to know how many time-slots this event takes
 * We should subtract 2px for having a gap between events
 */
export function calculateHeight(
  startEvent: number,
  endEvent: number,
  intervalAgenda: number,
  cellHeight: number
): number {
  const duration = Math.abs(
    moment.duration(
      moment.unix(startEvent)
        .diff(moment.unix(endEvent))
    )
      .as('minutes')
  );

  return duration / intervalAgenda * cellHeight - 1;
}

export function calculateMaxDayAmount(
  {minWidthOfDay, isShowFormPanel, isMobile}:
    { minWidthOfDay: number, isShowFormPanel: boolean, isMobile: boolean }): number {
  let windowWidth = window.innerWidth;

  if (isShowFormPanel && !isMobile) {
    const formEl = document.querySelector('.agenda-form');
    if (formEl) {
      windowWidth -= formEl.clientWidth;
    }
  }

  const maxDayAmount = Math.floor(windowWidth / minWidthOfDay);

  return maxDayAmount !== 0 ? maxDayAmount : 1; // it should be at least 1 day(event if we have a lot of resources)
}

export function calculateHowManyColumnsAvailable(): number {
  return Math.floor((window.innerWidth) / minColumnWidth);
}

export function calculateMinWidthOfDay(secondaryColumnsCount: number): number {
  let minWidthOfDay = secondaryColumnsCount * minColumnWidth;

  if (minWidthOfDay < minColumnWidth) {
    minWidthOfDay = minColumnWidth;
  }

  return minWidthOfDay;
}

export function calculateNearestTime(startTime: Moment, intervalAgenda: number, isEndTime = false): string {
  const time = startTime.clone();

  const minutes = time.minute() % intervalAgenda;
  return time.subtract(isEndTime && minutes === 0 ? intervalAgenda : minutes, 'minutes')
    .format(BASE_TIME_FORMAT);
}

/**
 * calculateMaxHeight
 *
 * Calculate max Height of Agenda.
 * Agenda view have to fit to the user screen height
 */
export function calculateMaxHeight(scrollableEl: HTMLElement, filterHeight: number): number {
  const {top} = scrollableEl.getBoundingClientRect();

  return Math.abs(window.innerHeight - top - filterHeight);
}

export function getNewEventStartTime(
  newDate: string,
  newTime: string,
  oldEventStartTimestamp: number,
  intervalAgenda: number,
  startAgenda: string
): Moment {
  const eventStartTime = moment.unix(oldEventStartTimestamp);

  const startAgendaTime = concatDateTime(
    eventStartTime.clone(),
    startAgenda
  );
  const duration = moment.duration(
    moment(eventStartTime)
      .diff(startAgendaTime)
  )
    .as('minutes');
  const divisionRemainder = duration % intervalAgenda;

  const startTime = concatDateTime(
    moment(newDate, DATE_FORMAT),
    newTime
  );

  // just in case if event start is not the same as available freeTime(because of different slotDuration)
  startTime.add(divisionRemainder, 'minutes');

  return startTime;
}

export function setStyleToEvent(
  event: EventModel,
  startAgenda: string,
  intervalAgenda: number,
  cellHeight: number,
  viewType: AgendaViewTypeEnum,
  colorMode: ColorModeEnum = ColorModeEnum.Type
) {
  const backgroundColor = colorMode === ColorModeEnum.Type ? event.eventType.color : event?.creator?.profile?.color;

  return {
    top: `${calculateStartPosition(event ? event.startTime : null, startAgenda, intervalAgenda, cellHeight)}px`,
    height: `${calculateHeight(event ? event.startTime : null, event ? event.endTime : null, intervalAgenda, cellHeight)}px`,
    'background-color': backgroundColor
  };
}

export function timelineLabels(desiredStartTime, endTime, interval, period): Array<string> {
  const periodsInADay = moment.duration(1, 'day')
    .as(period);

  const timeLabels = [];
  const startTimeMoment = moment(desiredStartTime, BASE_TIME_FORMAT);
  const endTimeMoment = moment(endTime, BASE_TIME_FORMAT);

  for (let i = 0; i <= periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);
    if (startTimeMoment <= endTimeMoment) {
      timeLabels.push(startTimeMoment.format(BASE_TIME_FORMAT));
    }
  }

  return timeLabels;
}

export function getIntersectedEvents(events: EventModel[]): Array<EventModel[]> {
  const sortedEvents = sortBy((item: EventModel) => item.startTime, events);
  // id
  /**
   * External carry array of event groups
   */
  const groups = [];

  for (let i = 0; i <= sortedEvents.length;) {
    if (!sortedEvents[i]) {
      break;
    }
    /**
     * Payload is current group context
     */
    let payload = null;
    /**
     * Internal group array of events
     */
    const carry = [];
    /**
     * Event will create a group anyway, even if it won't intersect anything
     */
    carry.push(sortedEvents[i]);
    do {
      // memorize current event time context
      const {startTime, endTime} = sortedEvents[i];
      payload = [startTime, endTime];

      i++;

      /**
       * If we has intersection,
       * we add current event (in group) to internal array and repeat the cycle
       */
      if (sortedEvents[i]) {
        payload = hasIntersection(payload, sortedEvents[i]);
        if (payload) {
          carry.push(sortedEvents[i]);
        }
      } else {
        payload = null;
      }

    } while (payload);

    // it was last event in group,
    // we add internal group to external and repeat `for` cycle to create new group
    groups.push(carry);
  }

  return groups;
}

/**
 * Helper function for {@link getIntersectedEvents}
 */
function hasIntersection([start, end]: [number, number], event: EventModel): [number, number] | null {
  if (start <= event.startTime && end > event.startTime) {
    end = event.endTime > end ? event.endTime : end;
  } else {
    return null;
  }

  return [start, end];
}

