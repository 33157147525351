<mat-list>
  <div class="group">
    <mat-list-item>
      <span>
        {{ 'auto_planning' | translate }}
      </span>
      <span class="spacer"></span>
      <mat-icon class="icon-no-margin" [matMenuTriggerFor]="menu">person</mat-icon>
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="currentUser; else notSignIn">
          <button mat-menu-item [routerLink]="['/settings']">{{ currentUser.username }}</button>
        </ng-container>
        <ng-template #notSignIn>
          <button mat-menu-item [routerLink]="['/auth/sign-in']">
            {{ 'sign_in' | translate }}
            <mat-icon class="icon pointer">perm_identity</mat-icon>
          </button>
        </ng-template>
      </mat-menu>
      <mat-icon (click)="signOutEvent()" class="icon pointer logoutIcon">exit_to_app</mat-icon>
    </mat-list-item>
  </div>
  <div class="group">
    <mat-list-item (click)="isOpenSearch = !isOpenSearch">
      <mat-icon>search</mat-icon>{{ 'search' | translate }}
    </mat-list-item>
    <ng-container *ngIf="isOpenSearch">
      <mat-list-item [routerLink]="['search/search-by-institution']" routerLinkActive="active" (click)="sidenav.close()">
        {{ 'search_by_institution' | translate }}
      </mat-list-item>
      <mat-list-item [routerLink]="['search/search-by-phone']" routerLinkActive="active" (click)="sidenav.close()">
        {{ 'search_by_phone' | translate }}
      </mat-list-item>
      <mat-list-item [routerLink]="['search/search-by-reference']" routerLinkActive="active" (click)="sidenav.close()">
        {{ 'search_by_reference' | translate }}
      </mat-list-item>
    </ng-container>
  </div>
  <div *ngIf="(isMobile$ | async)" class="group">
    <mat-list-item [routerLink]="['dashboard']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>home</mat-icon>{{ 'dashboard.navigation' | translate }}
    </mat-list-item>
  </div>
  <div class="group">
    <mat-list-item [routerLink]="['calendar']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>insert_invitation</mat-icon>{{ 'calendar' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['calendar-rule']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>calendar_view_day</mat-icon>{{ 'calendar_rule.title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['team']" routerLinkActive="active">
      <mat-icon>people</mat-icon>{{ 'team' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['customers']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>perm_contact_calendar</mat-icon>{{ 'customers' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['statistics']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>show_chart</mat-icon>{{ 'statistics' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['adwords']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>font_download</mat-icon>{{ 'adwords' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['form/entity-event-form']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>subtitles</mat-icon>{{ 'menu.form.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['jobs']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>play_circle_outline</mat-icon>{{ 'menu.job.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['sms']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>sms</mat-icon>{{ 'menu.sms.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['products']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>shopping_cart</mat-icon>{{ 'menu.products.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['orders']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>list_alt</mat-icon>{{ 'menu.orders.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['promotions']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>handshake</mat-icon>{{ 'menu.promotions.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['notifications']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>notification_important</mat-icon>{{ 'menu.notifications.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['partner-access']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>group_work</mat-icon>{{ 'menu.partnerAccess.main_title' | translate }}
    </mat-list-item>
    <mat-list-item [routerLink]="['workflow-rules']" routerLinkActive="active" (click)="sidenav.close()">
      <mat-icon>event_note</mat-icon>{{ 'menu.workflow-rules.main_title' | translate }}
    </mat-list-item>
    <div class="group">
      <mat-list-item (click)="isOpenConfiguration = !isOpenConfiguration">
        <mat-icon>work</mat-icon>{{ 'configuration' | translate }}
      </mat-list-item>
      <ng-container *ngIf="isOpenConfiguration">
        <mat-list-item [routerLink]="['configuration/criterion']" routerLinkActive="active" (click)="sidenav.close()">
          {{ 'criterion' | translate }}
        </mat-list-item>
        <mat-list-item [routerLink]="['configuration/entity-criterion']" routerLinkActive="active" (click)="sidenav.close()">
          {{ 'entity_criterion' | translate }}
        </mat-list-item>
        <mat-list-item [routerLink]="['configuration/service']" routerLinkActive="active" (click)="sidenav.close()">
          {{ 'configuration_menu.service' | translate }}
        </mat-list-item>
        <mat-list-item [routerLink]="['configuration/service-option']" routerLinkActive="active" (click)="sidenav.close()">
          {{ 'configuration_menu.service-option' | translate }}
        </mat-list-item>
        <mat-list-item [routerLink]="['configuration/service-stepper']" routerLinkActive="active" (click)="sidenav.close()">
          {{ 'configuration_menu.service-stepper' | translate }}
        </mat-list-item>
        <mat-list-item [routerLink]="['configuration/step']" routerLinkActive="active" (click)="sidenav.close()">
          {{ 'configuration_menu.step' | translate }}
        </mat-list-item>
        <mat-list-item [routerLink]="['configuration/step-criterion']" routerLinkActive="active" (click)="sidenav.close()">
          {{ 'configuration_menu.step_criterion' | translate }}
        </mat-list-item>
      </ng-container>
    </div>
  </div>

  <!-- External URL -->
  <div class="group" *ngFor="let group of keysFrom(externalMenuLinks)">
    <div class="label" (click)="toggleExpanded(group)">
      {{ group }}
      <mat-icon [@indicatorRotate]="externalMenuLinks[group].expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>
    </div>
    <ng-container *ngIf="externalMenuLinks[group].expanded">
      <mat-list-item *ngFor="let item of externalMenuLinks[group].items"
                     [routerLink]="['external', item.slug]"
                     routerLinkActive="active"
                     (click)="sidenav.close()">
        {{ item.name }}
      </mat-list-item>
    </ng-container>
  </div>

  <div class="spacer"></div>
  <div class="group" *ngIf="isMobileAgent && isMobile">
    <mat-list-item (click)="desktopMode(); sidenav.close()">
      <mat-icon>desktop_windows</mat-icon>{{ 'desktop_view' | translate }}
    </mat-list-item>
  </div>
  <div class="group" *ngIf="isMobileAgent && !isMobile">
    <mat-list-item (click)="mobileMode(); sidenav.close()">
      <mat-icon>mobile_friendly</mat-icon>{{ 'mobile_view' | translate }}
    </mat-list-item>
  </div>
</mat-list>
