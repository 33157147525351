import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filterText: string, filterField: string = 'text'): any[] {
    if (!items) {
      return [];
    }
    if (!filterText) {
      return items;
    }

    filterText = filterText.toLowerCase();

    return items.filter((cur) =>
      cur[filterField].toLowerCase()
        .includes(filterText));
  }
}
