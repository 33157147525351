import { Action } from '@ngrx/store';
import { CustomerModel } from '~/shared/models';

export enum CustomerActionTypes {
  GetAllCustomers = '[Customer] Get All Customers',
  GetAllCustomersFail = '[Customer] Get All Customers Fail',
  GetAllCustomersSuccess = '[Customer] Get All Customers Success',

  GetOneCustomer = '[Customer] Get One Customer',
  GetOneCustomerFail = '[Customer] Get One Customer Fail',
  GetOneCustomerSuccess = '[Customer] Get One Customer Success',

  AddOneCustomer = '[Customer] Add One',
  AddOneCustomerSuccess = '[Customer] Add One Success',
  AddOneCustomerFail = '[Customer] Add One Fail',

  DeleteOneCustomer = '[Customer] Delete One',
  DeleteOneCustomerSuccess = '[Customer] Delete One Success',
  DeleteOneCustomerFail = '[Customer] Delete One Fail',

  GetCustomersFromDB = '[Customer] Get FromDB',
  SetCustomersToDB = '[Customer] Set to DB',
  SetCustomersFromDB = '[Customer] Set from DB'
}

export class GetAllCustomers implements Action {
  readonly type = CustomerActionTypes.GetAllCustomers;

  constructor(public payload: { page: number, itemsPerPage: number, search: string }) {
  }
}

export class GetAllCustomersFail implements Action {
  readonly type = CustomerActionTypes.GetAllCustomersFail;

  constructor(public payload: any) {
  }
}

export class GetAllCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.GetAllCustomersSuccess;

  constructor(public payload: Array<CustomerModel>) {
  }
}

export class GetOneCustomer implements Action {
  readonly type = CustomerActionTypes.GetOneCustomer;

  constructor(public payload: string) {
  }
}

export class GetOneCustomerFail implements Action {
  readonly type = CustomerActionTypes.GetOneCustomerFail;

  constructor(public payload: any) {
  }
}

export class GetOneCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.GetOneCustomerSuccess;

  constructor(public payload: CustomerModel) {
  }
}

export class AddOneCustomer implements Action {
  readonly type = CustomerActionTypes.AddOneCustomer;

  constructor(public payload: CustomerModel) {
  }
}

export class AddOneCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.AddOneCustomerSuccess;

  constructor(public payload: CustomerModel) {
  }
}

export class AddOneCustomerFail implements Action {
  readonly type = CustomerActionTypes.AddOneCustomerFail;

  constructor(public payload: any) {
  }
}

export class DeleteOneCustomer implements Action {
  readonly type = CustomerActionTypes.DeleteOneCustomer;

  constructor(public payload: string) {
  }
}

export class DeleteOneCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.DeleteOneCustomerSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteOneCustomerFail implements Action {
  readonly type = CustomerActionTypes.DeleteOneCustomerFail;

  constructor(public payload: any) {
  }
}

export class GetCustomersFromDB implements Action {
  readonly type = CustomerActionTypes.GetCustomersFromDB;
}

export class SetCustomersToDB implements Action {
  readonly type = CustomerActionTypes.SetCustomersToDB;

  constructor(public payload: Array<CustomerModel>) {
  }
}

export class SetCustomersFromDB implements Action {
  readonly type = CustomerActionTypes.SetCustomersFromDB;

  constructor(public payload: Array<CustomerModel>) {
  }
}

export type CustomerActions =
  GetAllCustomers |
  GetAllCustomersFail |
  GetAllCustomersSuccess |
  GetOneCustomer |
  GetOneCustomerFail |
  GetOneCustomerSuccess |
  AddOneCustomer |
  AddOneCustomerFail |
  AddOneCustomerSuccess |
  DeleteOneCustomer |
  DeleteOneCustomerFail |
  DeleteOneCustomerSuccess |
  GetCustomersFromDB |
  SetCustomersToDB |
  SetCustomersFromDB;
