<ng-container *ngIf="event">
  <div>
    <span *ngIf="event.contactName">{{ event.contactName }}</span>
    <span *ngIf="event.contactTel">
    &nbsp;-&nbsp;
    <a class="link-tel" href="tel:{{ event.contactTel }}">
      {{ event.contactTel }}
    </a>
  </span>
  </div>
</ng-container>
