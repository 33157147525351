import { FieldConfig } from '~/shared/models';

export const CUSTOMER_FORM = 'customer_form';

export interface CustomerFormState {
  fields: Array<FieldConfig>;
  isLoading: boolean;
  error: any;
}

export const initialCustomerFormState = {
  fields: [],
  isLoading: undefined,
  error: undefined
};
