import { NotificationModel } from '~/shared/models';

export const NOTIFICATIONS = 'notifications';

export interface NotificationState {
  notification: NotificationModel;
}

export const initialNotificationState: NotificationState = {
  notification: undefined
};
