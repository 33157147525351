import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DatabaseService } from '~/framework/database/database.service';
import { BaseService } from '~/framework/core/src/base.service';
// @ts-ignore
import { UserExtraDataModel } from '~/shared/models';

@Injectable({
  providedIn: 'root'
})
export class UserEntitiesService extends BaseService {

  constructor(protected http: HttpClient,
              private databaseService: DatabaseService) {
    super(http);
  }

  /**
   * getUserEntities
   *
   * Send request to server if userData is not exist on the indexedDB
   */
  getUserEntities(): Observable<UserExtraDataModel> {
    return this.databaseService
      .getUserData()
      .pipe(
        switchMap(userData => {
          if (userData) {
            return of(userData);
          } else {
            return this.http.get<UserExtraDataModel>(`${this.apiUrl}/sf_guard_users/entities`)
              .pipe(map(entityList => {
                this.databaseService.setUserData(entityList);

                return entityList;
              }));
          }
        })
      );
  }
}
