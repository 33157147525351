<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav" mode="over" fixedInViewport="true">
    <apfr-sidenav-menu [sidenav]="sidenav"
                       [externalMenuLinks]="externalMenuLinks"
                       [isMobile]="(isMobile$ | async)"
                       [isMobileAgent]="isMobileAgent"
                       [currentUser]="(currentUser$ | async)"
                       (signOut)="signOut()">
    </apfr-sidenav-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="main-container">
      <apfr-header [sidenav]="sidenav"
                   [currentPagePortal]="currentPagePortal">
      </apfr-header>

      <div class="content"
           [ngClass]="'content-' + routeName.url.split(
           routeName.url.includes('?') ? '?' : '#'
           )[0].replace(regExp, '')">
        <apfr-notification-wrapper></apfr-notification-wrapper>
        <mat-spinner *ngIf="isResolverLoading$ | async"></mat-spinner>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<iframe #iframe [src]="iframeSrc" class="hidden-iframe"></iframe>
