import { TokenService } from '~/shared/services';
import { LocalStorageLabels } from '~/shared/models';
import { UserSettings } from '~/store/user-settings';

export interface UserTokenModel {
  first_name: string;
  last_name: string;
  roles: string[];
  username: string;
  exp: number;
  ip: string;
  id: number;
}

export class UserTokenData {
  constructor(
    public firstName: UserTokenModel['first_name'],
    public lastName: UserTokenModel['last_name'],
    public username: UserTokenModel['username'],
    public roles: UserTokenModel['roles'],
    public exp: UserTokenModel['exp'],
    public ip: UserTokenModel['ip'],
    public id: UserTokenModel['id']
  ) {}

  static fromToken() {
    const userTokenModel = TokenService.getUserInfoFromToken();

    return new UserTokenData(
      userTokenModel.first_name,
      userTokenModel.last_name,
      userTokenModel.username,
      userTokenModel.roles,
      userTokenModel.exp,
      userTokenModel.ip,
      userTokenModel.id
    );
  }
}

export interface CurrentUserDTO {
  id: number;
  default_entity: number;
  institution: CurrentUserInstitution | null;
  company_name: string;
  corporate_email: string;
  gender: string;
  context: CurrentUserContext;
}

export interface CurrentUserInstitution {
  id: string;
  name: string;
}

export interface CurrentUserContext extends UserSettings {
  mobilePlanningView: string;
  howManyDaysDisplay: number;
  isTimeLabelDisplay: boolean;
  isFormSticky: boolean;
}

export class CurrentUser {
  constructor(
    public id: number,
    public institution: CurrentUserInstitution | null,
    public companyName: string,
    public corporateEmail: string,
    public gender: string,
    public context: CurrentUserContext
  ) {}

  static fromDto(dto: CurrentUserDTO) {
    // save default institution_id to localStorage
    if (!localStorage.getItem(LocalStorageLabels.selectedInstitution)) {
      localStorage.setItem(LocalStorageLabels.selectedInstitution, dto.default_entity.toString());
    }
    const context = {
      mobilePlanningView: dto.context.mobilePlanningView,
      howManyDaysDisplay: dto.context.howManyDaysDisplay,
      isTimeLabelDisplay: dto.context.isTimeLabelDisplay,
      isFormSticky: dto.context.isFormSticky,
      eventNotification: dto.context.eventNotification
    };

    return new CurrentUser(
      dto.id,
      dto.institution,
      dto.company_name,
      dto.corporate_email,
      dto.gender,
      context
    );
  }
}
