import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MaterialModule, SharedModule } from '~/framework';

import { SnackBarComponent } from './snack-bar.component';

@NgModule({
  imports: [
    SharedModule,
    MatSnackBarModule,
    MaterialModule
  ],
  exports: [
    MatSnackBarModule,
    SnackBarComponent
  ],
  declarations: [
    SnackBarComponent
  ]
})
export class SnackBarModule { }
