import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { entityErrorFn, entityStartLoadingFn, entityStopLoadingFn } from '~/store/entity-reducer.utils';

import { CurrentUserState, CURRENT_USER, initialCurrentUserState } from './current-user.state';
import * as CurrentUserActions from './current-user.actions';


export const currentUserReducer = createReducer<CurrentUserState>(
  initialCurrentUserState,
  on(CurrentUserActions.loadUser, (state) => entityStartLoadingFn(state)),
  on(CurrentUserActions.loadUserSuccess, (state, {currentUser}) => {
    return entityStopLoadingFn({...state, currentUser});
  }),
  on(CurrentUserActions.loadUserFail, (state, {error}) => {
    return entityErrorFn(state, error);
  }),
  on(CurrentUserActions.setUserFromToken, (state, {userTokenData}) => {
    return entityStopLoadingFn({...state, userTokenData});
  }),
  on(CurrentUserActions.removeCurrentUser, (state) => {
    return {
      ...state,
      userTokenData: initialCurrentUserState.userTokenData,
      currentUser: initialCurrentUserState.currentUser
    };
  })
);

export function currentUserReducerFn(state: CurrentUserState, action: Action) {
  return currentUserReducer(state, action);
}

const selectCurrentUserState = createFeatureSelector<CurrentUserState>(CURRENT_USER);

export const selectCurrentUser = createSelector(
  selectCurrentUserState,
  (state) => state.userTokenData
);
