import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment, { Moment } from 'moment';

import { BaseComponent, FromStore } from '~/framework';
import {
  getDisplayTimeLabel,
  getMaxDayAmount,
  getNumberOfDays, getSelectedDate,
  getViewType, SetAgendaSettings
} from '~/store/agenda-view';
import { selectIsMobile } from '~/store/config-app';

import { AgendaViewTypeEnum } from '~/shared/models';
import { DatepickerHeaderComponent } from '~/shared/components/datepicker-header';

@Component({
  templateUrl: './agenda-settings-dialog.component.html',
  styleUrls: ['./agenda-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaSettingsDialogComponent extends BaseComponent implements OnInit, FromStore {
  readonly AgendaViewTypeEnum = AgendaViewTypeEnum;

  isMobile$: Observable<boolean>;

  dayAmount: Array<number> = [];
  viewType: AgendaViewTypeEnum;

  numberOfDaysValue: number;
  dateValue: moment.Moment;
  displayTimeLabel: boolean;
  currentSelectedDate: moment.Moment;

  datepickerHeaderComponent = DatepickerHeaderComponent;

  currentDateSelection = (date: moment.Moment) => {
    if (this.currentSelectedDate && this.currentSelectedDate.isSame(date, 'day')) {
      return 'currentSelectedDate';
    }
  };

  constructor(public dialogRef: MatDialogRef<AgendaSettingsDialogComponent>,
              private readonly store: Store<any>) {
    super();
  }

  ngOnInit() {
    this.getDataFromStore();
  }

  getDataFromStore(): void {
    this.isMobile$ = this.store
      .pipe(select(selectIsMobile));

    this.store
      .pipe(
        select(getNumberOfDays),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((numberOfDays) => {
        this.numberOfDaysValue = numberOfDays;
      });

    this.store
      .pipe(
        select(getMaxDayAmount),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((maxDayAmount) => {
        this.dayAmount = [];

        for (let i = 1; i <= maxDayAmount; i ++) {
          this.dayAmount.push(i);
        }
      });

    this.store
      .pipe(
        select(getViewType),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((viewType: AgendaViewTypeEnum) => {
        this.viewType = viewType;
      });

    this.store
      .pipe(
        select(getSelectedDate),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((selectedDate: Moment) => {
        this.dateValue = selectedDate;
        this.currentSelectedDate = selectedDate;
      });

    this.store
      .pipe(
        select(getDisplayTimeLabel),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((displayTimeLabel) => {
        this.displayTimeLabel = displayTimeLabel;
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  numberOfDaysChangeEvent(selected: number) {
    this.numberOfDaysValue = selected;
  }

  selectedDateChange(date: moment.Moment) {
    this.dateValue = date;
  }

  viewTypeChange(checked: boolean) {
    if (checked) {
      this.viewType = AgendaViewTypeEnum.WeekView;
    } else {
      this.viewType = AgendaViewTypeEnum.DayView;
    }
  }

  displayTimeLabelChange(checked: boolean) {
    this.displayTimeLabel = checked;
  }

  applySettings() {
    const agendaSettings = {
      numberOfDays: this.numberOfDaysValue,
      selectedDate: this.dateValue,
      viewType: this.viewType,
      displayTimeLabel: this.displayTimeLabel
    };

    this.store.dispatch(new SetAgendaSettings({agendaSettings}));
    this.dialogRef.close();
  }

}
