import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { NOTES } from './note.state';
import { noteReducer } from './note.reducers';
import { NoteEffects } from './note.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTES, noteReducer),
    EffectsModule.forFeature([NoteEffects])
  ]
})
export class NoteStateModule {
}
