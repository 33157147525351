import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

/**
 * SnackBar component
 *
 * Simple SnackBar that copies the behavior of MatSnackBar, but has translate pipe
 */
@Component({
  selector: 'apfr-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {

  constructor(public snackBarRef: MatSnackBarRef<SnackBarComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {}


  /** Performs the action on the snack bar. */
  action(): void {
    this.snackBarRef.dismissWithAction();
  }

  /** If the action button should be shown. */
  get hasAction(): boolean {
    return !!this.data.action;
  }

  get hasCloseAction(): boolean {
    return !!this.data?.close_action;
  }

  close(): void {
    this.snackBarRef.dismiss();
  }

}
