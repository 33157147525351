import { Action } from '@ngrx/store';
import { FieldConfig } from '~/shared/models';

export enum AppointmentFormActionTypes {
  LoadForm = '[Appointment Form] Load Form',
  LoadFormSuccess = '[Appointment Form] Load Form Success',
  LoadFormFail = '[Appointment Form] Load Form Fail'
}

export class LoadForm implements Action {
  readonly type = AppointmentFormActionTypes.LoadForm;
}

export class LoadFormSuccess implements Action {
  readonly type = AppointmentFormActionTypes.LoadFormSuccess;

  constructor(public payload: Array<FieldConfig>) {
  }
}

export class LoadFormFail implements Action {
  readonly type = AppointmentFormActionTypes.LoadFormFail;

  constructor(public payload: any) {
  }
}

export type AppointmentFormActions =
  LoadForm |
  LoadFormSuccess |
  LoadFormFail;
