import { Pipe, PipeTransform } from '@angular/core';
import { TimeagoPipe } from 'ngx-timeago';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoExtendsPipe extends TimeagoPipe implements PipeTransform {
  transform(value: string): string {
    return super.transform(value);
  }
}
