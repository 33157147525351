import { Component, Input, OnInit } from '@angular/core';
import { CalendarRuleObject } from '~/+calendar/shared/calendar.service';
import { calculateHeight, calculateStartPosition } from '~/shared/components/agenda/agenda.util';
import moment from 'moment';
import { BaseComponent, BASE_TIME_FORMAT, DATE_FORMAT } from '~/framework';
import { Store } from '@ngrx/store';
import {
  CalendarRuleState,
  createCalendarRuleSuccess,
  deleteCalendarRuleSuccess,
  updateCalendarRuleSuccess
} from '~/store/calendar-rule';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { CalendarRuleModalService, ICalendarRule } from '@apfr/components/calendar-rule-modal';

@Component({
  selector: 'apfr-agenda-busy-time',
  templateUrl: './agenda-busy-time.component.html',
  styleUrls: ['./agenda-busy-time.component.scss']
})
export class AgendaBusyTimeComponent extends BaseComponent implements OnInit {
  @Input()
  busyTime: CalendarRuleObject;

  @Input()
  startAgenda: string;
  @Input()
  day: string;

  @Input()
  cellHeight: number;

  @Input()
  intervalAgenda: number;

  @Input()
  isMobile: boolean;
  @Input()
  isEnabledSelection: boolean;

  styles: Object;

  constructor(
    protected readonly store: Store<CalendarRuleState>,
    protected readonly dialog: MatDialog,
    protected readonly calendarRuleModalService: CalendarRuleModalService
  ) {
    super();
  }

  ngOnInit(): void {
    const start = moment(this.busyTime.start, BASE_TIME_FORMAT)
      .unix();
    const end = moment(this.busyTime.end, BASE_TIME_FORMAT)
      .add(this.busyTime.end === '00:00' ? 1 : 0, 'day')
      .unix();
    this.styles = {
      top: `${calculateStartPosition(start, this.startAgenda, this.intervalAgenda, this.cellHeight)}px`,
      height: `${calculateHeight(start, end, this.intervalAgenda, this.cellHeight)}px`,
      'background-color': this.busyTime.rule.color
    };
  }

  edit() {
    this.calendarRuleModalService.openUpdateDialogWithModes(
      {
        rule: this.busyTime.rule,
        day: moment.utc(this.day, DATE_FORMAT)
      }
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([updatedRule, cratedRule]: [ICalendarRule, ICalendarRule]) => {
        this.store.dispatch(updateCalendarRuleSuccess({selectedCalendarRule: updatedRule}));
        this.store.dispatch(createCalendarRuleSuccess({selectedCalendarRule: cratedRule}));
      });
  }

  delete() {
    const ruleId = this.busyTime.rule.id;
    this.calendarRuleModalService.openDeleteDialog(ruleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.store.dispatch(deleteCalendarRuleSuccess({id: ruleId}));
      });
  }

}
