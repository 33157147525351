import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Event as EventModel } from '~/shared/models';

export const EVENTS = 'events';

export interface EventState extends EntityState<EventModel> {
  selectedId: number | null;
  isLoading?: boolean;
  error?: any;
}

export const eventAdapter: EntityAdapter<EventModel> = createEntityAdapter<EventModel>({
  selectId: cur => cur ? cur.id : null
});

export const initialEventState: EventState = eventAdapter.getInitialState({
  selectedId: null,
  isLoading: undefined,
  error: undefined,
  selectedTime: undefined
});
