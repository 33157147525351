import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field, FieldConfig } from '~/shared/models';

import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormDateComponent } from './form-date/form-date.component';
import { FormToggleComponent } from './form-toggle/form-toggle.component';
import { FormAutocompleteComponent } from './form-autocomplete/form-autocomplete.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';

const components = {
  input: FormInputComponent,
  select: FormSelectComponent,
  date: FormDateComponent,
  toggle: FormToggleComponent,
  autocomplete: FormAutocompleteComponent,
  textarea: FormTextareaComponent
};

@Directive({
  selector: '[apfrDynamicField]'
})
export class DynamicFieldDirective implements OnInit {
  @Input() config: FieldConfig;
  @Input() group: FormGroup;

  component;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnInit(): void {

    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components)
        .join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }

    const factory = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(factory);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
