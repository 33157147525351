import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '~/framework/core/src/base.component';

import { NotificationComponent } from '../notification/notification.component';
import { HideNotification, NotificationState, selectNotifications } from '~/store/notification';

/**
 * NotificationWrapper
 * manage show/hide {@link NotificationComponent}
 *
 * @usage
 * In app.component.html
 *
 * <apfr-notification-wrapper></apfr-notification-wrapper>
 *
 * In any part of application
 *
 * showNotification(style: NotificationStyle, message: string) {
 *   this.notifStore.dispatch(new ShowNotification({style, message}));
 * }
 *
 * See {@link NotificationStyle} {@link NotificationModel} {@link NotificationState}
 */
@Component({
  selector: 'apfr-notification-wrapper',
  templateUrl: './notification-wrapper.component.html',
  styleUrls: ['./notification-wrapper.component.scss']
})
export class NotificationWrapperComponent extends BaseComponent implements OnInit {
  @ViewChild('notification', { read: ViewContainerRef, static: true }) notification: ViewContainerRef;
  notificationComponent: ComponentRef<NotificationComponent>;

  private notificationIdsMap = new Map;
  private notificationIndex = 0;

  constructor(private readonly resolver: ComponentFactoryResolver,
              private readonly notifStore: Store<NotificationState>) {
    super();
  }

  ngOnInit() {
    this.showNotification();
  }

  /**
   * ShowNotification
   * subscribe showNotification$ subject
   * it creates notification message dynamically
   */
  showNotification() {
    this.notifStore
      .pipe(
        select(selectNotifications),
        filter(notif => notif !== undefined),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((notificationData) => {
        const notificationFactory = this.resolver.resolveComponentFactory(NotificationComponent);
        this.notificationComponent = this.notification.createComponent(notificationFactory);
        this.notificationComponent.instance.style = notificationData.style;
        this.notificationComponent.instance.message = notificationData.message;
        this.notificationComponent.instance.notificationId = this.notificationIndex;
        this.notificationIdsMap.set(this.notificationIndex, this.notificationComponent);
        this.notificationIndex += 1;
        this.notificationComponent.instance
          .hideNotification
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((componentId) => this.destroyComponent(componentId));
      });
  }

  private destroyComponent(componentId) {
    const comp = this.notificationIdsMap.get(componentId);
    const indice = this.notification.indexOf(comp);
    this.notification.remove(indice);
    this.notificationIdsMap.delete(componentId);
    this.notifStore.dispatch(new HideNotification(indice));
  }

}
