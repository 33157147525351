import { NgModule } from '@angular/core';
import { MaterialModule } from '~/framework';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SelectAllCheckboxComponent } from './select-all-checkbox.component';

@NgModule({
  imports: [
    MaterialModule,
    MatCheckboxModule
  ],
  declarations: [
    SelectAllCheckboxComponent
  ],
  exports: [
    SelectAllCheckboxComponent
  ]
})
export class SelectAllCheckboxModule {}
