import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { CustomerFormState, selectCustomerFields } from '~/store/customers/customer-form';
import * as fromActions from '~/store/customers/customer-form/customer-form.actions';


@Injectable()
export class CustomerFormResolver implements Resolve<void> {
  constructor(private store: Store<CustomerFormState>) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): void {
    this.store.select(selectCustomerFields)
      .subscribe((fields) => {
        if (fields.length === 0) {
          this.store.dispatch(new fromActions.LoadForm());
        }
      });
  }
}
