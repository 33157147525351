import { Action } from '@ngrx/store';
import { Event as EventModel } from '~/shared/models';
import { Update } from '@ngrx/entity';

export const enum EventsBufferActionTypes {
  CopyEvent = '[Events Buffer] Copy',
  PasteEvent = '[Events Buffer] Paste',
  RemoveFromBuffer = '[Events Buffer] Remove',
  RemoveAllFromBuffer = '[Events Buffer] Remove All',
  UndoPaste = '[Events Buffer] UndoPaste',
  PastedEvent = '[Events Buffer] PastedEvent'
}

export class CopyEvent implements Action {
  readonly type = EventsBufferActionTypes.CopyEvent;

  constructor(public payload: { event: EventModel }) {
  }
}

export class PasteEvent implements Action {
  readonly type = EventsBufferActionTypes.PasteEvent;

  constructor(public payload: { updateData: Update<EventModel> }) {
  }
}

export class RemoveEventBuffer implements Action {
  readonly type = EventsBufferActionTypes.RemoveFromBuffer;

  constructor(public payload: { eventId: number }) {
  }
}

export class RemoveAllFromBuffer implements Action {
  readonly type = EventsBufferActionTypes.RemoveAllFromBuffer;
}

export class UndoPaste implements Action {
  readonly type = EventsBufferActionTypes.UndoPaste;

  constructor(public payload: { updateData: Update<EventModel> }) {
  }
}

export class PastedEvent implements Action {
  readonly type = EventsBufferActionTypes.PastedEvent;

  constructor(public payload: { eventId: number }) {
  }
}

export type EventsBufferActions =
  CopyEvent |
  PasteEvent |
  RemoveEventBuffer |
  RemoveAllFromBuffer |
  UndoPaste |
  PastedEvent;
