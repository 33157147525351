<div fxLayout="row"
     fxLayoutAlign="start center"
     fxLayoutGap="10px"
     fxFlex="1 1 auto">
  <div (click)="openInstitutionDialog()">
    <span class="institution-label">
      <ng-container *ngIf="selectedInstitutions">
        <ng-container *ngIf="selectedInstitutions.length > 1; else instName">
          {{ selectedInstitutions.length }} {{ 'institutions' | translate }}
        </ng-container>
        <ng-template #instName>
          {{ selectedInstitutions[0]?.name }}
        </ng-template>
      </ng-container>
    </span>
  </div>

  <mat-icon class="pointer show-form"
            *ngIf="!isMobile && selectedInstitutions && selectedInstitutions.length === 1"
            [ngClass]="isShowFormPanel ? 'active' : 'inactive'"
            (click)="toggleIsShowFormPanel()">
    vertical_split
  </mat-icon>

  <span class="spacer"></span>
  <button mat-icon-button (click)="openAgendaSettingsDialog()">
    <mat-icon>calendar_today</mat-icon>
  </button>
</div>
