<div *ngIf="busyTime" class="busy-time"
     [ngStyle]="styles">
  <div class="icons-container" *ngIf="isEnabledSelection && busyTime.rule.available">
    <mat-icon (click)="edit()">edit</mat-icon>
    <mat-icon (click)="delete()">delete</mat-icon>
  </div>

  <div *ngIf="busyTime.rule.comment" class="comment">
    {{busyTime.rule.comment}}
  </div>
</div>
