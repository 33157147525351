import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field, FieldConfig } from '~/shared/models';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'apfr-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss']
})
export class FormAutocompleteComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;

  filteredOptions$: Observable<Array<any>>;

  constructor() { }

  ngOnInit() {
    this.filteredOptions$ = this.group
      .get(this.config.name)
      .valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => this.filter(name))
      );
  }

  private filter(name: any): Array<any> {
    const filterValue = name.toLowerCase();
    if (!this.config.options) {
      return [];
    }

    return this.config.options.filter((option: any) => {
      return option.name.toLowerCase()
        .includes(filterValue);
    });
  }

  displayFn(option?: any): string | undefined {
    return option ? option.name : undefined;
  }
}
