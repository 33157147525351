import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldConfig } from '~/shared/models';

@Component({
  exportAs: 'apfrDynamicForm',
  selector: 'apfr-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input()
  parentForm: FormGroup;
  @Input()
  config: Array<FieldConfig> = [];
  @Input()
  hideSubmit = false;
  @Output()
  submitted: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.createGroup();
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get changes() {
    return this.form.valueChanges;
  }

  get value(): any {
    return this.form.value;
  }

  setValue(name: string, value: any) {
    if (this.form.controls[name]) {
      this.form.controls[name].setValue(value, {emitEvent: true});
    }
  }

  private createGroup() {
    const group = this.fb.group({});
    this.config.forEach(control => group.addControl(control.name, this.fb.control('')));

    return group;
  }

}
