import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CalendarService } from '~/+calendar/shared/calendar.service';
import { DatabaseService } from '~/framework/database/database.service';
import { Event } from '~/shared/models';

import {
  AddEventSuccess,
  CalendarActionTypes, DeleteEvent, DeleteEventFail, DeleteEventSuccess,
  GetAgenda,
  GetAgendaFail,
  GetAgendaFromDB,
  GetAgendaSuccess,
  SetAgendaFromDB,
  SetAgendaToDB,
  UpdateEvent,
  UpdateEventSuccess
} from './calendar.actions';
import { EventState } from './calendar.state';

@Injectable()
export class CalendarEffects {
  getEvents$ = createEffect(() => this.actions$
    .pipe(
      ofType(CalendarActionTypes.GetAgenda),
      map((action: GetAgenda) => action.payload),
      switchMap((payload) => {
        return this.calendarService.getAgenda(payload)
          .pipe(
            map(events => new GetAgendaSuccess(events)),
            catchError(error => of(new GetAgendaFail(error)))
          );
      })
    ));
  // TODO: refactor this, it doesnt make sense to get event again after creation,
  //  we use this because of different format response of creation/update endpoint.
  //  UpdateEventSuccess() called to insert of update in store event
  getEvent$ = createEffect(() => this.actions$
    .pipe(
      ofType(CalendarActionTypes.AddEventSuccess),
      map((action: AddEventSuccess) => action.payload),
      switchMap((payload) => {
        return this.calendarService.getEventById(payload.eventId)
          .pipe(
            map(event => new UpdateEventSuccess({updatedEvent: event}))
          );
      })
    ));
  handleGetAgendaSuccess = createEffect(() => this.actions$
    .pipe(
      ofType(CalendarActionTypes.GetAgendaSuccess),
      map((action: GetAgendaSuccess) => action.payload),
      map((data) => new SetAgendaToDB(data)),
      tap((action) => this.databaseService.setAgenda(action.payload))
    ));
  handleGetAgendaFail = createEffect(() => this.actions$
    .pipe(
      ofType(CalendarActionTypes.GetAgendaFail),
      map((action: GetAgendaFail) => action.payload),
      tap(error => {
        if (error.status === 0 || error.status >= 500) {
          this.databaseService.getAgenda()
            .subscribe(events => {
              this.store.dispatch(new SetAgendaFromDB(events));
            });
        }
      }),
      map(data => new GetAgendaFromDB())
    ));
  handleUpdateEvent$ = createEffect(() => this.actions$
    .pipe(
      ofType(CalendarActionTypes.UpdateEvent),
      filter((action: UpdateEvent) => !action.payload.updateData.changes.files),
      map((action: UpdateEvent) => action.payload),
      switchMap((payload) => this.calendarService.updateEvent(payload.updateData)),
      map((event: Event) => new UpdateEventSuccess({updatedEvent: event}))
    ));

  private id: number;
  handleDeleteEvent$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CalendarActionTypes.DeleteEvent),
        map((action: DeleteEvent) => {
          this.id = action.payload.eventId;
          return this.id;
        }),
        switchMap((id: number) => this.calendarService.deleteEvent(id)
          .pipe(
            map(() => {
              return new DeleteEventSuccess({eventId: this.id});
            }),
            catchError(error => {
              return of(new DeleteEventFail({error}));
            })
          ))
      ));

  constructor(private actions$: Actions,
              private calendarService: CalendarService,
              private store: Store<EventState>,
              private databaseService: DatabaseService) {
  }
}
