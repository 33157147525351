import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FreeTimeActionTypes, GetFreeTime, GetFreeTimeFail, GetFreeTimeSuccess } from '~/store/free-time/free-time.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CalendarRuleObject, CalendarService, FreeTimeResponse } from '~/+calendar/shared/calendar.service';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { CalendarRuleState, getCalendarRuleSuccess } from '~/store/calendar-rule';
import { uniqBy } from 'lodash';
import { ICalendarRule } from '@apfr/components/calendar-rule-modal';

@Injectable()
export class FreeTimeEffects {
  handleGetFreeTime$ = createEffect(() => this.actions$
    .pipe(
      ofType(FreeTimeActionTypes.GetFreeTime),
      map((action: GetFreeTime) => action.payload),
      switchMap((payload) => {
        return this.calendarService.getFreeTime(payload)
          .pipe(
            map((freeTimeResponse: FreeTimeResponse) => {
              const busyTimes = freeTimeResponse.busyTimes;
              let calendarRules: ICalendarRule[] = [];
              Object.keys(busyTimes)
                .map((day: string) => {
                  busyTimes[day].map((calendarRuleObject: CalendarRuleObject) => {
                    calendarRules.push(calendarRuleObject.rule);
                  });
              });

              calendarRules = uniqBy(calendarRules, 'id');

              this.store.dispatch(getCalendarRuleSuccess({
                calendarRules
              }));

              return new GetFreeTimeSuccess({freeTimeResponse});
            }),
            catchError(error => of(new GetFreeTimeFail({error})))
          );
      })
    ));

  constructor(private actions$: Actions,
              private calendarService: CalendarService,
              private store: Store<CalendarRuleState>
  ) {
  }
}
