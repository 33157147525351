import { Action } from '@ngrx/store';
import { NoteModel } from '~/shared/models';

export enum NoteActionTypes {
  GetNotes = '[Note] Get Notes',
  GetNotesFail = '[Note] Get Notes Fail',
  GetNotesSuccess = '[Note] Get Notes Success'
}

export class GetNotes implements Action {
  readonly type = NoteActionTypes.GetNotes;

  constructor(public payload: string) {
  }
}

export class GetNotesFail implements Action {
  readonly type = NoteActionTypes.GetNotesFail;

  constructor(public payload: any) {
  }
}

export class GetNotesSuccess implements Action {
  readonly type = NoteActionTypes.GetNotesSuccess;

  constructor(public payload: NoteModel) {
  }
}

export type NoteActions =
  GetNotes |
  GetNotesFail |
  GetNotesSuccess;
