import { CurrentUser, UserTokenData } from '~/shared/models';

export const CURRENT_USER = 'currentUser';

export interface CurrentUserState {
  userTokenData: UserTokenData;
  currentUser: CurrentUser;
  isLoading?: boolean;
  error?: any;
}

export const initialCurrentUserState: CurrentUserState = {
  userTokenData: undefined,
  currentUser: undefined,
  isLoading: undefined,
  error: undefined
};
