import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UserTokenData} from '~/shared/models';
import {Observable} from "rxjs";
import {ResponsiveService} from "@apfr/components/core";

@Component({
  selector: 'apfr-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      )
    ])
  ]
})
export class SidenavMenuComponent {
  constructor(private responsiveService: ResponsiveService) {
  }

  @Input()
  sidenav: MatSidenav;
  @Input()
  externalMenuLinks;
  @Input()
  isMobile: boolean;
  @Input()
  isMobileAgent: boolean;
  @Input()
  currentUser: UserTokenData;

  @Output()
  signOut: EventEmitter<void> = new EventEmitter<void>();

  isOpenConfiguration: boolean = false;
  isOpenSearch: boolean = false;
  isMobile$: Observable<boolean> = this.responsiveService.isMobile$;

  toggleExpanded(group) {
    this.externalMenuLinks[group].expanded = !this.externalMenuLinks[group].expanded;
  }

  keysFrom(object: Object): Array<string> {
    if (!object) {
      return;
    }

    return Object.keys(object);
  }

  desktopMode() {
    const meta: HTMLElement = document.querySelector("meta[name='viewport']");
    meta.setAttribute('content', 'width=1024');
  }

  mobileMode() {
    const meta: HTMLElement = document.querySelector("meta[name='viewport']");
    meta.setAttribute('content', 'width=device-width, initial-scale=1.0 user-scalable=no');
  }

  signOutEvent() {
    localStorage.clear();
    this.signOut.emit();
  }

}
