import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NoteService } from '~/+customer/shared';

import * as fromActions from './note.actions';
import { NoteActionTypes } from './note.actions';

@Injectable()
export class NoteEffects {
  loadNotes$ = createEffect(() => this.action$
    .pipe(
      ofType(NoteActionTypes.GetNotes),
      map((action: fromActions.GetNotes) => action.payload),
      switchMap((customerId: string) => {
        return this.noteService.getNotes(customerId)
          .pipe(
            map(note => new fromActions.GetNotesSuccess(note)),
            catchError(error => observableOf(new fromActions.GetNotesFail(error)))
          );
      })
    ));

  constructor(private action$: Actions,
              private noteService: NoteService) {
  }
}
