import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventListService } from '@apfr/components/events';

@Component({
  selector: 'apfr-event-list-modal',
  templateUrl: './event-list-modal.component.html',
  styleUrls: ['./event-list-modal.component.scss']
})
export class EventListModalComponent implements OnInit {

  phone: string;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<EventListModalComponent>,
    private readonly eventListService: EventListService
  ) {
    this.phone = dialogData.phone;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.eventListService.clearEventData();
    this.dialogRef.close(false);
  }

}
