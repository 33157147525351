import { Component } from '@angular/core';
import { BaseEventComponent } from '~/framework/core/src/base.event.component';

@Component({
  selector: 'apfr-agenda-event-ethtest',
  templateUrl: './agenda-event-ethtest.component.html',
  styleUrls: ['./agenda-event-ethtest.component.scss']
})
export class AgendaEventEthtestComponent extends BaseEventComponent {

}
