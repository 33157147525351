<div class="agenda-body"
     [hidden]="!selectedResources" (swipeleft)="nextDay()" (swiperight)="prevDay()">
  <div class="header" fxFlex="1" fxLayout #header>
    <div class="left-spacer" *ngIf="(displayTimeLabel$ | async)">
      <div class="ls"></div>
    </div>
    <div class="calendar-header" [style.width]="(displayTimeLabel$ | async) ? 'calc(100% - 65px)' : '100%'">
      <div class="ch">
        <div class="date"
             *ngFor="let mainColumn of mainColumns; let first = first; let last = last; trackBy: trackByFn"
             #calendarDateHeader>
          <h4 class="date-label"
              [ngClass]="{'hovered': hoveredFirstColumn == mainColumn.key}"
          >
            <ng-container *ngIf="first">
              <button mat-icon-button (click)="prevDay()" class="prev-btn btn">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </ng-container>
            <span [title]="mainColumn.name">{{ mainColumn.name }}</span>
            <ng-container *ngIf="last">
              <button mat-icon-button (click)="nextDay()" class="next-btn btn">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </ng-container>
          </h4>

          <div class="resources-header" *ngIf="(viewType === AgendaViewTypeEnum.DayView && !isGroupedByInst)">
            <div class="resource" *ngFor="let secondaryColumn of secondaryColumns; trackBy: trackByFn">
              <div class="resource-types">
                <div class="type pro" *ngIf="secondaryColumn?.resource?.legacyIsProVisible"></div>
                <div class="type web" *ngIf="secondaryColumn?.resource?.legacyIsWebVisible"></div>
                <div class="type call" *ngIf="secondaryColumn?.resource?.legacyIsCallCenterVisible"></div>
              </div>
              <h4 class="resource-label"
                  [ngClass]="{'hovered': hoveredFirstColumn + hoveredSecondColumn == mainColumn.key + secondaryColumn.key}"
              >
                <span class="concat-label">{{ secondaryColumn.name }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="scrollable"  #scrollable>
    <div class="calendar-body">
      <div class="agenda-table">
        <div class="time-labels" *ngIf="(displayTimeLabel$ | async)">
          <div class="tls">
            <div class="time-label"
                 [ngClass]="{'hovered': hoveredTime == time}"
                 *ngFor="let time of timeTable; trackBy: trackByFn">
              <span>{{ time }}</span>
            </div>
          </div>
        </div>

        <div class="agenda-presentation">
          <div class="agenda-content" #content
               (mousemove)="mousemove($event)"
               (mouseup)="mouseup($event)"
               (mousedown)="mousedown($event)"
               [ngClass]="{
               'dragging': draggingEvent || selectingCalendarRule
               }"
          >
            <div class="lines">
              <div class="line" *ngFor="let time of timeTable; trackBy: trackByFn"></div>
            </div>
            <div class="dnd-container"
                 *ngIf="draggingEvent || selectingCalendarRule"
                 [ngClass]="{
                 'dragging-container': true,
                  'invalid': tooltipMessage,
                  'event-container': draggingEvent,
                  'calendar-rule-container': selectingCalendarRule
                  }"
                 [ngStyle]="draggingEvent ? dndContainerStyles: calendarRuleSelectionStyles"
            >
              <div class="time" *ngIf="selectingCalendarRule && !tooltipMessage">
                {{startRuleTime}} - {{endRuleTime}}
              </div>
              <div class="tooltip-message" *ngIf="tooltipMessage">
                {{tooltipMessage | translate}}
              </div>
            </div>

            <ng-container *ngFor="let mainColumn of mainColumns; trackBy: trackByFn">
              <ng-container *ngIf="(viewType === AgendaViewTypeEnum.WeekView || isGroupedByInst); else dayView">
                <div class="one-col" #oneCol  [attr.mainColumnKey]="mainColumn.key">

                  <div class="red-line" *ngIf="todayMainColumnKey === mainColumn.key"
                       [ngStyle]="{'top': redLineTopPosition + 'px'}"></div>
                  <div class="col-content" *ngIf="isCalendarCmp">
                    <ng-container *ngIf="groupedEvents[mainColumn.key]">
                      <ng-container *ngFor="let eventRes of groupedEvents[mainColumn.key]">
                        <apfr-agenda-event-stack
                          [eventStack]="$any(eventRes)"
                          [viewType]="viewType"
                          [cellHeight]="cellHeight"
                          [isGroupedByResources]="isGroupedByResources"
                          [startAgenda]="startAgenda"
                          [intervalAgenda]="intervalAgenda"
                          [isMobile]="isMobile"
                          [numberOfDays]="numberOfDays"
                          [colorMode]="selectedColorMode"
                          [isGroupedByInst]="isGroupedByInst"
                          [selectedColor]="selectedColor"
                        ></apfr-agenda-event-stack>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-template #dayView>
                  <div class="one-col" #oneCol
                       *ngFor="let secondaryColumn of secondaryColumns; trackBy: trackByFn"
                       [attr.secondaryColumnKey]="secondaryColumn.key"
                       [attr.mainColumnKey]="mainColumn.key">
                    <div class="red-line" *ngIf="todayMainColumnKey === mainColumn.key"
                         [ngStyle]="{'top': redLineTopPosition + 'px'}"></div>

                    <div class="col-content">
                      <ng-container *ngIf="isCalendarCmp && groupedEvents[mainColumn.key]">
                        <ng-container *ngFor="let eventRes of secondaryColumn.key ?
                         groupedEvents[mainColumn.key][secondaryColumn.key] :
                          groupedEvents[mainColumn.key]">
                          <apfr-agenda-event-stack
                            [eventStack]="$any(eventRes)"
                            [viewType]="viewType"
                            [cellHeight]="cellHeight"
                            [isGroupedByResources]="isGroupedByResources"
                            [startAgenda]="startAgenda"
                            [intervalAgenda]="intervalAgenda"
                            [isMobile]="isMobile"
                            [numberOfDays]="numberOfDays"
                            [colorMode]="selectedColorMode"
                            [isGroupedByInst]="isGroupedByInst"
                            (selectEvent)="selectEvent($event, secondaryColumn)"
                            (mouseDownEvent)="mouseDownEvent($event, secondaryColumn)"
                            [draggingEvent]="draggingEvent"
                            [allowOverbooking]="hasOverbookingPermission"
                            [selectedColor]="selectedColor"
                            [isDraggable]="isDraggableEvent()"
                          ></apfr-agenda-event-stack>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="isCalendarCmp && groupedFreeTimes[mainColumn.key]">
                        <ng-container *ngFor="let timeSlot of secondaryColumn.key ?
                         groupedFreeTimes[mainColumn.key][secondaryColumn.key] :
                          groupedFreeTimes[mainColumn.key]">
                          <apfr-agenda-free-time [timeSlot]="timeSlot"
                                                 [cellHeight]="cellHeight"
                                                 [hidden]="selectedColor"
                                                 (click)="selectTime($event,
                                              mainColumn.key,
                                              secondaryColumn,
                                              timeSlot.startTime)"
                                                 [hovered]="
                                               mainColumn.key + secondaryColumn.key + timeSlot.startTime ===
                                                this.hoveredFirstColumn + this.hoveredSecondColumn + this.hoveredTime &&
                                                !selectingCalendarRule"
                                                 [startAgenda]="startAgenda"
                                                 [intervalAgenda]="intervalAgenda">
                          </apfr-agenda-free-time>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="groupedBusyTimes[mainColumn.key]">
                        <ng-container *ngFor="let busyTime of secondaryColumn.key ?
                         groupedBusyTimes[mainColumn.key][secondaryColumn.key] :
                          groupedBusyTimes[mainColumn.key]">
                          <apfr-agenda-busy-time [busyTime]="busyTime"
                                                 [hidden]="selectedColor"
                                                 [startAgenda]="startAgenda"
                                                 [day]="mainColumn.key"
                                                 [cellHeight]="cellHeight"
                                                 [isMobile]="isMobile"
                                                 [isEnabledSelection]="isEnabledSelection"
                                                 [intervalAgenda]="intervalAgenda">
                          </apfr-agenda-busy-time>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <apfr-agenda-event-filter *ngIf="isCalendarCmp"
                            [events]="events"
                            [selectedResources]="selectedResources"
                            [(selectedColorMode)]="selectedColorMode"
                            [(selectedColor)]="selectedColor"
                            (heightChanged)="filterHeightChanged($event)"
  ></apfr-agenda-event-filter>

</div>
