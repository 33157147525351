import { CustomerModel } from '~/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const CUSTOMERS = 'customers';

export interface CustomerState extends EntityState<CustomerModel> {
  selectedId: string | null;
  isLoading?: boolean;
  error?: any;
}

export const customerAdapter: EntityAdapter<CustomerModel> = createEntityAdapter<CustomerModel>({
  selectId: cur => cur.id
});


export const initialCustomerState: CustomerState = customerAdapter.getInitialState({
  selectedId: null,
  isLoading: undefined,
  error: undefined
});
