import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ConfigAppEffects } from '~/store/config-app/config-app.effects';

import { CONFIG_APP } from './config-app.state';
import { configAppReducers } from './config-app.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(CONFIG_APP, configAppReducers),
    EffectsModule.forFeature([ConfigAppEffects])
  ]
})
export class ConfigAppStateModule {
}
