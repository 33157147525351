import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { NOTIFICATIONS } from './notification.state';
import { notificationReducer } from './notification.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTIFICATIONS, notificationReducer)
  ]
})
export class NotificationStateModule {
}
