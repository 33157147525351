<div class="flex-align-center" *ngIf="event">
  <mat-button-toggle-group
    name="eventStatus"
    [vertical]="vertical"
    (change)="changeStatus($event.value)"
    [disabled]="disabled"
  >
    <mat-button-toggle [value]="eventStatuses.InProgress"
                       [checked]="isChecked(eventStatuses.InProgress)">
      <mat-icon>local_parking</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [value]="eventStatuses.CV"
                       [checked]="isChecked(eventStatuses.CV)">
      CV
    </mat-button-toggle>
    <mat-button-toggle [value]="eventStatuses.Done"
                       [checked]="isChecked(eventStatuses.Done)">
      <mat-icon color="primary">check</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [value]="eventStatuses.NotDone"
                       [checked]="isChecked(eventStatuses.NotDone)">
      <mat-icon color="warn">close</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
