import { Action, ActionReducer } from '@ngrx/store';

const enum UndoActionsTypes {
  Undo = 'UNDO_STATE',
  Redo = 'REDO_STATE'
}

export class Undo implements Action {
  readonly type = UndoActionsTypes.Undo;
}

export class Redo implements Action {
  readonly type = UndoActionsTypes.Redo;
}

let config: Config = {
  allowedActions: undefined,
  maxBufferSize: 5,
  undoActionType: UndoActionsTypes.Undo,
  redoActionType: UndoActionsTypes.Redo
};

let {allowedActions, maxBufferSize, undoActionType, redoActionType} = config;

export function configureUndo(conf: Config) {
  config = {...conf};
  allowedActions = conf.allowedActions;
  maxBufferSize = conf.maxBufferSize;
  undoActionType = conf.undoActionType;
  redoActionType = conf.redoActionType;
}

export interface UndoState<T> {
  past: T[];
  present: T;
  future: T[];
  changes: number;
}

export interface Config {
  maxBufferSize?: number;
  allowedActions?: Action[];
  undoActionType?: UndoActionsTypes.Undo;
  redoActionType?: UndoActionsTypes.Redo;
}

// export const undoRedo = (config?: Config) => <T>(reducer: ActionReducer<T>) => undoReducer(reducer, config);
export function undoReducer(reducer: ActionReducer<any>): ActionReducer<any> {

  let undoState: UndoState<any> = {
    past: [],
    present: reducer(undefined, {} as Action),
    future: [],
    changes: 0
  };

  return (state, action) => {
    let nextState;
    switch (action.type) {
      case undoActionType: {
        nextState = undoState.past[0];
        if (!nextState) {
          return state;
        }
        undoState = {
          past: [...undoState.past.slice(1)],
          present: undoState.past[0],
          future: [undoState.present, ...undoState.future],
          changes: undoState.changes
        };

        return nextState;
      }
      case redoActionType: {
        nextState = undoState.future[0];
        if (!nextState) {
          return state;
        }
        undoState = {
          past: [undoState.present, ...undoState.past],
          present: nextState,
          future: [...undoState.future.slice(1)],
          changes: undoState.changes
        };

        return nextState;
      }
    }

    nextState = reducer(state, action);
    if (!config.allowedActions || (allowedActions && allowedActions.some((a) => a.type === action.type))) {

      const nextChanged = undoState.changes + 1;

      undoState = {
        past: [undoState.present, ...undoState.past.slice(0, maxBufferSize - 1)],
        present: nextState,
        future: [...undoState.future.slice(0, maxBufferSize - 1)],
        changes: nextChanged
      };
    }

    return nextState;
  };
}
