import { Action } from '@ngrx/store';
import { ExternalMenuLinksModel } from '~/shared/models';

export enum ConfigAppActionTypes {
  ConfigAppOnline = '[Config App] Online',
  ConfigAppOffline = '[Config App] Offline',
  ConfigCheckMobile = '[Config App] Check Mobile',
  ConfigSetIsMobile = '[Config App] Set Is Mobile',
  ConfigSetIsTablet = '[Config App] Set Is Tablet',
  ConfigSetIsDesktop = '[Config App] Set Is Desktop',

  ConfigAppSetUrls = '[Config App] Set Urls'
}

export class ConfigAppOnline implements Action {
  readonly type = ConfigAppActionTypes.ConfigAppOnline;
}

export class ConfigAppOffline implements Action {
  readonly type = ConfigAppActionTypes.ConfigAppOffline;
}

export class ConfigCheckMobile implements Action {
  readonly type = ConfigAppActionTypes.ConfigCheckMobile;
}

export class ConfigSetIsMobile implements Action {
  readonly type = ConfigAppActionTypes.ConfigSetIsMobile;

  constructor(public payload: boolean) {
  }
}

export class ConfigSetIsTablet implements Action {
  readonly type = ConfigAppActionTypes.ConfigSetIsTablet;

  constructor(public payload: boolean) {
  }
}

export class ConfigSetIsDesktop implements Action {
  readonly type = ConfigAppActionTypes.ConfigSetIsDesktop;
}

export class ConfigAppSetUrls implements Action {
  readonly type = ConfigAppActionTypes.ConfigAppSetUrls;

  constructor(public payload: { externalLinks: ExternalMenuLinksModel }) {
  }
}

export type ConfigAppActions =
  ConfigAppOnline |
  ConfigAppOffline |
  ConfigCheckMobile |
  ConfigSetIsMobile |
  ConfigSetIsTablet |
  ConfigSetIsDesktop |
  ConfigAppSetUrls;
