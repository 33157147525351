import { UserSettings } from '~/store/user-settings/user-settings.actions';

export const USER_SETTINGS = 'user_settings';
// just in case if interface going increase with extra properties
// tslint:disable-next-line:no-empty-interface
export interface UserSettingsState extends UserSettings {

}

export const initialUserSettingsState: UserSettings = {
  eventNotification: false
};

