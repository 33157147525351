import { NgModule } from '@angular/core';

import { MaterialModule, SharedModule } from '~/framework';

import { CardListComponent } from './card-list.component';
import { CommonPipeModule } from '~/shared/pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    CommonPipeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CardListComponent
  ],
  declarations: [
    CardListComponent
  ]
})
export class CardListModule { }
