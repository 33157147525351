import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService, configApiUrls } from '~/framework';
import { AppointmentModel, FieldConfig } from '~/shared/models';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends BaseService {

  getAppointments(customerId: string): Observable<Array<AppointmentModel>> {
    return this.http.get<Array<AppointmentModel>>(`${this.apiUrl}/${configApiUrls.customers}/${customerId}/${configApiUrls.appointments}`)
      .pipe(map((cur: any) => cur[0].data));
  }

  getAppointment(id: string): Observable<AppointmentModel> {
    return this.http.get<AppointmentModel>(`${this.apiUrl}/${configApiUrls.customers}/1/${configApiUrls.appointments}`)
      .pipe(map((cur: any) => cur[0].data[0]));
  }

  getAppointmentForm(): Observable<Array<FieldConfig>> {
    return this.http.get(`${this.apiUrl}/appointment_form`)
      .pipe(map((data: any) => data.inputs));
  }
}
