<ng-container *ngIf="event">
  <div>
    <ng-container *ngIf="numberOfDays < breakpointNumberOfDays; else shortView">
      <small class="start-time">
        {{event.startTime | fromUnixToTime}}
      </small>
      <div class="event-first-line">
        <span>{{ event.contactName | slice:0: 6}}</span>
        <span *ngIf="event.typeCar"> - {{event.typeCar.abreviation}}</span>
        <span *ngIf="event.typeFuel"> - {{event.typeFuel.abreviation}}</span>
      </div>
      <div class="event-second-line">
        <span>{{ event.contactName | replaceContactName }}</span>
        <span *ngIf="event.contactTel"> -
          <a class="link-tel" href="tel:{{ event.contactTel }}">
            {{ event.contactTel }}
          </a>
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #shortView>
  <div class="event-first-line">
    <span>{{ event.contactName | slice:0: 6}}</span>
  </div>
</ng-template>
