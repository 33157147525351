export const serviceForm =     {
  'inputs': [
    {
      'type': 'autocomplete',
      'label': 'Service',
      'name': 'service',
      'placeholder': 'Select a Service',
      'options': [
        {
          'name': 'Haircut'
        },
        {
          'name': 'Hair Styling'
        },
        {
          'name': 'Brow bar'
        },
        {
          'name': 'Nails'
        }
      ]
    },
    {
      'type': 'select',
      'label': 'Additional',
      'name': 'additional_service',
      'placeholder': 'Select a Additional Service',
      'options': ['Color', 'Styling']
    },
    {
      'type': 'input',
      'label': 'Price',
      'name': 'price',
      'placeholder': 'Price of Appointment $'
    }
  ]
}
