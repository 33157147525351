import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

import { BaseComponent, FromStore, objectToQueryParams } from '~/framework';
import { Institution as InstitutionModel, Resource } from '~/shared/models';
import { ConfigAppState, selectIsMobile, selectIsNotDesktop } from '~/store/config-app';
import {
  AgendaSideInfo,
  AgendaSideInfoType,
  AgendaViewState,
  getIsShowForm,
  getSelectedInst,
  getSelectedRes, SetAgendaSettings,
  ShowFormChange
} from '~/store/agenda-view';

import { InstHintDialogComponent } from './inst-hint/inst-hint-dialog.component';
import { AgendaSettingsDialogComponent } from '~/shared/components/agenda-settings-dialog';

@Component({
  selector: 'apfr-agenda-nav',
  templateUrl: './agenda-nav.component.html',
  styleUrls: ['./agenda-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaNavComponent extends BaseComponent implements OnInit, FromStore {
  selectedInstitutions: InstitutionModel[];
  resources: Resource[] = [];

  isMobile: boolean;
  isNotDesktop$: Observable<boolean>;

  isShowFormPanel = false;

  private selectedResources: Resource[];

  constructor(private readonly store: Store<ConfigAppState | AgendaViewState>,
              public dialog: MatDialog,
              private readonly cdRef: ChangeDetectorRef,
              private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.getDataFromStore();
  }

  getDataFromStore(): void {
    this.store
      .pipe(
        select(getSelectedInst),
        filter(cur => cur.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(institutions => {
        this.selectedInstitutions = institutions;

        this.cdRef.markForCheck();
      });

    this.store
      .pipe(
        select(getSelectedRes),
        filter(cur => cur.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(resources => {
        this.selectedResources = resources;
      });

    this.store
      .pipe(
        select(selectIsMobile)
      )
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.cdRef.markForCheck();
      });

    this.isNotDesktop$ = this.store
      .pipe(select(selectIsNotDesktop));

    this.store
      .pipe(
        select(getIsShowForm),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((isShowFormPanel) => {
        this.isShowFormPanel = isShowFormPanel;
        this.cdRef.markForCheck();
      });
  }

  openInstitutionDialog() {
    const dialogConfig: MatDialogConfig = {
      ...this.getBaseDialogConfig(),
      panelClass: ['full-size-dialog', 'inst-hint-modal']
    };

    this.dialog.open(InstHintDialogComponent, dialogConfig);
  }

  toggleIsShowFormPanel() {
    this.isShowFormPanel = !this.isShowFormPanel;

    if (this.isShowFormPanel) {
      // this.store.dispatch(new SetAgendaSettings({agendaSettings: {isFormSticky: true}}));
      this.store.dispatch(new SetAgendaSettings({agendaSettings: {isFormSticky: false}}));
    } else {
      this.store.dispatch(new ShowFormChange({isShowForm: false}));
      this.store.dispatch(new SetAgendaSettings({agendaSettings: {isFormSticky: false}}));

      return;
    }

    const today = moment();
    const objectParams = {
      resource: this.selectedResources[0].id,
      day: today.date(),
      minute: today.minutes(),
      hour: today.hours(),
      year: today.year(),
      month: today.month() + 1
    };

    const iframeSrc = this.sanitizer
      .bypassSecurityTrustResourceUrl(`${environment.baseFormIframeUrl}?${objectToQueryParams(objectParams)}`);
    const selectedTime = {
      dateTime: null,
      secondaryColumn: { key: this.selectedInstitutions[0].id, name: this.selectedInstitutions[0].name },
      time: null,
      selectedResource: null,
      selectedResourceName: null,
      selectedInstitution: this.selectedInstitutions[0].id,
      selectedInstitutionName: this.selectedInstitutions[0].name,
      isGroupedByResources: false
    };
    this.store.dispatch(new AgendaSideInfo({
      type: AgendaSideInfoType.EventForm,
      data: {
        iframeSrc,
        selectedTime
      }
    }));

    this.store.dispatch(new ShowFormChange({ isShowForm: this.isShowFormPanel }));
  }

  openAgendaSettingsDialog() {
    const dialogConfig: MatDialogConfig = {
      ...this.getBaseDialogConfig('520px'),
      panelClass: ['full-size-dialog', 'agenda-settings-dialog']
    };
    this.dialog.open(AgendaSettingsDialogComponent, dialogConfig);
  }

  private getBaseDialogConfig(height: string = 'auto'): MatDialogConfig {
    let dialogConfig: MatDialogConfig = {
      panelClass: ['full-size-dialog']
    };
    if (this.isMobile) {
      dialogConfig = {
        ...dialogConfig,
        width: '100vw',
        maxWidth: '100%',
        height: '100vh'
      };
    } else {
      dialogConfig = {
        ...dialogConfig,
        width: '600px',
        height: height
      };
    }

    return dialogConfig;
  }

}
