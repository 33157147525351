import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AppointmentModel } from '~/shared/models';

export const APPOINTMENTS = 'appointments';

export interface AppointmentState extends EntityState<AppointmentModel> {
  selectedId: string | null;
  isLoading?: boolean;
  error?: any;
}

export const appointmentAdapter: EntityAdapter<AppointmentModel> = createEntityAdapter<AppointmentModel>({
  selectId: cur => cur.id
});

export const initialAppointmentState: AppointmentState = appointmentAdapter.getInitialState({
  selectedId: null,
  isLoading: undefined,
  error: undefined
});
