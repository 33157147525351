import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule as NgrxStoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { undoReducer } from '~/store/undo.reducer';
import { AgendaViewStateModule } from '~/store/agenda-view';
import { NavigationStateModule } from '~/store/navigation/navigation-state.module';

import { environment } from '../../environments/environment';

import { CurrentUserStateModule } from './current-user';
import { NotificationStateModule } from './notification';
import { ConfigAppStateModule } from './config-app';
import { CustomRouterSerializer } from './router';

@NgModule({
  imports: [
    CommonModule,
    NgrxStoreModule.forRoot({
      router: routerReducer
    }, {
      metaReducers: [undoReducer],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument()
      : [],
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    CurrentUserStateModule,
    NotificationStateModule,
    ConfigAppStateModule,
    AgendaViewStateModule,
    NavigationStateModule
  ],
  declarations: []
})
export class StoreModule {
  constructor(@Optional() @SkipSelf() parentModule: StoreModule) {
    if (parentModule) {
      throw new Error('StoreModule already loaded. Import in root module only.');
    }
  }

  static forRoot(): ModuleWithProviders<StoreModule> {
    return {
      ngModule: StoreModule
    };
  }
}
