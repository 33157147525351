import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { NotificationStyle } from '../../models';

/**
 * NotificationComponent
 * @description
 * contain template for notification.
 * This component create dynamically in {@link NotificationWrapperComponent}
 *
 * The component can emit event for delete
 */
@Component({
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  message: string;
  style: NotificationStyle;
  notificationId: number;

  @Output()
  hideNotification: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * hideNotificationAction
   * @description
   * emit event after user pres X on template
   */
  hideNotificationAction() {
    this.hideNotification.emit(this.notificationId);
  }

}
