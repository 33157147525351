import { ICalendarRule } from '@apfr/components/calendar-rule-modal';

export const CALENDAR_RULE = 'calendar-rule';

export interface CalendarRuleState {
  calendarRules: ICalendarRule[];
  selectedCalendarRule: ICalendarRule;
  isLoading: boolean;
  error: any;
}

export const CalendarRuleInitialState: CalendarRuleState = {
  calendarRules: null,
  selectedCalendarRule: null,
  isLoading: undefined,
  error: null
};
