import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { CurrentUserService } from '~/store/current-user/current-user.service';
import { UpdateUserSettings, UserSettings, UserSettingsActionTypes } from '~/store/user-settings/user-settings.actions';
// import { SnackBarComponent } from '~/shared/components';
// import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UserSettingsEffects {
  saveAgendaSettingsEffect$ = createEffect(() => this.actions$
    .pipe(
      ofType(UserSettingsActionTypes.UpdateUserSettings),
      map((action: UpdateUserSettings) => action.payload),
      switchMap((userSettings: UserSettings) => {
        return this.userService.updateProfile(userSettings)
          // .pipe(map(() => {
          //   this.snackBar.openFromComponent(SnackBarComponent, {
          //     duration: 5_000,
          //     data: {
          //       message: 'configuration.step.step_deleted',
          //       action: 'ok'
          //     }
          //   });
          // }));
      })
    ), {dispatch: false});

  constructor(
    private readonly actions$: Actions,
    private userService: CurrentUserService,
    // private snackBar: MatSnackBar,
  ) {
  }
}
