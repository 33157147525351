import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Range } from '@apfr/components/core';

export const planningUrl = '/calendar?institutions={INSTITUTION_ID}';

@Injectable({
  providedIn: 'root'
})
export class UrlRedirector {

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router
  ) {
  }

  $queryParams: Observable<Range> = this.route.queryParams as Observable<Range>;

  redirectTo(url: string) {
    if (url.includes('planningUrl')) {
      window.open(url);
      return;
    }
    const params = new URLSearchParams(
      url.split('?')
        .pop()
    );
    this.router.navigate([url.split('?')[0]], {
      queryParams:
        {
          start_at: params.get('start_at'),
          end_at: params.get('end_at'),
          institutions: params.get('institutions')
        }
    });

  }
}
