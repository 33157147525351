/**
 * Throws an exception for the case when popover trigger doesn't have a valid mde-popover instance
 */
export function throwMdePopoverMissingError() {
  throw Error(`mde-popover-trigger: must pass in an mde-popover instance.

    Example:
      <mde-popover #popover="mdePopover"></mde-popover>
      <button [mdePopoverTriggerFor]="popover"></button>`);
}
