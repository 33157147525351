<mat-card class="card-relationship">
  <div class="card-header">
    <div class="titles">
      <div class="title" *ngFor="let tab of tabs"
           (click)="selectTab(tab)" [class.active]="tab.active">
        <p>{{ tab.title | translate }}</p>
      </div>
    </div>
    <div class="actions">
      <ng-container *ngFor="let tab of tabs">
        <ng-container *ngIf="tab.active">
          <ng-template [cdkPortalOutlet]="tab.actions"></ng-template>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
