<div mat-dialog-title>
  <header>
    <mat-toolbar color="primary">
      <button mat-icon-button class="close-button material-drawer-button" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </header>
</div>
<div mat-dialog-content>
  <mat-card-content>
    <ul>
      <li *ngFor="let uploadedFile of uploadedFiles">
        <div>
          <a mat-button color="primary" target="_blank" [href]="uploadedFile.fullPath">
            <mat-icon>visibility</mat-icon>
          </a>
          <span class="file-name">{{uploadedFile.fileName}}</span>
        </div>
        <button mat-button color="warn" (click)="removeFile(uploadedFile)">
          <mat-icon>delete</mat-icon>
        </button>
      </li>
    </ul>
  </mat-card-content>
  <mat-card-actions>
    <div class="upload-actions-container">
      <button *ngIf="uploadedFiles && uploadedFiles.length" mat-button color="warn" (click)="removeAllFiles(uploadedFiles[0])">
        <mat-icon>delete</mat-icon>
        Delete all files
      </button>
      <button mat-button color="primary" (click)="onClick()">
        <mat-icon>file_upload</mat-icon>
        Upload files
      </button>
    </div>
  </mat-card-actions>
  <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" />
</div>
