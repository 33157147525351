import { createFeatureSelector, createSelector } from '@ngrx/store';
import { entityErrorFn, entityStartLoadingFn, entityStopLoadingFn } from '~/store/entity-reducer.utils';
import { Event as EventModel } from '~/shared/models';
import { Dictionary } from '@ngrx/entity';

import { eventAdapter, EVENTS, EventState, initialEventState } from './calendar.state';
import { CalendarActions, CalendarActionTypes } from './calendar.actions';

export function calendarReducer(
  state: EventState = initialEventState,
  action: CalendarActions
): EventState {
  switch (action.type) {
    case CalendarActionTypes.GetAgenda:
      return entityStartLoadingFn(state);
    case CalendarActionTypes.GetAgendaSuccess:
      return eventAdapter.setAll(action.payload, entityStopLoadingFn(state));
    case CalendarActionTypes.GetAgendaFail:
      return entityErrorFn(state, action.payload);

    case CalendarActionTypes.SetAgendaFromDB:
      return eventAdapter.setAll(action.payload, entityStopLoadingFn(state));
    case CalendarActionTypes.RemoveEvents:
      return eventAdapter.removeMany(action.payload.eventsIds, state);
    case CalendarActionTypes.UpdateEvent:
      return ({...state, isLoading: true});
    case CalendarActionTypes.UpdateEventSuccess:
      // update or insert because we use it on create/update action
      return eventAdapter.upsertOne(action.payload.updatedEvent, {...state, isLoading: false, selectedId: null});
    case CalendarActionTypes.AddEventFromWS:
      // update or insert because we use it on create/update action
      return eventAdapter.upsertOne(action.payload.updatedEvent, {...state, isLoading: false, selectedId: null});
    case CalendarActionTypes.AddEventSuccess:
      return {
        ...state,
        selectedId: action.payload.eventId
      };
    case CalendarActionTypes.DeleteEvent:
      return ({...state, isLoading: true});
    case CalendarActionTypes.DeleteEventSuccess:
      // selectedId - to update free time
      return eventAdapter.removeOne(action.payload.eventId, {...state, isLoading: false, selectedId: action.payload.eventId});
    case CalendarActionTypes.DeleteEventFromWS:
      return eventAdapter.removeOne(action.payload.eventId, {...state, isLoading: false});
    case CalendarActionTypes.DeleteEventFail:
      return entityErrorFn(state, action.payload);
    default:
      return state;
  }
}

export const selectEventState = createFeatureSelector<EventState>(EVENTS);

export const selectEventIds = eventAdapter.getSelectors(selectEventState).selectIds;
export const selectEventEntities = eventAdapter.getSelectors(selectEventState).selectEntities;
export const selectAllEvents = eventAdapter.getSelectors(selectEventState).selectAll;

export const selectEventError = createSelector(
  selectEventState,
  (state) => state.error
);

export const getEventById = () => {
  return createSelector(
    selectEventEntities,
    (entities: Dictionary<EventModel>, props: { id: string }) => {
      return entities[props.id];
    }
  );
};

export const selectedEventId = createSelector(
  selectEventState,
  (state) => state.selectedId
);
