import { NgModule } from '@angular/core';
import { DatepickerHeaderComponent } from '~/shared/components/datepicker-header/datepicker-header.component';
import { MaterialModule, SharedModule } from '~/framework';

@NgModule({
    imports: [
        SharedModule,
        MaterialModule
    ],
    declarations: [DatepickerHeaderComponent]
})
export class DatepickerHeaderModule {}
