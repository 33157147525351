import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FREE_TIME } from '~/store/free-time/free-time.state';
import { freeTimeReducer } from '~/store/free-time/free-time.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FreeTimeEffects } from '~/store/free-time/free-time.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(FREE_TIME, freeTimeReducer),
    EffectsModule.forFeature([FreeTimeEffects])
  ]
})
export class FreeTimeStateModule {
}
