import { NgModule } from '@angular/core';

import { CardActionDirective } from './card-action.directive';

@NgModule({
  declarations: [
    CardActionDirective
  ],
  exports: [
    CardActionDirective
  ]
})
export class CardActionModule {}
