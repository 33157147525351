import { AgendaViewActions, AgendaViewActionTypes } from '~/store/agenda-view/agenda-view.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  AgendaViewState,
  AGENDA_VIEW,
  initialAgendaViewState,
  selectedInstitutionsAdapter,
  selectedResourcesAdapter
} from './agenda-view.state';

export function agendaViewReducer(
  state: AgendaViewState = initialAgendaViewState,
  action: AgendaViewActions
): AgendaViewState {
  switch (action.type) {
    case AgendaViewActionTypes.SelectedInstResChange:
      return {
        ...state,
        selectedInstitutions: selectedInstitutionsAdapter
          .setAll(action.payload.selectedInstitutions, state.selectedInstitutions),
        selectedResources: selectedResourcesAdapter
          .setAll(action.payload.selectedResources, state.selectedResources)
      };

    case AgendaViewActionTypes.ViewTypeChange:
      return {
        ...state,
        viewType: action.payload.viewType
      };
    case AgendaViewActionTypes.DateChange:
      return {
        ...state,
        selectedDate: action.payload.date
      };
    case AgendaViewActionTypes.NumberOfDaysChange:
      return {
        ...state,
        numberOfDays: action.payload
      };
    case AgendaViewActionTypes.ShowFormChange:
      return {
        ...state,
        isShowForm: action.payload.isShowForm,
        agendaSideInfo: action.payload.isShowForm ? state.agendaSideInfo : initialAgendaViewState.agendaSideInfo
      };
    case AgendaViewActionTypes.GroupedResourcesChange:
      return {
        ...state,
        groupedResources: action.payload.groupedResources
      };
    case AgendaViewActionTypes.MaxDayAmountChange:
      return {
        ...state,
        maxDayAmount: action.payload.maxDayAmount
      };
    case AgendaViewActionTypes.GroupedInstitutionsChange:
      return {
        ...state,
        groupedInstitutions: action.payload.groupedInstitutions
      };
    case AgendaViewActionTypes.FreeTimeShortDataChange:
      return {
        ...state,
        freeTimeShortData: action.payload.freeTimeShortData
      };
    case AgendaViewActionTypes.HowManyColumnsAvailable:
      return {
        ...state,
        columnsCount: action.payload.columnsCount
      };
    case AgendaViewActionTypes.AgendaSideInfo:
      return {
        ...state,
        agendaSideInfo: {...action.payload}
      };
    case AgendaViewActionTypes.SetAgendaSettings:
      const {numberOfDays, selectedDate, viewType, displayTimeLabel, isFormSticky} = action.payload.agendaSettings;

      return {
        ...state,
        numberOfDays: numberOfDays ? numberOfDays : state.numberOfDays,
        selectedDate: selectedDate ? selectedDate : state.selectedDate,
        viewType: viewType ? viewType : state.viewType,
        displayTimeLabel: displayTimeLabel !== undefined ? displayTimeLabel : state.displayTimeLabel,
        isFormSticky: isFormSticky !== undefined ? isFormSticky : state.isFormSticky
      };
    default:
      return state;
  }
}

const selectAgendaViewState = createFeatureSelector<AgendaViewState>(AGENDA_VIEW);

export const getViewType = createSelector(
  selectAgendaViewState,
  (state) => state.viewType
);

export const getSelectedDate = createSelector(
  selectAgendaViewState,
  (state) => state.selectedDate
);

export const getInst = createSelector(
  selectAgendaViewState,
  (state) => state.selectedInstitutions
);

export const getSelectedInst = selectedInstitutionsAdapter
  .getSelectors(getInst).selectAll;

export const getRes = createSelector(
  selectAgendaViewState,
  (state) => state.selectedResources
);

export const getSelectedRes = selectedResourcesAdapter
  .getSelectors(getRes).selectAll;

export const getNumberOfDays = createSelector(
  selectAgendaViewState,
  (state) => state ? state.numberOfDays : 1
);

export const getIsShowForm = createSelector(
  selectAgendaViewState,
  (state) => state.isShowForm
);

export const getGroupedByResources = createSelector(
  selectAgendaViewState,
  (state) => state.groupedResources
);

export const getMaxDayAmount = createSelector(
  selectAgendaViewState,
  (state) => state.maxDayAmount
);

export const getIsGroupedInstitutions = createSelector(
  selectAgendaViewState,
  (state) => state.groupedInstitutions
);

export const getFreeTimeShortData = createSelector(
  selectAgendaViewState,
  (state) => state.freeTimeShortData
);


export const getColumnsCount = createSelector(
  selectAgendaViewState,
  (state) => state.columnsCount
);

export const getAgendaSideInfo = createSelector(
  selectAgendaViewState,
  (state) => state.agendaSideInfo
);

export const getDisplayTimeLabel = createSelector(
  selectAgendaViewState,
  (state) => state.displayTimeLabel
);
