import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { File } from '~/shared/models/agenda/file.model';

@Component({
  selector: 'apfr-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss']
})
export class FilePreviewModalComponent implements OnInit {

  file: File;
  fileSrc: string;

  constructor(
    public dialogRef: MatDialogRef<FilePreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.file = this.data['file'];
    if (!this.file) {
      return;
    }
    this.fileSrc = this.file.fullPath;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
