import { createFeatureSelector, createSelector } from '@ngrx/store';
import { entityErrorFn, entityStartLoadingFn, entityStopLoadingFn } from '~/store/entity-reducer.utils';

import { CustomerFormState, CUSTOMER_FORM, initialCustomerFormState } from './customer-form.state';
import * as formActions from './customer-form.actions';
import { CustomerFormActionTypes } from './customer-form.actions';

export function customerFormReducer(
  state: CustomerFormState = initialCustomerFormState,
  action: formActions.CustomerFormActions
): CustomerFormState {
  switch (action.type) {
    case CustomerFormActionTypes.LoadForm:
      return entityStartLoadingFn(state);
    case CustomerFormActionTypes.LoadFormSuccess:
      return {
        ...entityStopLoadingFn(state),
        fields: action.payload
      };
    case CustomerFormActionTypes.LoadFormFail:
      return entityErrorFn(state, action.payload);
    default:
      return state;
  }
}

export const selectCustomerFormState = createFeatureSelector<CustomerFormState>(CUSTOMER_FORM);

export const selectCustomerFields = createSelector(
  selectCustomerFormState,
  (state) => state.fields
);

export const selectCustomerFormError = createSelector(
  selectCustomerFormState,
  (state) => state.error
);

export const selectCustomerFormLoading = createSelector(
  selectCustomerFormState,
  (state) => state.isLoading
);
