import { initialUserSettingsState, USER_SETTINGS, UserSettingsState } from './user-settings.state';
import { UserSettingsActions, UserSettingsActionTypes } from './user-settings.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export function userSettingsReducer(
  state: UserSettingsState = initialUserSettingsState,
  action: UserSettingsActions
): UserSettingsState {
  switch (action.type) {
    case UserSettingsActionTypes.SetUserSettings:
      return {
        ...state,
        ...action.payload
      };
    case UserSettingsActionTypes.UpdateUserSettings:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export const selectUserSettingsState = createFeatureSelector<UserSettingsState>(USER_SETTINGS);
export const getEventNotification = createSelector(
  selectUserSettingsState,
  (state: UserSettingsState) => state && state.eventNotification
);
