import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AgendaViewTypeEnum, Event as EventModel } from '~/shared/models';
import { ReorganizeEvents, setStyleToEvent } from '~/shared/components/agenda/agenda.util';
import { EventTypes } from '~/store/calendar';
import { MatDialog } from '@angular/material/dialog';
import { AgendaEventGroupDialogComponent } from '~/shared/components/agenda/agenda-event-group-dialog/agenda-event-group-dialog.component';


export interface EventGroupDialogOptions {
  eventRes: ReorganizeEvents;
  options: { isDraggable: boolean; isMobile: boolean; isGroupedByResources: boolean };
}

@Component({
  selector: 'apfr-agenda-event-grouped',
  templateUrl: './agenda-event-grouped.component.html',
  styleUrls: ['./agenda-event-grouped.component.scss']
})
export class AgendaEventGroupedComponent {
  EventTypes = EventTypes;

  @Input()
  eventRes: ReorganizeEvents;
  @Input()
  isMobile: boolean;
  @Input()
  isDraggable = false;
  @Input()
  isGroupedByResources: boolean;

  @Input()
  viewType: AgendaViewTypeEnum;

  @Input()
  startAgenda: string;
  @Input()
  cellHeight: number;

  @Input()
  intervalAgenda: number;

  @Output()
  selectEvent = new EventEmitter<EventModel>();

  constructor(private matDialog: MatDialog) {
  }


  trackByFn(index, event: EventModel) {
    return event.id;
  }

  setStyleToOverlapEvent() {
    if (!this.eventRes) {
      return;
    }
    const firstEvent = this.eventRes.events[0];

    return setStyleToEvent(firstEvent, this.startAgenda, this.intervalAgenda, this.cellHeight, this.viewType);
  }

  showEventListDialog(mouseEvent: MouseEvent) {
    const matDialogRef = this.matDialog.open(AgendaEventGroupDialogComponent, {
      data: <EventGroupDialogOptions> {
        eventRes: this.eventRes,
        options: {
          isDraggable: this.isDraggable,
          isMobile: this.isMobile,
          isGroupedByResources: this.isGroupedByResources
        }
      },
      width: `clamp(100px, 500px, 100%)`
    });

    const selectEventSubscription = matDialogRef.componentInstance.selectEvent.subscribe({
      next: (event: EventModel) => {
        this.selectEvent.emit(event);
      }
    });
    matDialogRef.afterClosed()
      .subscribe(() => selectEventSubscription.unsubscribe());
    mouseEvent.stopPropagation();
  }

}
