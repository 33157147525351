import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AgendaViewEffects } from '~/store/agenda-view/agenda-view.effects';

import { AGENDA_VIEW } from './agenda-view.state';
import { agendaViewReducer } from './agenda-view.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(AGENDA_VIEW, agendaViewReducer),
    EffectsModule.forFeature([AgendaViewEffects])
  ]
})
export class AgendaViewStateModule {
}
