<ng-template #agendaInfo>
  <ng-container *ngIf="event">
    <label>
      <mat-icon>storage</mat-icon>
      <span *ngIf="event.typeCar">{{event.typeCar.abreviation}}</span>
      <span *ngIf="event.typeFuel"> - {{event.typeFuel.abreviation}}</span>
    </label>
    <label *ngIf="event.name && event.resource.root.name === businessType.MOT">
      <mat-icon>directions_car</mat-icon>
      <span>{{ event.name }}</span>
    </label>
    <label *ngIf="event.price">
      <mat-icon>attach_money</mat-icon>
      <span>{{ event.price | currency: 'EUR' }}</span>
    </label>
    <label *ngIf="event.contactName">
      <mat-icon>account_box</mat-icon>
      <span>{{ event.contactName | replaceContactName}}</span>
    </label>
    <label *ngIf="event.contactTel">
      <mat-icon>phone</mat-icon>
      <span>
      <a class="link-tel" href="tel:{{ event.contactTel }}">
        {{ event.contactTel }}
      </a>
    </span>
    </label>
  </ng-container>
</ng-template>

<ng-container *ngIf="(isMobile$ | async); else desktopView">
  <div fxLayout="column">
    <header>
      <mat-toolbar color="primary">
        <mat-toolbar-row>
          <ng-container *ngIf="event">
            {{ event.startTime | fromUnixToTime }} - {{ event.duration }} {{ 'min' | translate }}
            {{ 'technical_control' | translate }}
          </ng-container>
          <span class="spacer"></span>
          <button mat-icon-button color="accent" [disabled]="(bufferEventsTotal$ | async) === 2" class="material-drawer-button" (click)="copyEvent()">
            <mat-icon>content_copy</mat-icon>
          </button>
          <button mat-icon-button class="material-drawer-button" (click)="openEditDialog()">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
    </header>
    <div class="agenda-info" fxFlex="100%" fxLayout="column" fxLayoutGap="10px">
      <ng-container *ngTemplateOutlet="agendaInfo"></ng-container>
    </div>
    <apfr-event-action [event]="event" [vertical]="false" [disabled]="!isNotInPast"></apfr-event-action>
  </div>
</ng-container>

<ng-template #desktopView>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
    <div class="agenda-info" fxFlex="100%" fxLayout="column" fxLayoutGap="10px">
      <div class="info-header">
        <small *ngIf="event">{{ event.startTime | fromUnixToTime }} - {{ event.duration }} {{ 'min' | translate }}</small>
      </div>
      <ng-container *ngTemplateOutlet="agendaInfo"></ng-container>
    </div>
    <apfr-event-action [event]="event" [vertical]="true" [disabled]="!isNotInPast"></apfr-event-action>
  </div>
  <div *ngIf="event" class="edit-button" fxFlexAlign="flex-end" fxLayoutGap="10px">
    <button mat-mini-fab color="warn"
            (click)="deleteEvent()"
            [matTooltip]="'delete_event' | translate">
      <mat-icon>delete</mat-icon>
    </button>
    <div class="confirmation-list-container"
         *ngIf="event?.actions?.sms_confirmation && event?.actions?.mail_confirmation; else singleConfirmationButton"
    >
      <button
        type="button"
        mat-mini-fab color="primary" [matTooltip]="'confirmation_list' | translate" (click)="toggleConfirmationList()">
        <mat-icon>email</mat-icon>
      </button>
      <div *ngIf="isShowConfirmationList" tabindex="1" class="confirmation-list-content" (mouseleave)="closeConfirmationList()">
        <button mat-menu-item (click)="resendNotification(event, NotificationKeyType.SMS_CONFIRMATION)">
          <span>{{'sms_confirmation' | translate}}</span>
        </button>
        <button mat-menu-item (click)="resendNotification(event, NotificationKeyType.MAIL_CONFIRMATION)">
          <span>{{'mail_confirmation' | translate}}</span>
        </button>
      </div>
    </div>
    <ng-template #singleConfirmationButton>
      <button
        *ngIf="event?.actions?.sms_confirmation || event?.actions?.mail_confirmation"
        mat-mini-fab color="primary"
        (click)="resendNotification(event, event.actions.sms_confirmation ? NotificationKeyType.SMS_CONFIRMATION : NotificationKeyType.MAIL_CONFIRMATION)"
        [matTooltip]="event.actions.sms_confirmation?.label || event.actions.mail_confirmation?.label">
        <mat-icon>email</mat-icon>
      </button>
    </ng-template>
    <button
      *ngIf="event.actions && event.actions.payment_link"
      mat-mini-fab color="primary"
      (click)="resendNotification(event, NotificationKeyType.PAYMENT_LINK)"
      [matTooltip]="event.actions.payment_link.label">
      <mat-icon>attach_money</mat-icon>
    </button>
    <button mat-mini-fab color="accent"
            *ngIf="isDraggable && isNotInPast"
            [disabled]="(bufferEventsTotal$ | async) === 2"
            (click)="copyEvent()"
            [matTooltip]="'copy_event' | translate">
      <mat-icon>content_copy</mat-icon>
    </button>
    <button mat-mini-fab color="primary"
            *ngIf="isDraggable && isNotInPast"
            (click)="openFilesDialog()"
            [matTooltip]="'upload_file' | translate">
      <mat-icon>upload_file</mat-icon>
    </button>
    <button mat-mini-fab color="primary"
            *ngIf="isDraggable && isNotInPast"
            (click)="openEditDialog()"
            [matTooltip]="'edit_event' | translate">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</ng-template>
