<div class="agenda-event grouped" tabindex="0"
     (click)="showEventListDialog($event)"
     [ngStyle]="setStyleToOverlapEvent()"
>
  <div class="agenda-event-content">
    <div class="agenda-time" *ngIf="eventRes">
      <span> There are {{ eventRes.overlapCount }} events starts at {{ +eventRes.startTime | fromUnixToTime }} </span>
    </div>
  </div>
</div>

