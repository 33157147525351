import { SafeResourceUrl } from '@angular/platform-browser';
import { Action } from '@ngrx/store';
import { Moment } from 'moment';
import { AgendaViewTypeEnum, Institution as InstitutionModel, Resource as ResourceModel } from '~/shared/models';
import { FreeTimeShortData } from '~/+calendar/shared/institution.service';

export const enum AgendaViewActionTypes {
  NumberOfDaysChange = '[Agenda View] Number of days',
  DateChange = '[Agenda View] Date Change',
  ViewTypeChange = '[Agenda View] View Type Change',
  SelectedInstResChange = '[Agenda View] Selected Inst Res Change',
  ShowFormChange = '[Agenda View] ShowFormChange',
  GroupedResourcesChange = '[Agenda View] GroupedResourcesChange',
  MaxDayAmountChange = '[Agenda View] MaxDayAmountChange',
  GroupedInstitutionsChange = '[Agenda View] GroupedInstitutionsChange',
  FreeTimeShortDataChange = '[Agenda View] FreeTimeShortDataChange',
  HowManyColumnsAvailable = '[Agenda View] HowManyColumnsAvailable',
  AgendaSideInfo = '[Agenda View] AgendaSideInfo',

  SetAgendaSettings = '[Agenda View] Set Agenda Settings',
  SaveAgendaSettings = '[Agenda View] Save'
}

export enum AgendaSideInfoType {
  EventInfo = 'EventInfo',
  EventForm = 'EventForm'
}

export enum GroupedResourceType {
  Grouped = 'grouped',
  All = 'all'
}

export interface AgendaSideInfoPayload {
  data: {
    iframeSrc: SafeResourceUrl
    event?: any,
    selectedTime?: any
  };
  type: AgendaSideInfoType;
}

export interface IAgendaSettings {
  numberOfDays: number;
  viewType: AgendaViewTypeEnum;
  selectedDate: Moment;
  displayTimeLabel: boolean;
  isFormSticky: boolean;
}

export class NumberOfDaysChange implements Action {
  readonly type = AgendaViewActionTypes.NumberOfDaysChange;

  constructor(public payload: number) {
  }
}

export class DateChange implements Action {
  readonly type = AgendaViewActionTypes.DateChange;

  constructor(public payload: { date: Moment }) {
  }
}

export class ViewTypeChange implements Action {
  readonly type = AgendaViewActionTypes.ViewTypeChange;

  constructor(public payload: { viewType: AgendaViewTypeEnum }) {
  }
}

export class SelectedInstResChange implements Action {
  readonly type = AgendaViewActionTypes.SelectedInstResChange;

  constructor(public payload: {
    selectedInstitutions: Array<InstitutionModel>,
    selectedResources: Array<ResourceModel>
  }) {
  }
}

export class ShowFormChange implements Action {
  readonly type = AgendaViewActionTypes.ShowFormChange;

  constructor(public payload: { isShowForm: boolean }) {
  }
}

export class GroupedResourcesChange implements Action {
  readonly type = AgendaViewActionTypes.GroupedResourcesChange;

  constructor(public payload: { groupedResources: boolean }) {
  }
}

export class MaxDayAmountChange implements Action {
  readonly type = AgendaViewActionTypes.MaxDayAmountChange;

  constructor(public payload: { maxDayAmount: number }) {
  }
}

export class GroupedInstitutionsChange implements Action {
  readonly type = AgendaViewActionTypes.GroupedInstitutionsChange;

  constructor(public payload: { groupedInstitutions: boolean }) {
  }
}

export class FreeTimeShortDataChange implements Action {
  readonly type = AgendaViewActionTypes.FreeTimeShortDataChange;

  constructor(public payload: { freeTimeShortData: FreeTimeShortData }) {
  }
}

export class HowManyColumnsAvailable implements Action {
  readonly type = AgendaViewActionTypes.HowManyColumnsAvailable;

  constructor(public payload: { columnsCount: number }) {
  }
}

export class AgendaSideInfo implements Action {
  readonly type = AgendaViewActionTypes.AgendaSideInfo;

  constructor(public payload: AgendaSideInfoPayload) {
  }
}

export class SetAgendaSettings implements Action {
  readonly type = AgendaViewActionTypes.SetAgendaSettings;

  constructor(public payload: { agendaSettings: Partial<IAgendaSettings>, excludeUpdate?: boolean }) {
  }
}

export class SaveAgendaSettings implements Action {
  readonly type = AgendaViewActionTypes.SaveAgendaSettings;

  constructor(public payload: { agendaSettings: Partial<IAgendaSettings> }) {
  }
}

export type AgendaViewActions =
  NumberOfDaysChange |
  DateChange |
  ViewTypeChange |
  SelectedInstResChange |
  ShowFormChange |
  GroupedResourcesChange |
  MaxDayAmountChange |
  GroupedInstitutionsChange |
  FreeTimeShortDataChange |
  HowManyColumnsAvailable |
  AgendaSideInfo |
  SetAgendaSettings;
