import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { map, switchMap } from 'rxjs/operators';
import { Event as EventModel } from '~/shared/models';
import { UpdateEvent } from '~/store/calendar';
import { Undo } from '~/store/undo.reducer';

import { EventsBufferActionTypes, PastedEvent, PasteEvent, RemoveEventBuffer } from './events-buffer.actions';

@Injectable()
export class EventsBufferEffects {
  handlePaste$ = createEffect(() => this.actions$
    .pipe(
      ofType(EventsBufferActionTypes.PasteEvent),
      map((action: PasteEvent) => action.payload.updateData),
      switchMap((updateData: Update<EventModel>) => [
        new PastedEvent({eventId: null}),
        new UpdateEvent({updateData}),
        new RemoveEventBuffer({eventId: updateData.id as number})
      ])
    ));
  handleUndoPaste$ = createEffect(() => this.actions$
    .pipe(
      ofType(EventsBufferActionTypes.UndoPaste),
      map((action: PasteEvent) => action.payload.updateData),
      switchMap((updateData: Update<EventModel>) => [
        // we have to undo 3 actions 2 from handlePaste$ effect and UndoPaste
        new Undo(),
        new Undo(),
        new Undo(),
        new UpdateEvent({updateData})
      ])
    ));

  handlerRemoveEventBuffer$ = createEffect(() => this.actions$
    .pipe(
      ofType(EventsBufferActionTypes.RemoveFromBuffer),
      map((action: RemoveEventBuffer) => action.payload.eventId),
      switchMap((eventId: number) => [
        new PastedEvent({eventId: null})
      ])
    ));

  constructor(private readonly actions$: Actions) {
  }
}
