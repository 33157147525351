import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule, SharedModule } from '~/framework';
import { DatepickerHeaderModule, SelectAllCheckboxModule, SnackBarComponent, SnackBarModule } from '~/shared/components';
import { InstitutionService } from '~/+calendar/shared/institution.service';
import { AgendaSettingsDialogComponent, AgendaSettingsDialogModule } from '~/shared/components/agenda-settings-dialog';

import { AgendaNavComponent } from './agenda-nav.component';
import { InstHintDialogComponent } from './inst-hint/inst-hint-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        ReactiveFormsModule,
        MaterialModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatDialogModule,
        SelectAllCheckboxModule,
        DatepickerHeaderModule,
        SnackBarModule,
        AgendaSettingsDialogModule
    ],
    declarations: [
        AgendaNavComponent,
        InstHintDialogComponent
    ],
    exports: [
        AgendaNavComponent
    ],
    providers: [
        InstitutionService
    ]
})
export class AgendaNavModule {}
