import { Component, Input, OnInit } from '@angular/core';
import { Field, FieldConfig } from '~/shared/models';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'apfr-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss']
})
export class FormDateComponent implements Field, OnInit {
  @Input() config: FieldConfig;
  @Input() group: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
