import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '~/framework/material/material.module';

import { NotificationComponent } from '../notification/notification.component';
import { NotificationWrapperComponent } from './notification-wrapper.component';

@NgModule({
    imports: [
        TranslateModule,
        MaterialModule
    ],
    declarations: [
        NotificationWrapperComponent,
        NotificationComponent
    ],
    exports: [
        NotificationWrapperComponent
    ]
})
export class NotificationWrapperModule { }
