import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppointmentActionTypes, AppointmentFormActionTypes, CustomerActionTypes, NoteActionTypes } from '~/store/customers';
import { CustomerFormActionTypes } from '~/store/customers/customer-form';
import { map, switchMap } from 'rxjs/operators';
import { MediaObserver } from '@angular/flex-layout';

import {
  ConfigAppActionTypes,
  ConfigAppOffline,
  ConfigAppOnline,
  ConfigSetIsDesktop,
  ConfigSetIsMobile,
  ConfigSetIsTablet
} from './config-app.actions';

/**
 * ConfigAppEffects
 *
 * @description
 * Main Application Effect which handle Fail response from server an change application to Offline mode
 * if server response.status was >= 500 or 0 (Not Connection).
 * If response was success change application to Online
 */
@Injectable()
export class ConfigAppEffects {
  checkMobile$ = createEffect(() => this.actions
    .pipe(
      ofType(ConfigAppActionTypes.ConfigCheckMobile),
      switchMap(() => {
        return this.media
          .media$
          .pipe(
            map(change => {
              if (change.mqAlias === 'xs') {
                return new ConfigSetIsMobile(true);
              } else if (change.mqAlias === 'sm') {
                return new ConfigSetIsTablet(true);
              } else {
                return new ConfigSetIsDesktop();
              }
            })
          );
      })
    ));
  handleFailResponse$ = createEffect(() => this.actions
    .pipe(
      ofType(
        CustomerActionTypes.GetAllCustomersFail,
        CustomerActionTypes.GetOneCustomerFail,
        AppointmentActionTypes.GetOneAppointmentFail,
        NoteActionTypes.GetNotesFail,
        AppointmentActionTypes.GetAppointmentsFail,
        CustomerFormActionTypes.LoadFormFail,
        AppointmentFormActionTypes.LoadFormFail
      ),
      map((action: any) => {
        if (action.payload.status === 0 || action.payload.status >= 500) {
          return new ConfigAppOffline();
        } else {
          return new ConfigAppOnline();
        }
      })
    ));
  handleSuccessResponse$ = createEffect(() => this.actions
    .pipe(
      ofType(
        CustomerActionTypes.GetAllCustomersSuccess,
        CustomerActionTypes.GetOneCustomerSuccess,
        AppointmentActionTypes.GetOneAppointmentSuccess,
        AppointmentActionTypes.GetAppointmentsSuccess,
        NoteActionTypes.GetNotesSuccess,
        CustomerFormActionTypes.LoadFormSuccess,
        AppointmentFormActionTypes.LoadFormSuccess
      ),
      map((action: any) => {
        return new ConfigAppOnline();
      })
    ));

  constructor(private actions: Actions,
              private media: MediaObserver) {
  }
}
