import moment from 'moment';

export const BASE_DATE_TIME_FORMAT = 'HH:mm DD/MM/YYYY';
export const BASE_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_FORMAT = 'YYYYMMDD';
export const BASE_TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

/**
 * generateDaysArray
 *
 * @description
 * Generate Array of Days
 *
 */
export function generateDaysArray(start: moment.Moment, end?: moment.Moment): Array<Date> {
  const days = [];
  let day = start;
  if (end === undefined) {
    end = start.clone()
      .add(3, 'days');
  }

  while (day <= end) {
    days.push(day.toDate());
    day = day.clone()
      .add(1, 'd');
  }

  return days;
}

export function generateMonthLabels(start: moment.Moment, interval: number): Array<string> {
  const days = [];
  let day = start;

  const end = start.clone()
    .add(1, 'month');

  while (day <= end) {
    days.push(day.format('DD/MM'));
    day = day.clone()
      .add(interval, 'd');
  }

  return days;
}

/**
 * getTime
 */
export function getTime(start: moment.Moment, end: moment.Moment): string {
  return `Start ${start.format('DD/MM HH:mm')} - End ${end.format('DD/MM HH:mm')}`;
}

/**
 * concatDateTime
 *
 * Concatenate date and time return {@link BASE_DATE_TIME_FORMAT}
 */
export function concatDateTime(
  date: Date | moment.Moment, time: string, dateFormat = BASE_DATE_FORMAT, dateTimeFormat = BASE_DATE_TIME_FORMAT
): moment.Moment {
  return moment(
    `${time} ${moment(date).format(dateFormat)}`,
    dateTimeFormat
    );
}

export function toDateTime(date: moment.Moment | Date): string {
  return moment(date)
    .format(BASE_DATE_TIME_FORMAT);
}

/**
 * getMomentDateTimeFromBaseFormat
 *
 * Return Moment object from string which based on {@link BASE_DATE_TIME_FORMAT}
 */
export function getMomentDateTimeFromBaseFormat(date: string): moment.Moment {
  return moment(date, BASE_DATE_TIME_FORMAT);
}

export function isHasOverlap(cur, next) {
  return (
    (moment(cur.start)
      .isSameOrBefore(next.end))
    &&
    (moment(cur.end)
      .isSameOrAfter(next.start))
  );
}

