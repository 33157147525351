import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { APPOINTMENT_FORM } from './appointment-form.state';
import { appointmentFormReducer } from './appointment-form.reducer';
import { AppointmentFormEffects } from './appointment-form.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(APPOINTMENT_FORM, appointmentFormReducer),
    EffectsModule.forFeature([AppointmentFormEffects])
  ]
})
export class AppointmentFormStateModule {
}
