<div mat-dialog-title>
  <header>
    <mat-toolbar color="primary">
      <button mat-icon-button class="close-button material-drawer-button" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </header>
</div>
<div mat-dialog-content>
  <mat-card-content>
    <div class="preview-container">
      <apfr-iframe-container [iframeSrc]="fileSrc"></apfr-iframe-container>
    </div>
  </mat-card-content>
</div>
