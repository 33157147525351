<header class="mat-elevation-z2">
  <mat-toolbar color="primary">
    <mat-toolbar-row *ngIf="isLoggedIn()">
      <button mat-icon-button class="material-drawer-button" (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <ng-container *ngIf="currentPagePortal; else spacer">
        <ng-template [cdkPortalOutlet]="currentPagePortal"></ng-template>
      </ng-container>
      <ng-template #spacer>
        <span class="spacer"></span>
      </ng-template>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
