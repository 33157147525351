import { Injectable } from '@angular/core';

import { JSONSchemaArray, JSONSchemaObject, LocalStorage } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';

import { CUSTOMERS } from '~/store/customers/customer.state';
import { EVENTS } from '~/store/calendar/calendar.state';
import { AuthResponseModel, BaseEntity, CustomerModel, Event as EventModel } from '~/shared/models';
import { Observable } from 'rxjs';

const schemaCustomer: JSONSchemaArray = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: { type: 'string'},
      name: { type: 'string' },
      phone: { type: 'string' },
      avatar: { type: 'string' },
      address: { type: 'string' },
      birthday: { type: 'string' },
      gender: { type: 'string' },
      email: { type: 'string' }
    }
  }
};

const schemaBaseEntity: JSONSchemaObject = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    name: { type: 'string' },
    parent_id: { type: 'string' }
  }
};

const schemaBaseEntityArray: JSONSchemaArray = {
  type: 'array',
  items: schemaBaseEntity
};

const schemaAuthResponseUserData: JSONSchemaObject = {
  type: 'object',
  properties: {
    entitiesList: {
      type: 'object',
      properties: {
        businesses: schemaBaseEntityArray,
        companies: schemaBaseEntityArray,
        domains: schemaBaseEntityArray,
        institutions: schemaBaseEntityArray,
        resources: schemaBaseEntityArray
      }
    } as JSONSchemaObject
  }
};

const USER_DATA = 'userData';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(protected localStorage: LocalStorage) { }

  setCustomers(customers) {
    this.localStorage.setItem(CUSTOMERS, customers)
      .subscribe();
  }

  getCustomers() {
    return this.localStorage.getItem<Array<CustomerModel>>(CUSTOMERS, schemaCustomer);
  }

  setAgenda(events: Array<EventModel>) {
    this.localStorage.setItem(EVENTS, events)
      .subscribe();
  }

  getAgenda() {
    return this.localStorage.getItem<Array<EventModel>>(EVENTS)
      .pipe(
        map(data => data as Array<EventModel>)
      );
  }

  setUserData(userData: AuthResponseModel['userData']) {
    return this.localStorage.setItem(USER_DATA, userData)
      .subscribe();
  }

  getUserData() {
    return this.localStorage.getItem<AuthResponseModel['userData']>(USER_DATA)
      .pipe(
        map(data => data as AuthResponseModel['userData'])
      );
  }

  getInstitutions(): Observable<BaseEntity[]> {
    return this.getUserData()
      .pipe(
        map((userData: AuthResponseModel['userData']) => userData.entitiesList ? userData.entitiesList.institutions : [])
      );
  }
}
