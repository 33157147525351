import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ColorModeEnum } from '~/shared/models/agenda/color-mode.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '~/framework';
import { Event as EventModel, Resource } from '~/shared/models';

interface EventFilter {
  color: string;
  name: string;
  total: number;
}

@Component({
  selector: 'apfr-agenda-event-filter',
  templateUrl: './agenda-event-filter.component.html',
  styleUrls: ['./agenda-event-filter.component.scss']
})
export class AgendaEventFilterComponent extends BaseComponent implements AfterViewInit {
  @Input()
  set events(events: EventModel[]) {
    this._events = events;
    this.updateFilter();
  }

  get events(): EventModel[] {
    return this._events;
  }

  @Input()
  set selectedResources(selectedResources: Resource[]) {
    this._selectedResources = selectedResources;
    this.updateFilter();
  }

  get selectedResources(): Resource[] {
    return this._selectedResources;
  }


  @Input()
  selectedColorMode: ColorModeEnum;
  @Output()
  selectedColorModeChange: EventEmitter<ColorModeEnum> = new EventEmitter<ColorModeEnum>();
  @Input()
  selectedColor: string;
  @Output()
  selectedColorChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  heightChanged: EventEmitter<number> = new EventEmitter<number>();

  _selectedResources: Resource[] = [];
  _events: EventModel[] = [];

  eventFilters: {
    [ColorModeEnum.Type]: EventFilter[],
    [ColorModeEnum.Origin]: EventFilter[]
  } = {
    [ColorModeEnum.Type]: [],
    [ColorModeEnum.Origin]: []
  };
  colorModes: ColorModeEnum[] = [ColorModeEnum.Type, ColorModeEnum.Origin];

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    protected readonly _elementRef: ElementRef
  ) {
    super();
  }

  ngAfterViewInit() {
    this.heightChanged.emit(this._elementRef.nativeElement.getBoundingClientRect().height);
  }

  onChangeFilterType(colorMode: ColorModeEnum): void {
    this.selectedColorMode = colorMode;
    this.selectedColorModeChange.emit(this.selectedColorMode);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {colormode: this.selectedColorMode},
        queryParamsHandling: 'merge'
      });
  }

  onFilterByColor(color: string): void {
    this.selectedColor = color;
    this.selectedColorChange.emit(this.selectedColor);
  }

  /**
   * calculations of event filters by type or origin, in future instead of use eventType, we need to start use service data(name+color)
   * currently old events dont have attached service. Also creator relation can be null
   *
   * calculations depends on date range and selected resources
   */
  private updateFilter(): void {
    this.eventFilters[ColorModeEnum.Type] = [];
    this.eventFilters[ColorModeEnum.Origin] = [];
    const events = this.events;
    if (!events.length) {
      return;
    }
    const selectedResourcesIds = this.selectedResources.map((resource: Resource) => resource.id);
    // TODO: refactor with lodash
    events.forEach((event: EventModel) => {
      if (selectedResourcesIds.includes(event.resource.id)) {
        if (event.eventType) {
          const index = this.eventFilters[ColorModeEnum.Type].findIndex(item => item.color === event.eventType.color);
          if (index < 0) {
            this.eventFilters[ColorModeEnum.Type].push({
              color: event.eventType.color,
              name: event.eventType.name,
              total: 1
            });
          } else {
            this.eventFilters[ColorModeEnum.Type][index] = {
              ...this.eventFilters[ColorModeEnum.Type][index],
              total: this.eventFilters[ColorModeEnum.Type][index].total + 1
            };
          }
        }
        if (event.creator?.profile?.color) {
          const index = this.eventFilters[ColorModeEnum.Origin].findIndex(item => item.color === event.creator.profile.color);
          if (index < 0) {
            this.eventFilters[ColorModeEnum.Origin].push({
              color: event.creator.profile.color,
              name: event.creator.firstName,
              total: 1
            });
          } else {
            this.eventFilters[ColorModeEnum.Origin][index] = {
              ...this.eventFilters[ColorModeEnum.Origin][index],
              total: this.eventFilters[ColorModeEnum.Origin][index].total + 1
            };
          }
        }
      }
    });
  }

}
