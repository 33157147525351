import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuards} from '~/shared/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'calendar'
  },
  {
    path: 'auth',
    loadChildren: () => import('./+auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./+user-settings/user-settings.module').then(m => m.UserSettingsModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./+reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./+dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'calendar-rule',
    loadChildren: () => import('./+calendar-rule/calendar-rule.module').then(m => m.CalendarRuleModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./+calendar/calendar.module').then(m => m.CalendarModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'customers',
    loadChildren: () => import('./+customer/customer.module').then(m => m.CustomerModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'team',
    loadChildren: () => import('./+team/team.module').then(m => m.TeamModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'external',
    loadChildren: () => import('./+external/external.module').then(m => m.ExternalModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'statistics',
    loadChildren: () => import('./+statistics/statistics.module').then(m => m.StatisticsModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'adwords',
    loadChildren: () => import('./+adwords/adwords.module').then(m => m.AdWordsModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'review',
    loadChildren: () => import('./+review/review.module').then(m => m.ReviewModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'marketing',
    loadChildren: () => import('./+marketing/marketing.module').then(m => m.MarketingModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./+configuration/configuration.module').then(m => m.ConfigurationModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'form',
    loadChildren: () => import('./+form/form-page.module').then(m => m.FormPageModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'jobs',
    loadChildren: () => import('./+job/job.module').then(m => m.JobModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'sms',
    loadChildren: () => import('./+sms/sms.module').then(m => m.SmsModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'search',
    loadChildren: () => import('./+search/search.module').then(m => m.SearchModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'products',
    loadChildren: () => import('./+products/products.module').then(m => m.ProductsModules),
    canLoad: [AuthGuards]
  },
  {
    path: 'orders',
    loadChildren: () => import('./+orders/orders.module').then(m => m.OrdersModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'promotions',
    loadChildren: () => import('./+promotions/promotions.module').then(m => m.PromotionsModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./+notifications/notifications.module').then(m => m.NotificationsModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'partner-access',
    loadChildren: () => import('./+partner-access/partner-access.module').then(m => m.PartnerAccessModule),
    canLoad: [AuthGuards]
  },
  {
    path: 'workflow-rules',
    loadChildren: () => import('./+workflow-rules/workflow-rules.module').then(m => m.WorkflowRulesModule),
    canLoad: [AuthGuards]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
