import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ComponentPortal } from '@angular/cdk/portal';

import { TokenService } from '~/shared/services';

@Component({
  selector: 'apfr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  sidenav: MatSidenav;
  @Input()
  currentPagePortal: ComponentPortal<any>;

  constructor() { }

  ngOnInit() {
  }

  isLoggedIn() {
    return TokenService.isUserLoggedIn();
  }

}
