import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, configApiUrls } from '~/framework';
import { CustomerModel, FieldConfig } from '~/shared/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService {

  getCustomers(page: number = 1, itemsPerPage: number = 100, search: string): Observable<CustomerModel[]> {
    return this.http.get<CustomerModel[]>(`${this.apiUrl}/${configApiUrls.customers}?page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`);
  }

  getCustomer(id: string): Observable<CustomerModel> {
    return this.http.get<CustomerModel>(`${this.apiUrl}/${configApiUrls.customers}/${id}`);
  }

  getCustomerForm(): Observable<FieldConfig[]> {
    return this.http.get(`${this.apiUrl}/customer_form`)
      .pipe(map((data: any) => data.inputs));
  }
}
