import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';

import { MaterialModule, SharedModule } from '~/framework';

import { AgendaSettingsDialogComponent } from './agenda-settings-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    MatDatepickerModule,
    MaterialModule,
    MatDialogModule
  ],
  declarations: [
    AgendaSettingsDialogComponent
  ],
  exports: [
    AgendaSettingsDialogComponent
  ],
})
export class AgendaSettingsDialogModule {}
