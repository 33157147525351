import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, pluck, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SnackBarComponent } from '~/shared/components/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalendarRuleService, ICalendarRule } from '@apfr/components/calendar-rule-modal';
import { forkJoin, of } from 'rxjs';
import * as CalendarRuleActions from '~/store/calendar-rule/calendar-rule.actions';

@Injectable()
export class CalendarRuleEffects {
  handleGetCalendarRule$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CalendarRuleActions.getCalendarRule),
        switchMap(({page, items, filters}) => this.calendarRuleService.getCalendarRules(page, items, filters)
          .pipe(
            pluck('hydra:member'),
            map((calendarRules: ICalendarRule[]) => CalendarRuleActions.getCalendarRuleSuccess({calendarRules})),
            catchError(error => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: error.error.errors['rule_data']['message'],
                  action: 'ok'
                }
              });

              return of(CalendarRuleActions.getCalendarRuleFail({error}));
            })
          ))
      ));
  handleGetCalendarRuleById$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CalendarRuleActions.getCalendarRuleById),
        switchMap(({id}) => this.calendarRuleService.getCalendarRuleById(id)
          .pipe(
            map((selectedCalendarRule: ICalendarRule) => CalendarRuleActions.getCalendarRuleByIdSuccess({selectedCalendarRule})),
            catchError(error => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: error.error.errors['rule_data']['message'],
                  action: 'ok'
                }
              });

              return of(CalendarRuleActions.getCalendarRuleByIdFail({error}));
            })
          ))
      ));
  handleCreateCalendarRule$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CalendarRuleActions.createCalendarRule),
        switchMap(({body}) => this.calendarRuleService.createCalendarRule(body)
          .pipe(
            map((selectedCalendarRule: ICalendarRule) => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'calendar_rule.calendar_rule_created',
                  action: 'ok'
                }
              });

              return CalendarRuleActions.createCalendarRuleSuccess({selectedCalendarRule});
            }),
            catchError(error => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: error.error.errors['rule_data']['message'],
                  action: 'ok'
                }
              });

              return of(CalendarRuleActions.createCalendarRuleFail({error}));
            })
          ))
      ));

  handleCreateCalendarRules$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CalendarRuleActions.createCalendarRules),
        switchMap(({calendarRules}) => forkJoin(
          ...calendarRules.map(calendarRule => this.calendarRuleService.createCalendarRule(calendarRule))
        )
          .pipe(
            map((rules: ICalendarRule[]) => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'calendar_rule.calendar_rule_created',
                  action: 'ok'
                }
              });

              return CalendarRuleActions.createCalendarRulesSuccess({calendarRules: rules});
            }),
            catchError(error => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: error.error.errors['rule_data']['message'],
                  action: 'ok'
                }
              });

              return of(CalendarRuleActions.createCalendarRulesFail({error}));
            })
          ))
      ));
  handleUpdateCalendarRule$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CalendarRuleActions.updateCalendarRule),
        switchMap(({id, body}) => this.calendarRuleService.updateCalendarRule(id, body)
          .pipe(
            map((selectedCalendarRule: ICalendarRule) => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'calendar_rule.calendar_rule_updated',
                  action: 'ok'
                }
              });

              return CalendarRuleActions.updateCalendarRuleSuccess({selectedCalendarRule});
            }),
            catchError(error => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: error.error.errors['rule_data']['message'],
                  action: 'ok'
                }
              });

              return of(CalendarRuleActions.updateCalendarRuleFail({error}));
            })
          ))
      ));
  private id: number;
  handleDeleteCalendarRule$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CalendarRuleActions.deleteCalendarRule),
        tap(({id}) => this.id = id),
        switchMap(({id}) => this.calendarRuleService.deleteCalendarRule(id)
          .pipe(
            map(() => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: 'calendar_rule.calendar_rule_deleted',
                  action: 'ok'
                }
              });

              return CalendarRuleActions.deleteCalendarRuleSuccess({id: this.id});
            }),
            catchError(error => {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 5_000,
                data: {
                  message: error.error.errors['rule_data']['message'],
                  action: 'ok'
                }
              });

              return of(CalendarRuleActions.deleteCalendarRuleFail({error}));
            })
          ))
      ));

  constructor(
    private actions$: Actions,
    private calendarRuleService: CalendarRuleService,
    private snackBar: MatSnackBar
  ) {
  }
}
